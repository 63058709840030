import Colors from "./Colors"
import { useIPEditorEditorMode } from "./editor-store"

type SVGTabRendererProps = {
  type: "title" | "name" | "signature" | "value" | "address" | "email" | "date"
  lineHeight: number
  fontSize: number
  x: number
  rectY: number
  rectWidth: number
  textX: number
  textY: number
  lineX2: number
  lineY: number
  value?: string
}

export default function SVGTabRenderer({
  type,
  lineHeight,
  fontSize,
  x,
  rectY,
  rectWidth,
  textX,
  textY,
  lineX2,
  lineY,
  value,
}: SVGTabRendererProps) {
  let renderMode = useIPEditorEditorMode()

  let height = lineHeight / 1.25

  // don't alter the value if it's a value type placeholder and we are passing a label through,
  // or if the signature has an actual value to display
  const shouldCapitalize = type !== "value" && !value

  if (type === "signature") {
    rectY -= 4
    height += 5.5
  }

  return (
    <g>
      {renderMode === "edit" && (
        <>
          <rect
            x={x}
            y={rectY}
            width={rectWidth}
            height={type === "address" ? lineHeight * 3.75 : height}
            fill={Colors.yellow[100]}
            fillOpacity={0.6}
            rx={3}
            ry={3}
          />
          <text
            alignmentBaseline="middle"
            x={textX}
            y={textY}
            fontSize={type === "signature" ? 14 : fontSize}
            fill={Colors.yellow[700]}
            fontFamily={type === "signature" ? "Dancing Script" : "Open Sans"}
            className={shouldCapitalize ? "capitalize" : ""}
          >
            {value ?? type}
          </text>
          {/* OverPaint to cut off overflow text */}
          {type === "value" && (
            <rect
              x={rectWidth + x - 5}
              y={rectY}
              width={5}
              height={height}
              fill="white"
              rx={3}
            />
          )}
          <rect
            x={rectWidth + x - 5}
            y={rectY}
            width={5}
            height={height}
            fill={Colors.yellow[100]}
            fillOpacity={0.6}
            rx={3}
          />
          {type === "value" && (
            <rect
              x={rectWidth + x}
              y={rectY}
              width={100}
              height={height}
              fill="white"
            />
          )}
          {/* End of OverPaint */}
        </>
      )}
      {type === "signature" && (
        <line
          x1={x}
          y1={lineY}
          x2={lineX2}
          y2={lineY}
          stroke="black"
          strokeWidth={0.75}
        />
      )}
    </g>
  )
}
