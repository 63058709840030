import { IconPropsType } from "./IconPropTypes"

export default function AddLeadInIcon({
  x = 0,
  y = 0,
  width = 16,
  height = 16,
  classNames = "",
}: IconPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      x={x}
      y={y}
    >
      <path
        d="M8.6 9H2.6C2.44087 9 2.28826 8.9097 2.17574 8.74895C2.06321 8.5882 2 8.37019 2 8.14286C2 7.91553 2.06321 7.69751 2.17574 7.53677C2.28826 7.37602 2.44087 7.28572 2.6 7.28572L8.6 7.28572C8.75913 7.28572 8.91174 7.37602 9.02426 7.53677C9.13679 7.69751 9.2 7.91553 9.2 8.14286C9.2 8.37019 9.13679 8.5882 9.02426 8.74895C8.91174 8.9097 8.75913 9 8.6 9ZM13.4 4.71429L2.6 4.71429C2.44087 4.71429 2.28826 4.62398 2.17574 4.46323C2.06321 4.30249 2 4.08447 2 3.85714C2 3.62981 2.06321 3.4118 2.17574 3.25105C2.28826 3.09031 2.44087 3 2.6 3L13.4 3C13.5591 3 13.7117 3.09031 13.8243 3.25105C13.9368 3.4118 14 3.62981 14 3.85714C14 4.08447 13.9368 4.30249 13.8243 4.46323C13.7117 4.62398 13.5591 4.71429 13.4 4.71429Z"
        fill="currentColor"
        className={classNames}
      />
    </svg>
  )
}
