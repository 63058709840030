import { IconPropsType } from "./IconPropTypes"

export default function EmailIcon({
  x,
  y,
  width = 16,
  height = 16,
  classNames = "",
}: IconPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x={x}
      y={y}
    >
      <path
        d="M14.6654 3.99935C14.6654 3.26602 14.0654 2.66602 13.332 2.66602H2.66536C1.93203 2.66602 1.33203 3.26602 1.33203 3.99935V11.9993C1.33203 12.7327 1.93203 13.3327 2.66536 13.3327H13.332C14.0654 13.3327 14.6654 12.7327 14.6654 11.9993V3.99935ZM13.332 3.99935L7.9987 7.33268L2.66536 3.99935H13.332ZM13.332 11.9993H2.66536V5.33268L7.9987 8.66602L13.332 5.33268V11.9993Z"
        fill="currentColor"
        className={classNames}
      />
    </svg>
  )
}
