import { IconPropsType } from "./IconPropTypes"

export default function AddressIcon({
  x,
  y,
  width = 16,
  height = 16,
  classNames = "",
}: IconPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x={x}
      y={y}
    >
      <path
        d="M8.4987 4.33398C8.94073 4.33398 9.36465 4.50958 9.67721 4.82214C9.98977 5.1347 10.1654 5.55862 10.1654 6.00065C10.1654 6.21952 10.1223 6.43625 10.0385 6.63846C9.95474 6.84067 9.83197 7.0244 9.67721 7.17916C9.52244 7.33393 9.33871 7.45669 9.1365 7.54045C8.93429 7.62421 8.71757 7.66732 8.4987 7.66732C8.05667 7.66732 7.63275 7.49172 7.32019 7.17916C7.00763 6.8666 6.83203 6.44268 6.83203 6.00065C6.83203 5.55862 7.00763 5.1347 7.32019 4.82214C7.63275 4.50958 8.05667 4.33398 8.4987 4.33398ZM8.4987 1.33398C9.73637 1.33398 10.9234 1.82565 11.7985 2.70082C12.6737 3.57599 13.1654 4.76297 13.1654 6.00065C13.1654 9.50065 8.4987 14.6673 8.4987 14.6673C8.4987 14.6673 3.83203 9.50065 3.83203 6.00065C3.83203 4.76297 4.3237 3.57599 5.19887 2.70082C6.07404 1.82565 7.26102 1.33398 8.4987 1.33398ZM8.4987 2.66732C7.61464 2.66732 6.7668 3.01851 6.14168 3.64363C5.51655 4.26875 5.16536 5.1166 5.16536 6.00065C5.16536 6.66732 5.16536 8.00065 8.4987 12.474C11.832 8.00065 11.832 6.66732 11.832 6.00065C11.832 5.1166 11.4808 4.26875 10.8557 3.64363C10.2306 3.01851 9.38275 2.66732 8.4987 2.66732Z"
        fill="currentColor"
        className={classNames}
      />
    </svg>
  )
}
