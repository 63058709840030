import contentToLines from "../editor/content-to-lines"
import { SignerBlock } from "models/InstaPageV2/SignerBlock"
import { SignatureBlock } from "models/InstaPageV2/Blocks/SignatureBlock"
import { Node } from "./blocks-to-lines"
import {
  SignatoryDescriptionOptions,
  SignatoryTitleOptions,
} from "models/Signatory"
import { DEFAULT_SIGNATORY_OPTIONS } from "../editor/signatories/useUpdateSignatoryTitleOptions"
import { isEqualValues } from "helpers/object"
import { AdditionalFieldsItems } from "components/item-detail/InstapageV2/FieldsFormatting"
import { textWidth } from "lib/text-width"
import { type Content } from "models/InstaPageV2/Content"
import marksToTextStyle from "./marks-to-text-style"

export const NESTING_X_SPACING = 7

export default function renderSignatureBlockContent(
  signerBlock: SignerBlock,
  blockLineIndex: number,
  addContentToLineIndex: (lineIndex: number, content: Node) => void,
  block: SignatureBlock,
  blockXPosition: number,
  blockXEndPosition: number,
  lineHeight: number,
  fontFamily: string | undefined = "times_new_roman",
  fontSize: number
) {
  const CURRENT_NESTING_X_SPACING =
    block.authRepAlignment === "left" ? 0 : NESTING_X_SPACING

  const addTextContentToLineIndex = (
    lineIndex: number,
    content: {
      text: string
      marks?: { type: string }[]
    },
    startX = blockXPosition
  ) => {
    let width = textWidth(
      content.text,
      marksToTextStyle(content.marks, {
        size: `{fontSize}px`,
        family: fontFamily,
        lineHeight: lineHeight,
      })
    )
    addContentToLineIndex(lineIndex, {
      id: `${block.id}-${lineIndex}-${content.text
        .toLowerCase()
        .split(" ")
        .join("-")}`,
      type: "text",
      text: content.text,
      marks: content.marks,
      blockX: startX,
      blockXEnd: blockXEndPosition,
      blockId: block.id,
      width,
      // Not needed in signature blocks so forcing false?
      isLastLineInParagraph: false,
    })
  }

  const addPlaceholderContentToLineIndex = (
    lineIndex: number,
    content: {
      type:
        | "title"
        | "signature"
        | "spacer"
        | "name"
        | "value"
        | "address"
        | "date"
        | "email"
      x: number
      xEnd?: number
      value?: string
    }
  ) => {
    addContentToLineIndex(lineIndex, {
      id: `${block.id}-${lineIndex}-${content.type}`,
      type: content.type,
      blockX: blockXPosition,
      blockXEnd: content.xEnd || blockXEndPosition,
      blockId: block.id,
      x: content.x,
      assignmentId: signerBlock.assignmentId,
      value: content.value,
    })
  }

  const fieldContentToLines = (
    content: string | Content[],
    marks: { type: string }[] = [],
    startX: number = blockXPosition
  ) => {
    if (typeof content === "string") {
      return contentToLines(
        [
          {
            type: "paragraph",
            content: [{ type: "text", text: content, marks }],
          },
        ],
        {
          width: blockXEndPosition - startX,
          lineHeight: lineHeight,
          fontFamily: fontFamily,
          fontSize: fontSize,
        }
      )
    } else {
      return contentToLines([{ type: "paragraph", content }], {
        width: blockXEndPosition - startX,
        lineHeight: lineHeight,
        fontFamily: fontFamily,
        fontSize: fontSize,
      })
    }
  }

  let lineIndex = blockLineIndex
  let FIELD_LINE_TAB_STOP = fontSize * 2.8

  /* ---------------------------------------------------------
   * HEADER
   * ---------------------------------------------------------
   */
  let blockHeader = signerBlock.header
  let isBlockHeadEnabled = blockHeader && block.blockStyles.header.isEnabled
  if (blockHeader && isBlockHeadEnabled) {
    let content = blockHeader
    let marks: { type: string }[] = []

    if (block.blockStyles.header.bold) {
      marks.push({ type: "bold" })
    }

    if (block.blockStyles.header.textTransform === "uppercase") {
      marks.push({ type: "uppercase" })
    }

    if (block.blockStyles.header.italic) {
      marks.push({ type: "italic" })
    }

    if (block.blockStyles.header.underline) {
      marks.push({ type: "underline" })
    }

    let headerContentLines = fieldContentToLines(content, marks)
    headerContentLines.forEach((contentLine) => {
      contentLine.forEach((content) => {
        let textContent = content.text || ""
        addTextContentToLineIndex(lineIndex, {
          text: textContent,
          marks: content.marks,
        })
      })

      lineIndex++
    })

    addPlaceholderContentToLineIndex(lineIndex + 1, {
      type: "spacer",
      x: blockXPosition,
    })

    lineIndex += 1
  }

  /* ---------------------------------------------------------
   * SIGNATORIES[]
   * ---------------------------------------------------------
   */
  if (signerBlock.signatories.length > 0) {
    signerBlock.signatories.forEach((signatory, idx) => {
      let isIndividualSigner =
        signerBlock.signatories.length === 1 &&
        signerBlock.signatories[0] &&
        !signerBlock.signatories[0].isOrg
      let isOnLastEntityWithNoAuthRepSigner =
        signerBlock.signatories.length > 1 &&
        signerBlock.signatories[idx] &&
        signerBlock.signatories[idx]?.isOrg &&
        idx === signerBlock.signatories.length - 1
      let isEntityNoAuthRepSigner =
        signerBlock.signatories.length === 1 &&
        signerBlock.signatories[0] &&
        signerBlock.signatories[0].isOrg
      let isAuthRepSigner = idx === signerBlock.signatories.length - 1
      let isAuthRepSignerAnEntity =
        signerBlock.signatories[idx]?.isOrg && isAuthRepSigner
      let topEntitySigner = !isIndividualSigner && signerBlock.signatories[0]
      let signatoryStartX = Math.min(
        // If signatory is the top entity or immediate auth rep, align left at
        // blockXPosition.
        idx <= 1 && !isOnLastEntityWithNoAuthRepSigner
          ? blockXPosition
          : // If signatory is not the top entity or immediate auth rep, align
            // left 7 spaces from the previous signatory left position.
            blockXPosition +
              // If is on last entity with no auth rep signer, align by actual
              // index, otherwise align by index - 1.
              (isOnLastEntityWithNoAuthRepSigner ? idx : idx - 1) *
                CURRENT_NESTING_X_SPACING,
        // Cap left nesting at 4 levels deep
        blockXPosition + 4 * CURRENT_NESTING_X_SPACING
      )

      /* ---------------------------------------------------------
       * ${signatory.name}
       * ---------------------------------------------------------
       */
      let signatoryNameString = signatory.name
      let signatoryNameMarks: { type: string }[] = []

      const activeDescriptionFormat = block.blockStyles.signatoryDescription

      // We need to know if the top-most entity in the chain has a config that does NOT equal
      // the default signatory options. If it does, we will use that config for all signatories
      let doesTopEntityHaveConfig = Boolean(
        topEntitySigner &&
          topEntitySigner.instapagesConfig &&
          !isEqualValues(
            topEntitySigner.instapagesConfig,
            DEFAULT_SIGNATORY_OPTIONS
          )
      )

      let configToUse =
        topEntitySigner &&
        topEntitySigner.instapagesConfig &&
        doesTopEntityHaveConfig
          ? topEntitySigner.instapagesConfig
          : block.blockStyles.title

      const activeTitleFormat = configToUse.format

      if (
        activeDescriptionFormat.isEnabled &&
        signatory.description &&
        SignatoryTitleOptions.ITS &&
        activeDescriptionFormat.format === SignatoryDescriptionOptions.STYLE2
      ) {
        // If we have a signatory description, we need to add a comma after the name. The description
        // gets added either after this string or on the next line, depending on the format
        signatoryNameString = `${signatory.name}, `
      }

      let signatoryNameContent = [
        {
          text: signatoryNameString,
          marks: signatoryNameMarks,
          type: "text",
        },
      ]

      // If the signatory has a description and the format is in line with the name, we need combine
      // the name and description into one line. Note that the signatoryNameString is used in the
      // top-level's signatoryNameContentLines, but for middle entities, it is used in the byLineContent
      // for STYLE1. For STYLE2, the description is added to the next line using signatoryDescriptionContentLines
      if (
        activeDescriptionFormat.isEnabled &&
        signatory.description &&
        activeDescriptionFormat.format === SignatoryDescriptionOptions.STYLE1
      ) {
        signatoryNameContent.push({
          text: `, ${signatory.description}`,
          marks: [],
          type: "text",
        })
      }

      let byLineContentString = `By: ${signatoryNameString}${
        activeTitleFormat === 2 &&
        signatory.title &&
        configToUse.entity === SignatoryTitleOptions.ITS &&
        (!activeDescriptionFormat.isEnabled || !signatory.description)
          ? ","
          : ""
      }${
        signatory.description &&
        activeDescriptionFormat.isEnabled &&
        activeDescriptionFormat.format === SignatoryDescriptionOptions.STYLE1
          ? `, ${signatory.description}${
              activeTitleFormat === 2 && signatory.title ? "," : ""
            }`
          : ""
      }`

      let byLineMarks: { type: string }[] = []

      /* ---------------------------------------------------------
       * titleOrItsLabel logic
       * ---------------------------------------------------------
       */
      let titleOrItsLabel = "Title:"

      if (signatory.isOrg && !isEntityNoAuthRepSigner) {
        if (configToUse.entity === SignatoryTitleOptions.ITS) {
          titleOrItsLabel = "Its:"

          if (activeTitleFormat === 2) {
            titleOrItsLabel = "its "
          }

          if (activeTitleFormat === 1) {
            titleOrItsLabel = ""
          }
        }
      }

      if (!signatory.isOrg || isEntityNoAuthRepSigner) {
        if (configToUse.individual === SignatoryTitleOptions.ITS) {
          titleOrItsLabel = "Its:"

          if (activeTitleFormat === 2) {
            titleOrItsLabel = "its "
          }

          if (activeTitleFormat === 1) {
            titleOrItsLabel = ""
          }
        }
      }
      /* ---------------------------------------------------------
       * END -> titleOrItsLabel logic
       * ---------------------------------------------------------
       */

      if (
        activeTitleFormat === 1 &&
        (!isAuthRepSigner || isOnLastEntityWithNoAuthRepSigner) &&
        configToUse.entity === SignatoryTitleOptions.ITS
      ) {
        byLineContentString = `By: ${signatoryNameString}${
          activeDescriptionFormat.isEnabled &&
          signatory.description !== undefined &&
          signatory.description !== "" &&
          activeDescriptionFormat.format === SignatoryDescriptionOptions.STYLE1
            ? `, ${signatory.description}${signatory.title ? ", " : ""}`
            : ""
        }${
          signatory.title
            ? `${
                !signatory.description
                  ? ", "
                  : !activeDescriptionFormat.isEnabled
                    ? ", "
                    : ""
              }its ${signatory.title}${
                activeDescriptionFormat.isEnabled &&
                signatory.description !== "" &&
                activeDescriptionFormat.format ===
                  SignatoryDescriptionOptions.STYLE2
                  ? ","
                  : ""
              } `
            : ""
        }`
      }

      let byLineContent = [
        {
          text: byLineContentString,
          marks: byLineMarks,
        },
      ]

      let signatoryNameFieldContent = "Name: "
      if (!isEntityNoAuthRepSigner && !isOnLastEntityWithNoAuthRepSigner) {
        signatoryNameFieldContent += signatory.name
      }
      if (
        signatory.description &&
        activeDescriptionFormat.isEnabled &&
        activeDescriptionFormat.format === SignatoryDescriptionOptions.STYLE1 &&
        !isOnLastEntityWithNoAuthRepSigner &&
        !isEntityNoAuthRepSigner
      ) {
        signatoryNameFieldContent += ", " + signatory.description
      }
      if (
        signatory.description &&
        activeDescriptionFormat.isEnabled &&
        activeDescriptionFormat.format === SignatoryDescriptionOptions.STYLE2 &&
        activeTitleFormat !== 2 &&
        !isOnLastEntityWithNoAuthRepSigner &&
        !isEntityNoAuthRepSigner
      ) {
        signatoryNameFieldContent += ","
      }

      if (
        signatory.name &&
        activeTitleFormat === 2 &&
        !isEntityNoAuthRepSigner &&
        !isOnLastEntityWithNoAuthRepSigner &&
        (configToUse.individual === SignatoryTitleOptions.ITS ||
          (isAuthRepSignerAnEntity &&
            configToUse.entity === SignatoryTitleOptions.ITS))
      ) {
        signatoryNameFieldContent += ","
      }

      let signatoryNameFieldMarks: { type: string }[] = []

      if (
        activeTitleFormat === 1 &&
        isAuthRepSigner &&
        signatory.name &&
        !isEntityNoAuthRepSigner &&
        !isOnLastEntityWithNoAuthRepSigner &&
        (configToUse.individual === SignatoryTitleOptions.ITS ||
          (isAuthRepSignerAnEntity &&
            configToUse.entity === SignatoryTitleOptions.ITS))
      ) {
        signatoryNameFieldContent = `Name: ${signatory.name}${
          configToUse.individual === SignatoryTitleOptions.ITS ||
          (isAuthRepSignerAnEntity &&
            configToUse.entity === SignatoryTitleOptions.ITS)
            ? `,${
                signatory.description &&
                activeDescriptionFormat.isEnabled &&
                activeDescriptionFormat.format ===
                  SignatoryDescriptionOptions.STYLE2
                  ? ` ${signatory.description},`
                  : ""
              }`
            : ""
        } ${
          configToUse.individual === SignatoryTitleOptions.ITS ||
          (isAuthRepSignerAnEntity &&
            configToUse.entity === SignatoryTitleOptions.ITS)
            ? `${
                signatory.description &&
                activeDescriptionFormat.isEnabled &&
                activeDescriptionFormat.format ===
                  SignatoryDescriptionOptions.STYLE1
                  ? signatory.description + ", "
                  : ""
              }its`
            : ""
        } ${signatory.title || ""}`
      }

      if (block.blockStyles.signatoryName.bold) {
        signatoryNameMarks.push({ type: "bold" })
      }

      if (block.blockStyles.signatoryName.italic) {
        signatoryNameMarks.push({ type: "italic" })
      }

      if (block.blockStyles.signatoryName.underline) {
        signatoryNameMarks.push({ type: "underline" })
      }

      if (block.blockStyles.signatoryName.textTransform === "uppercase") {
        signatoryNameMarks.push({ type: "uppercase" })
      }

      if (block.blockStyles.byLineLabels.bold) {
        byLineMarks.push({ type: "bold" })
      }

      // If the signatory has a description and the format is in line with the name, we need combine the name and description into one line
      let signatoryNameContentLines = fieldContentToLines(
        signatoryNameContent,
        [],
        signatoryStartX
      )

      let byLineContentLines = fieldContentToLines(
        byLineContent,
        byLineMarks,
        signatoryStartX
      )

      let signatoryNameFieldStartX: number = signatoryStartX

      if (
        activeTitleFormat === 1 &&
        isAuthRepSigner &&
        configToUse.individual === SignatoryTitleOptions.ITS
      ) {
        signatoryNameFieldStartX = signatoryStartX + FIELD_LINE_TAB_STOP
      }

      let signatoryNameFieldContentLines = fieldContentToLines(
        signatoryNameFieldContent,
        signatoryNameFieldMarks,
        signatoryNameFieldStartX
      )

      // If we have a description that is formatted to the next line, we need to get the content lines for the description
      let signatoryDescriptionContentLines =
        activeDescriptionFormat.isEnabled &&
        typeof signatory.description === "string" &&
        signatory.description !== "" &&
        activeDescriptionFormat.format === SignatoryDescriptionOptions.STYLE2
          ? fieldContentToLines(
              `${signatory.description}${
                activeTitleFormat === 2 && signatory.title ? "," : ""
              } `,
              [],
              isAuthRepSigner
                ? signatoryStartX + FIELD_LINE_TAB_STOP
                : signatoryStartX
            )
          : []

      const enabledAdditionalFields = block.additionalFields.filter(
        (field) => field.isEnabled
      )

      const signatoryAddress = signerBlock.signatories[0]?.address || ""

      let signatoryAddressContentLines = fieldContentToLines(
        signatoryAddress,
        [],
        signatoryStartX
      )

      const keyToTitleCase = (s: string) =>
        s.replace(/^_*(.)|_+(.)/g, (_: string, c: string, d: string) =>
          c ? c.toUpperCase() : " " + d.toUpperCase()
        )

      const fieldLabelStyles = {
        family: fontFamily,
        lineHeight: lineHeight,
        size: `${fontSize}px`,
      }

      let getCustomFieldKeyWidth = (name: string) => {
        return textWidth(keyToTitleCase(name) + ":  ", fieldLabelStyles)
      }

      let additionalFieldsContentLinesArray: Content[][][] = []

      enabledAdditionalFields.forEach((field) => {
        const matchingField = AdditionalFieldsItems.find(
          (item) => item.key === field.key
        )

        if (matchingField) {
          additionalFieldsContentLinesArray.push(
            fieldContentToLines(matchingField.name, [], signatoryStartX)
          )
          return
        }

        if (field.isCustomValue) {
          // use the top entity's custom field value if it exists,
          // otherwise look for the custom field value in the current signatory
          let matchingCustomField =
            signerBlock.signatories[0]?.customValues?.find(
              (f) => f.label === field.key
            ) ?? signatory.customValues?.find((f) => f.label === field.key)

          if (matchingCustomField && !!matchingCustomField.value) {
            additionalFieldsContentLinesArray.push(
              fieldContentToLines(
                String(field.name) + ":  " + matchingCustomField.value,
                [],
                signatoryStartX
              )
            )
          } else if (
            matchingCustomField &&
            (matchingCustomField.value === undefined ||
              matchingCustomField.value === "")
          ) {
            additionalFieldsContentLinesArray.push(
              fieldContentToLines(String(field.name) + ":", [], signatoryStartX)
            )
          }
        }
      })

      if (isIndividualSigner) {
        signatoryNameContentLines.forEach((contentLine) => {
          contentLine.forEach((content) => {
            let textContent = content.text || ""
            addTextContentToLineIndex(
              lineIndex,
              {
                text: textContent,
                marks: content.marks,
              },
              signatoryStartX
            )
          })

          lineIndex++
        })

        if (signatoryDescriptionContentLines.length > 0) {
          signatoryDescriptionContentLines.forEach((contentLine) => {
            contentLine.forEach((content) => {
              let textContent = content.text || ""
              addTextContentToLineIndex(
                lineIndex,
                {
                  text: textContent,
                  marks: content.marks,
                },
                signatoryStartX
              )
            })
            lineIndex++
          })
        }

        addPlaceholderContentToLineIndex(lineIndex, {
          type: "spacer",
          x: blockXPosition,
        })

        // add placeholder line if only one individual signer
        addPlaceholderContentToLineIndex((lineIndex += 1), {
          type: "signature",
          x: signatoryStartX,
        })
        lineIndex += 1
      } else {
        if (isEntityNoAuthRepSigner) {
          signatoryNameContentLines.forEach((contentLine) => {
            contentLine.forEach((content) => {
              let textContent = content.text || ""
              addTextContentToLineIndex(
                lineIndex,
                {
                  text: textContent,
                  marks: content.marks,
                },
                signatoryStartX
              )
            })
            lineIndex++
          })
          // If we have a description that is formatted to the next line, we need to add it as a new line
          // Note this is duplicated code from above, but we need to add the description after the by line for each entity too
          if (signatoryDescriptionContentLines.length > 0) {
            signatoryDescriptionContentLines.forEach((contentLine) => {
              contentLine.forEach((content) => {
                let textContent = content.text || ""
                addTextContentToLineIndex(
                  lineIndex,
                  {
                    text: textContent,
                    marks: content.marks,
                  },
                  signatoryStartX
                )
              })
              lineIndex++
            })
          }

          lineIndex += 1
        }

        if (idx === 0 && idx !== signerBlock.signatories.length - 1) {
          signatoryNameContentLines.forEach((contentLine) => {
            contentLine.forEach((content) => {
              let textContent = content.text || ""

              addTextContentToLineIndex(
                lineIndex,
                {
                  text: textContent,
                  marks: content.marks,
                },
                signatoryStartX
              )
            })
            lineIndex++
          })

          // If we have a description that is formatted to the next line, we need to add it as a new line
          // Note this is duplicated code from above, but we need to add the description after the by line for each entity too
          if (signatoryDescriptionContentLines.length > 0) {
            signatoryDescriptionContentLines.forEach((contentLine) => {
              contentLine.forEach((content) => {
                let textContent = content.text || ""
                addTextContentToLineIndex(
                  lineIndex,
                  {
                    text: textContent,
                    marks: content.marks,
                  },
                  signatoryStartX
                )
              })
              lineIndex++
            })
          }

          // After name & (maybe) description, add some spacing between the next content
          lineIndex++

          addPlaceholderContentToLineIndex(lineIndex + 1, {
            type: "spacer",
            x: blockXPosition,
          })

          return
        }

        /* ---------------------------------------------------------
         * By:
         * ---------------------------------------------------------
         */
        if (!isAuthRepSigner || isOnLastEntityWithNoAuthRepSigner) {
          /* Adds by and title lines for all middle entity signatories
          /* ---------------------------------------------------------
          *  By: ${signatory.name}
          *  Title: ${signatory.title} || (dont render anything)
          /* ---------------------------------------------------------
          */
          byLineContentLines.forEach((contentLine) => {
            contentLine.forEach((content) => {
              let textContent = content.text || ""
              addTextContentToLineIndex(
                lineIndex,
                {
                  text: textContent,
                  marks: content.marks,
                },
                isOnLastEntityWithNoAuthRepSigner
                  ? signatoryStartX - CURRENT_NESTING_X_SPACING
                  : signatoryStartX
              )
            })
            lineIndex++
          })

          // If we have a description that is formatted to the next line, we need to add it as a new line
          // Note this is duplicated code from above, but we need to add the description after the by line for each entity too
          if (signatoryDescriptionContentLines.length > 0) {
            signatoryDescriptionContentLines.forEach((contentLine) => {
              contentLine.forEach((content) => {
                let textContent = content.text || ""
                addTextContentToLineIndex(
                  lineIndex,
                  {
                    text: textContent,
                    marks: content.marks,
                  },
                  signatoryStartX + CURRENT_NESTING_X_SPACING * 2
                )
              })
              lineIndex++
            })
          }

          /* ---------------------------------------------------------
           * Middle entity title line(s)
           * ---------------------------------------------------------
           */

          if (signatory.title) {
            let isTitleALabel =
              titleOrItsLabel === "Title:" || titleOrItsLabel === "Its:"
            let isInlineTitleField = configToUse.format === 1
            let labelWidth = textWidth(titleOrItsLabel)
            let labelContentLines = fieldContentToLines(titleOrItsLabel)
            labelContentLines.forEach((contentLine) => {
              addTextContentToLineIndex(
                lineIndex,
                {
                  text: contentLine[0]?.text || "",
                },
                signatoryStartX
              )
            })

            if (isTitleALabel) {
              let widthOfNameLabel = textWidth(
                titleOrItsLabel + " ",
                fieldLabelStyles
              )
              let titleContentLines = fieldContentToLines(
                signatory.title || "",
                [],
                signatoryStartX + widthOfNameLabel
              )
              titleContentLines.forEach((contentLine) => {
                contentLine.forEach((content) => {
                  addTextContentToLineIndex(
                    lineIndex,
                    {
                      text: content.text || "",
                      marks: content.marks,
                    },
                    signatoryStartX + widthOfNameLabel
                  )
                })
                lineIndex++
              })
            } else if (!isInlineTitleField) {
              let titleContentLines = fieldContentToLines(
                signatory.title || "",
                [],
                // This is weird - don't have to do this for final auth rep
                signatoryStartX + labelWidth
              )
              titleContentLines.forEach((contentLine) => {
                contentLine.forEach((content) => {
                  addTextContentToLineIndex(
                    lineIndex,
                    {
                      text: content.text || "",
                      marks: content.marks,
                    },
                    signatoryStartX + labelWidth
                  )
                })
                lineIndex++
              })
            }
          }
          /* ---------------------------------------------------------
           * END -> Middle entity title line(s)
           * ---------------------------------------------------------
           */

          let hasDescription = signatoryDescriptionContentLines.length > 0
          let hasTitle = Boolean(signatory.title)

          if (hasDescription || hasTitle) {
            lineIndex++
          }
          if (!hasDescription && !hasTitle) {
            lineIndex++
          }

          addPlaceholderContentToLineIndex(lineIndex + 1, {
            type: "spacer",
            x: blockXPosition,
          })

          if (!isOnLastEntityWithNoAuthRepSigner) {
            return
          }
        }
        /* ---------------------------------------------------------
         * By: __________________________
         * ---------------------------------------------------------
         */

        addTextContentToLineIndex(
          lineIndex,
          {
            text: "By:",
            marks: [],
          },
          signatoryStartX
        )

        addPlaceholderContentToLineIndex(lineIndex, {
          type: "signature",
          x: signatoryStartX + FIELD_LINE_TAB_STOP,
        })

        lineIndex++

        /* ---------------------------------------------------------
         * Name: {signatory.name}
         * ---------------------------------------------------------
         */
        let hasName =
          Boolean(signatory.name) && !isOnLastEntityWithNoAuthRepSigner

        if (!hasName || isEntityNoAuthRepSigner) {
          let xEnd = blockXEndPosition

          if (
            activeTitleFormat === 1 &&
            configToUse.individual === SignatoryTitleOptions.ITS
          ) {
            xEnd = signatoryStartX + FIELD_LINE_TAB_STOP + 72
          }

          addPlaceholderContentToLineIndex(lineIndex, {
            type: "name",
            x: signatoryStartX + FIELD_LINE_TAB_STOP,
            xEnd,
          })
        }

        signatoryNameFieldContentLines.forEach((contentLine, contentIdx) => {
          contentLine.forEach((content) => {
            let textContent = content.text || ""
            addTextContentToLineIndex(
              lineIndex,
              {
                text: textContent,
                marks: content.marks,
              },
              contentIdx > 0
                ? signatoryStartX + FIELD_LINE_TAB_STOP
                : signatoryStartX
            )
          })
          lineIndex++
        })

        if (
          signatoryDescriptionContentLines.length > 0 &&
          activeDescriptionFormat.format ===
            SignatoryDescriptionOptions.STYLE2 &&
          activeTitleFormat !== 1 &&
          !isOnLastEntityWithNoAuthRepSigner &&
          !isEntityNoAuthRepSigner
        ) {
          signatoryDescriptionContentLines.forEach((contentLine) => {
            contentLine.forEach((content) => {
              let textContent = content.text || ""
              addTextContentToLineIndex(
                lineIndex,
                {
                  text: textContent,
                  marks: content.marks,
                },
                signatoryStartX + FIELD_LINE_TAB_STOP
              )
            })
            lineIndex++
          })
        }

        /* ---------------------------------------------------------
         * Title: {signatory.title} || _____________________________
         * ---------------------------------------------------------
         */
        let hasTitle =
          Boolean(signatory.title) && !isOnLastEntityWithNoAuthRepSigner

        if (!hasTitle) {
          let isCurrentSignatoryShowingIts = false

          if (configToUse.entity === SignatoryTitleOptions.ITS) {
            if (
              signatory.isOrg &&
              !isEntityNoAuthRepSigner &&
              !isOnLastEntityWithNoAuthRepSigner
            ) {
              isCurrentSignatoryShowingIts = true
            }
          }

          if (configToUse.individual === SignatoryTitleOptions.ITS) {
            if (
              !signatory.isOrg ||
              Boolean(isEntityNoAuthRepSigner) ||
              isOnLastEntityWithNoAuthRepSigner
            ) {
              isCurrentSignatoryShowingIts = true
            }
          }

          let isInlineTitleField = configToUse.format === 1

          let hasName = signatoryNameFieldContent !== "Name: "

          if (isInlineTitleField && isCurrentSignatoryShowingIts) {
            let lastLineWidth: number = textWidth(
              signatoryNameFieldContentLines[
                signatoryNameFieldContentLines.length - 1
              ]?.reduce((lineText, content) => lineText + content.text, "") ||
                ""
            )

            lineIndex--

            let x =
              !isOnLastEntityWithNoAuthRepSigner && !isEntityNoAuthRepSigner
                ? signatoryStartX + lastLineWidth
                : signatoryStartX + FIELD_LINE_TAB_STOP + lastLineWidth + 58

            if (!hasName) {
              addTextContentToLineIndex(
                lineIndex,
                {
                  text: ", its",
                },
                signatoryStartX + (blockXEndPosition - signatoryStartX) / 2
              )
            }

            addPlaceholderContentToLineIndex(lineIndex, {
              type: "title",
              x: x,
            })
          } else if (configToUse.format === 2 && isCurrentSignatoryShowingIts) {
            let itsTextWidth = textWidth("its ")
            let x = !isOnLastEntityWithNoAuthRepSigner
              ? signatoryStartX + itsTextWidth + FIELD_LINE_TAB_STOP
              : signatoryStartX + FIELD_LINE_TAB_STOP

            addPlaceholderContentToLineIndex(lineIndex, {
              type: "title",
              x: x,
            })
          } else {
            let x = signatoryStartX + FIELD_LINE_TAB_STOP

            if (
              activeTitleFormat === 2 &&
              configToUse.individual === SignatoryTitleOptions.ITS
            ) {
              x = signatoryStartX + FIELD_LINE_TAB_STOP + fontSize + 2
            }

            addPlaceholderContentToLineIndex(lineIndex, {
              type: "title",
              x: x,
            })
          }
        }

        /* ---------------------------------------------------------
         * FINAL Auth rep title line(s)
         * ---------------------------------------------------------
         */
        if (isOnLastEntityWithNoAuthRepSigner) {
          // we need to reset titleOrItsLabel
          titleOrItsLabel = "Title:"

          if (configToUse.individual === SignatoryTitleOptions.ITS) {
            titleOrItsLabel = "Its:"

            if (activeTitleFormat === 2) {
              titleOrItsLabel = "its "
            }

            if (activeTitleFormat === 1) {
              titleOrItsLabel = ""
            }
          }
        }

        let isTitleALabel =
          titleOrItsLabel === "Title:" || titleOrItsLabel === "Its:"
        let isInlineTitleField = configToUse.format === 1
        let isIndividualItsFormat =
          configToUse.individual === SignatoryTitleOptions.ITS
        // let labelWidth = textWidth(titleOrItsLabel)
        let labelContentLines = fieldContentToLines(titleOrItsLabel)
        labelContentLines.forEach((contentLine) => {
          if (contentLine.length === 0) {
            return
          }
          addTextContentToLineIndex(
            lineIndex,
            {
              text: contentLine[0]?.text || "",
            },
            activeTitleFormat === 2 &&
              isIndividualItsFormat &&
              !isOnLastEntityWithNoAuthRepSigner
              ? signatoryStartX + FIELD_LINE_TAB_STOP
              : signatoryStartX
          )
        })

        if (isTitleALabel) {
          let widthOfNameLabel = textWidth("Name: ", fieldLabelStyles)
          let titleContentLines = fieldContentToLines(
            isOnLastEntityWithNoAuthRepSigner ? "" : signatory.title || "",
            [],
            signatoryStartX + widthOfNameLabel
          )
          titleContentLines.forEach((contentLine) => {
            contentLine.forEach((content) => {
              addTextContentToLineIndex(
                lineIndex,
                {
                  text: content.text || "",
                  marks: content.marks,
                },
                signatoryStartX + FIELD_LINE_TAB_STOP
              )
            })
            lineIndex++
          })
        } else if (!isInlineTitleField) {
          let titleContentLines = fieldContentToLines(
            isOnLastEntityWithNoAuthRepSigner ? "" : signatory.title || "",
            [],
            isAuthRepSigner
              ? signatoryStartX + FIELD_LINE_TAB_STOP
              : signatoryStartX
          )
          titleContentLines.forEach((contentLine) => {
            contentLine.forEach((content) => {
              addTextContentToLineIndex(
                lineIndex,
                {
                  text: content.text || "",
                  marks: content.marks,
                },
                signatoryStartX + FIELD_LINE_TAB_STOP
              )
            })
            lineIndex++
          })
        }

        /* ---------------------------------------------------------
         * END -> Final Auth rep title line(s)
         * ---------------------------------------------------------
         */
      }

      /* ---------------------------------------------------------
       * Additional Fields: {fieldName} || value or placeholder tab
       * ---------------------------------------------------------
       */

      // Custom fields start at lineIndex + 1
      if (additionalFieldsContentLinesArray.length > 0) {
        lineIndex += 1
      }

      additionalFieldsContentLinesArray.forEach(
        (additionalFieldsContentLines, arrayIndex) => {
          additionalFieldsContentLines.forEach((contentLine, contentIdx) => {
            contentLine.forEach((content) => {
              if (content.text === "Address" && arrayIndex !== 0) {
                // add a blank line before an address block if its not the first one
                lineIndex += 1
              }

              let textContent = content.text || ""

              let isCustomField =
                enabledAdditionalFields[arrayIndex]?.isCustomValue

              addTextContentToLineIndex(
                lineIndex,
                {
                  text: isCustomField ? textContent : `${textContent}:  `,
                  marks: content.marks,
                },
                isCustomField && contentIdx > 0
                  ? signatoryStartX + 5
                  : signatoryStartX
              )

              if (textContent === "Address") {
                if (signatoryAddress) {
                  // Need to start the address content on the line after the address label
                  lineIndex += 1

                  signatoryAddressContentLines.forEach(
                    (contentLine, contentIdx) => {
                      contentLine.forEach((content) => {
                        let textContent = content.text || ""
                        addTextContentToLineIndex(
                          lineIndex,
                          {
                            text: textContent,
                            marks: content.marks,
                          },
                          signatoryStartX
                        )
                        if (
                          contentIdx !==
                          signatoryAddressContentLines.length - 1
                        ) {
                          lineIndex++
                        }
                      })
                    }
                  )
                } else {
                  addPlaceholderContentToLineIndex((lineIndex += 1), {
                    type: "address",
                    value: undefined,
                    x: signatoryStartX,
                  })
                  // Add space below address label to account for eSign fields yellow rectangle
                  lineIndex += 3
                }

                addPlaceholderContentToLineIndex(lineIndex, {
                  type: "spacer",
                  x: blockXPosition,
                })

                // Add a blank line after the address eSign field placeholder if it's not the
                // last one
                if (
                  arrayIndex !==
                  additionalFieldsContentLinesArray.length - 1
                ) {
                  lineIndex += 1
                }
              } else {
                let matchingValue
                if (content.text === "Email") {
                  // use the top entity's email value if it exists,
                  // otherwise look for the email value in the current signatory
                  matchingValue =
                    signerBlock.signatories[0]?.email ??
                    signatory.email ??
                    undefined
                }

                // use the top entity's custom field value if it exists,
                // otherwise look for the custom field value in the current signatory
                let matchingCustomField =
                  signerBlock.signatories[0]?.customValues?.find(
                    (f) => f.label === enabledAdditionalFields[arrayIndex]?.key
                  ) ??
                  signatory.customValues?.find(
                    (f) => f.label === enabledAdditionalFields[arrayIndex]?.key
                  )

                if (matchingCustomField) {
                  matchingValue = matchingCustomField.value
                }

                if (matchingValue) {
                  if (content.text === "Email") {
                    addTextContentToLineIndex(
                      lineIndex,
                      {
                        text: matchingValue,
                        marks: [],
                      },
                      signatoryStartX
                    )
                  }
                } else {
                  const valueText = content.text?.replace(":", "")
                  let type: "value" | "date" | "email"

                  switch (valueText) {
                    case "Email":
                      type = "email"
                      break
                    case "Signing Date":
                      type = "date"
                      break
                    default:
                      type = "value"
                      break
                  }

                  addPlaceholderContentToLineIndex(lineIndex, {
                    type,
                    value: valueText,
                    x:
                      signatoryStartX + getCustomFieldKeyWidth(valueText || ""),
                  })
                }
              }
            })

            lineIndex++
          })
        }
      )
    })
  }
}
