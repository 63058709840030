import * as React from "react"
import { Button } from "@blueprintjs/core"
import { apiClient, useAPIMutation } from "lib/api"
import { patchSignatureBlockTemplate } from "helpers/api"
import useToaster from "helpers/toaster"
import { SignatureBlock } from "models/InstaPageV2/Blocks/SignatureBlock"
import { useTransactionIdParam } from "helpers/params"

type UpdateTransactionSignatureBlockTemplateProps = {
  templateId: string
  transactionId: string
  signatureBlock: SignatureBlock
}

export default function UpdateTransactionSignatureBlockTemplate({
  templateId,
  signatureBlock,
}: UpdateTransactionSignatureBlockTemplateProps) {
  let { updateSignatureBlockTemplate, isLoading } =
    useUpdateTransactionSignatureBlockTemplate()
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    updateSignatureBlockTemplate({
      templateId,
      signatureBlock,
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Button
        className="cursor-default"
        disabled={isLoading}
        minimal
        intent="primary"
        type="submit"
      >
        Update transaction style
      </Button>
    </form>
  )
}

function useUpdateTransactionSignatureBlockTemplate() {
  let transactionId = useTransactionIdParam()
  let { failure } = useToaster()

  let result = useAPIMutation(
    (data: { templateId: string; signatureBlock: SignatureBlock }) =>
      patchSignatureBlockTemplate(data.templateId, data.signatureBlock),
    {
      onSuccess: () => {
        apiClient.refetchQueries([
          "transactions",
          transactionId,
          "signature-block-templates",
        ])
      },
      onError: () => {
        failure(
          "We ran into an issue attempting to update the transaction style. Please try again."
        )
      },
    }
  )

  return {
    ...result,
    updateSignatureBlockTemplate: result.mutate,
  }
}
