import { BlockStyles, TitleType } from "models/InstaPageV2/Blocks/BlockStyles"
import {
  SignatoryFormatOptions,
  SignatoryInstapagesConfig,
  SignatoryTitleOptions,
} from "models/Signatory"

type TitleFormatConfigurationProps = {
  key: keyof TitleType | "signatoryFormat"
  value: SignatoryFormatOptions | SignatoryTitleOptions | undefined
  entityChecked: boolean
  individualChecked: boolean
  currentValues?: BlockStyles[keyof BlockStyles] | SignatoryInstapagesConfig

  currentFormat: SignatoryFormatOptions
}

export const titleFormatConfiguration = ({
  key,
  value,
  entityChecked,
  individualChecked,
  currentValues,
  currentFormat,
}: TitleFormatConfigurationProps) => {
  let toggleFormatTo = key === "format" ? value : currentFormat

  /*------------------------------------------------------------------------
   * If we are toggling the entity switch "off" (to SignatoryTitleOptions.TITLE)
   * and the individual switch is already "off", we should toggle the format
   * to the 4th option (index of 3, or SignatoryFormatOptions.STYLE4)
   * -----------------------------------------------------------------------
   */
  if (
    key === "entity" &&
    value === SignatoryTitleOptions.TITLE &&
    entityChecked &&
    !individualChecked
  ) {
    toggleFormatTo = currentFormat !== 3 ? 3 : currentFormat
  }

  /*------------------------------------------------------------------------
   * Similarly, if we are toggling the individual switch "off" (to SignatoryTitleOptions.TITLE)
   * and the entity switch is already "off", we should toggle the format
   * to the 4th option (index of 3, or SignatoryFormatOptions.STYLE4)
   * -----------------------------------------------------------------------
   */
  if (
    key === "individual" &&
    value === SignatoryTitleOptions.TITLE &&
    !entityChecked &&
    individualChecked
  ) {
    toggleFormatTo = currentFormat !== 3 ? 3 : currentFormat
  }

  /*------------------------------------------------------------------------
   * Conversely, if both switches are off and we are turning either one on,
   * the 4th option (index 3) no longer makes sense, so we reset the format
   * to the 1st option (index of 0, or SignatoryFormatOptions.STYLE1)
   * -----------------------------------------------------------------------
   */
  if (
    (key === "individual" || key === "entity") &&
    !entityChecked &&
    !individualChecked
  ) {
    toggleFormatTo = currentFormat !== 0 ? 0 : currentFormat
  }

  let updatedValues =
    key !== "format"
      ? ({
          ...(currentValues as TitleType),
          [key]: value,
          format: toggleFormatTo,
        } as TitleType)
      : ({
          ...(currentValues as TitleType),
          format: value as SignatoryFormatOptions,
        } as TitleType)

  return updatedValues
}
