import * as React from "react"

import { Colors } from "@blueprintjs/core"
import styled from "@emotion/styled"

import InstapagePreview from "./InstapagePreview"
import AutoFitPagePreview from "./AutoFitPagePreview"
import SVGSignerPage from "features/insta-pages2/renderer/SVGSignerPage"
import { Page as PageModel } from "models/Pages"
import { getSignerBlocksForAssignment } from "features/insta-pages2/editor/use-selected-signer-block"

export { InstapagePreview, AutoFitPagePreview }

const Img = styled.img<
  { height?: string; width?: string; pageHeight?: number } & {
    border?: "green" | "blue"
    shadow?: boolean
    disabled?: boolean
  }
>`
  background-color: white;
  border: 1px solid #738694;
  border-radius: 2px;
  transition: 150ms;
  ${(props) =>
    props.border === "green"
      ? `outline: 2px solid ${Colors.FOREST4};`
      : props.border === "blue"
        ? `outline: 2px solid #4580E6;`
        : null}
  ${(props) =>
    props.shadow && `box-shadow: 0px 2px 8px rgba(92, 112, 128, 0.5);`}

  ${(props) =>
    props.border === "blue"
      ? `box-shadow: 0px 0px 0px 8px rgba(20, 87, 200, 0.10);`
      : props.border === "green"
        ? `box-shadow: 0px 0px 0px 8px rgba(67, 191, 77, 0.10);`
        : null}
  ${(props) => props.disabled && "opacity: 0.4;"}
`

export const PagePreview = styled(Img)`
  height: ${(props) => props?.height ?? "714px"};
  width: ${(props) => props?.width ?? "552px"};
`

export const ThumbnailPreview = styled(Img)`
  height: ${(props) => props?.height ?? "248px"};
  width: ${(props) => props?.width ?? "192px"};
`

export const PageNumberLabel = styled.div<{
  border?: "green" | "blue"
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 100px;
  border: 1px solid
    ${(props) =>
      props.border === "green"
        ? "#43BF4D33"
        : props.border === "blue"
          ? "#1457C833"
          : "#73869433"};

  background-color: ${(props) =>
    props.border === "green"
      ? "#43BF4D11"
      : props.border === "blue"
        ? "#1457C811"
        : "white"};
`

type PageProps = {
  page?: PageModel
  type?: "instapage" | "document" | "attachment" | "instapagev2"
  height?: number
  width?: number
  className?: string
}

export default function Page(props: PageProps) {
  let instapageSignerBlocks =
    (props.page &&
      props.page.assignments &&
      props.page.assignments[0] &&
      getSignerBlocksForAssignment(props.page.assignments[0])) ||
    []

  if (
    props.page &&
    props.page.type === "instapagev2" &&
    props.page.assignments.length > 0
  ) {
    return (
      <div
        style={{
          height: props.height || "auto",
          width: props.width || "auto",
        }}
        className={props.className}
      >
        <SVGSignerPage
          page={props.page}
          signerBlocks={instapageSignerBlocks}
          tabs={[]}
        />
      </div>
    )
  }

  return props.type === "instapage" ? (
    <div
      style={{ height: props.height || "auto", width: props.width || "auto" }}
      className={props.className}
    >
      <InstapagePreview {...props} />
    </div>
  ) : (
    <AutoFitPagePreview {...props} />
  )
}

type AutoSizeProps = {
  children: React.ReactNode
}

export const AutoSize = ({ children, ...props }: AutoSizeProps) => {
  let [width, setWidth] = React.useState(null)
  let [height, setHeight] = React.useState(null)
  let container = React.useRef(null)

  React.useLayoutEffect(() => {
    if (container.current) {
      setWidth(container.current.clientWidth)
      setHeight(container.current.clientHeight)
    }
  }, [container.current])

  return (
    <div ref={container} className="h-full w-full">
      {width &&
        React.cloneElement(React.Children.only(children), {
          ...props,
          width: String(width),
          height: String(height),
          pageHeight: height,
        })}
    </div>
  )
}
