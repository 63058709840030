import { Position, Menu, Icon, MenuItem, MenuDivider } from "@blueprintjs/core"
import { Popover2, Tooltip2 } from "@blueprintjs/popover2"
import IndentIcon from "icons/indent.svg"
import LeftIcon from "icons/left.svg"
import { useIPEditorSelectedBlock } from "../editor-store"
import { useItemIdParam } from "helpers/params"
import { getItemInstaPage } from "features/item/api"
import useUpdateInstaPage from "../useUpdateInstaPage"

export default function AuthRepNameAlignmentMenu({
  pageId,
}: {
  pageId: string
}) {
  let itemID = useItemIdParam()
  let { handleUpdateInstaPage } = useUpdateInstaPage(itemID)
  let { selectedBlockId } = useIPEditorSelectedBlock()

  let page = getItemInstaPage(itemID, pageId)

  let block = page?.blocks.find((b) => b.id === selectedBlockId)

  let activeAuthRepAlignment =
    block?.kind === "signature" ? block.authRepAlignment : "indent"

  function handleUpdateAuthRepAlignment(alignment: "indent" | "left") {
    if (page) {
      handleUpdateInstaPage({
        id: pageId,
        blocks: page.blocks.map((b) =>
          b.id === selectedBlockId ? { ...b, authRepAlignment: alignment } : b
        ),
      })
    }
  }

  return (
    <Popover2
      interactionKind="click"
      position={Position.BOTTOM}
      minimal
      className="invisible group-hover:visible"
      content={
        <Menu>
          <MenuDivider title="Entity alignment" />
          <MenuItem
            active={activeAuthRepAlignment === "indent"}
            onClick={() => {
              if (activeAuthRepAlignment !== "indent") {
                handleUpdateAuthRepAlignment("indent")
              }
            }}
            icon={<IndentIcon className="w-4" />}
            text="Indent each level"
          />
          <MenuItem
            active={activeAuthRepAlignment === "left"}
            onClick={() => {
              if (activeAuthRepAlignment !== "left") {
                handleUpdateAuthRepAlignment("left")
              }
            }}
            icon={<LeftIcon className="w-4" />}
            text="Left-align each level"
          />
        </Menu>
      }
      renderTarget={({ isOpen, ...targetProps }) => (
        <Tooltip2
          content="Entity alignment options"
          position={Position.TOP}
          hoverOpenDelay={500}
          disabled={isOpen}
          className="invisible group-hover:visible"
        >
          <button {...targetProps} className="bp4-button bp4-minimal">
            <Icon icon="more" />
          </button>
        </Tooltip2>
      )}
    />
  )
}
