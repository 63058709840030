import SVGPageRenderer from "components/item-detail/InstapageV2/SVGPageRenderer"
import { IPEditorStoreProvider } from "components/item-detail/InstapageV2/editor-store"
import { type InstaPageTab } from "models/InstaPageTab"
import { type InstaPageV2 } from "models/InstaPageV2"
import { type SignerBlock } from "models/InstaPageV2/SignerBlock"

type SVGSignerPageProps = {
  page: Omit<InstaPageV2, "assignments">
  tabs: InstaPageTab[]
  signerBlocks: SignerBlock[]
}

export default function SVGSignerPage({
  page,
  signerBlocks,
  tabs,
}: SVGSignerPageProps) {
  return (
    <IPEditorStoreProvider editorMode="preview">
      <SVGPageRenderer page={page} signerBlocks={signerBlocks} tabs={tabs} />
    </IPEditorStoreProvider>
  )
}
