import { SignatoryFormatOptions } from "models/Signatory"

export type TitleOption = {
  name: SignatoryFormatOptions
  firstLine: {
    lineBreak: boolean
    comma: boolean
    label: string
  }
  secondLine: {
    lineBreak: boolean
    comma: boolean
    label: string
  }
}

export default function useTitleOptions(): TitleOption[] {
  const TITLE_OPTIONS = [
    {
      name: SignatoryFormatOptions.STYLE1,
      firstLine: {
        lineBreak: true,
        comma: false,
        label: "By:",
      },
      secondLine: {
        lineBreak: false,
        comma: false,
        label: "Its:",
      },
    },
    {
      name: SignatoryFormatOptions.STYLE2,
      firstLine: {
        lineBreak: false,
        comma: true,
        label: "By:",
      },
      secondLine: {
        lineBreak: false,
        comma: false,
        label: "its",
      },
    },
    {
      name: SignatoryFormatOptions.STYLE3,
      firstLine: {
        lineBreak: true,
        comma: true,
        label: "By:",
      },
      secondLine: {
        lineBreak: false,
        comma: false,
        label: "its",
      },
    },
    {
      name: SignatoryFormatOptions.STYLE4,
      firstLine: {
        lineBreak: true,
        comma: false,
        label: "By:",
      },
      secondLine: {
        lineBreak: false,
        comma: false,
        label: "Title:",
      },
    },
  ]

  return TITLE_OPTIONS
}
