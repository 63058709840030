import { InstaPageTab } from "models/InstaPageTab"
import { Line } from "./blocks-to-lines"

/* --------------------------------------------------------------------------
 * SIGNATURE_BLOCK_PADDING
 * --------------------------------------------------------------------------
 * These values come from calculations around postitioning eSign field tabs
 * on a 72 DPI grid taking into account where the left and bottom edge of
 * the label font should appear.
 *
 * See https://www.notion.so/simplyagree/Determining-eSign-Field-Coordinates-7c1cc98a01ef498c81c7b52af2c3b265?pvs=4#64e2319360a04242acf13ae2b75bdbdb
 */
export const SIGNATURE_BLOCK_X_PADDING = 5.3
const SIGNATURE_BLOCK_Y_PADDING = 8

export default function linesToTabs(
  lines: (Line | undefined)[],
  tabs: InstaPageTab[],
  pageData: {
    marginX: number
    marginY: number
    lineHeight: number
  }
): InstaPageTab[] {
  for (const [lineIdx, line] of lines.entries()) {
    if (!line) continue

    line.nodes.forEach((node) => {
      if (node.type === "title") {
        tabs.push({
          tab_type: "title",
          x_position: pageData.marginX + node.x - SIGNATURE_BLOCK_X_PADDING,
          y_position: lineIdx * pageData.lineHeight + pageData.marginY - 2,
          required: false,
          assignment: node.assignmentId,
        })
      }

      if (node.type === "signature") {
        tabs.push({
          tab_type: "signature",
          x_position: pageData.marginX + node.x - SIGNATURE_BLOCK_X_PADDING,
          y_position:
            lineIdx * pageData.lineHeight +
            pageData.marginY -
            SIGNATURE_BLOCK_Y_PADDING,
          assignment: node.assignmentId,
          required: true,
        })
      }

      if (node.type === "name") {
        tabs.push({
          tab_type: "full_name",
          x_position: pageData.marginX + node.x - SIGNATURE_BLOCK_X_PADDING,
          y_position: lineIdx * pageData.lineHeight + pageData.marginY - 2,
          assignment: node.assignmentId,
          required: true,
        })
      }

      if (node.type === "date") {
        tabs.push({
          tab_type: "date",
          x_position: pageData.marginX + node.x - SIGNATURE_BLOCK_X_PADDING,
          y_position: lineIdx * pageData.lineHeight + pageData.marginY - 2,
          assignment: node.assignmentId,
          required: true,
        })
      }

      if (node.type === "email" || node.type === "value") {
        tabs.push({
          tab_type: "custom",
          x_position: pageData.marginX + node.x - SIGNATURE_BLOCK_X_PADDING,
          y_position: lineIdx * pageData.lineHeight + pageData.marginY - 2,
          assignment: node.assignmentId,
          required: true,
        })
      }

      if (node.type === "address") {
        let tab_type: InstaPageTab["tab_type"] = "custom"
        let x_position = pageData.marginX + node.x - SIGNATURE_BLOCK_X_PADDING
        let assignment = node.assignmentId

        tabs.push(
          ...Array.from({ length: 4 }).map(
            (_, i): InstaPageTab => ({
              tab_type,
              x_position,
              y_position:
                (lineIdx + i) * pageData.lineHeight + pageData.marginY - 2,
              assignment,
              required: i < 2 ? true : false,
            })
          )
        )
      }
    })
  }

  return tabs
}
