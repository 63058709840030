import { Node } from "features/insta-pages2/renderer/blocks-to-lines"

export default function getTSpanX(
  text: Node,
  textIndex: number,
  startX: number,
  pageAttrs: {
    marginX: number
  }
): number | undefined {
  if (text.type === "text") {
    let textAlign = text.textAlign
    if (textAlign === "center" || textAlign === "right") {
      // Text alignment for center and right is handled differently in the renderer so
      // we want to just set the x position to the block x position here
      return undefined
    }

    let currentTextX = text.blockX + pageAttrs.marginX

    // We specifically only support a tspan having a different x value
    // to the right of the startX ... for now
    let isCurrentTextXDifferentFromBlockStartX =
      startX !== currentTextX && currentTextX > startX

    if (isCurrentTextXDifferentFromBlockStartX) {
      return currentTextX
    }
  }

  return undefined
}
