type DimensionsType = {
  height: number
  width: number
}

export default function applyOptions<D extends DimensionsType>(
  dimensions: D,
  options: {
    contain?: DimensionsType
    force?: DimensionsType
  } = {}
): D {
  if (dimensions.width === 0 || dimensions.height === 0) {
    // NOTE Can't do anything with zero height / width img
    return dimensions
  }
  let imageRatio = dimensions.width / dimensions.height

  if (options.contain) {
    let imageWidth = Math.min(
      imageRatio * options.contain.height,
      options.contain.width
    )
    let imageHeight = imageWidth / imageRatio

    return { ...dimensions, height: imageHeight, width: imageWidth }
  }

  if (options.force) {
    if (options.force.width) {
      let imageWidth = options.force.width
      let imageHeight = imageWidth / imageRatio

      return { ...dimensions, height: imageHeight, width: imageWidth }
    }

    if (options.force.height) {
      let imageHeight = options.force.height
      let imageWidth = imageHeight * imageRatio

      return { ...dimensions, height: imageHeight, width: imageWidth }
    }
  }

  return dimensions
}
