import { FormEvent, MouseEvent, useState } from "react"
import { Button, Position, Menu } from "@blueprintjs/core"
import { Popover2, Tooltip2 } from "@blueprintjs/popover2"
import OnClickOutside from "components/util/OnClickOutside"
import { SettingsMenuButton } from "../UI"
import {
  BlockStyles,
  SignatoryDescriptionType,
} from "models/InstaPageV2/Blocks/BlockStyles"
import useSignatoryDescriptionOptions from "./useSignatoryDescriptionOptions"
import FormatSignatoryDescriptionPreview from "./FormatSignatoryDescriptionPreview"
import { SignatoryDescriptionOptions } from "models/Signatory"

type SignatoryDescriptionMenuProps = {
  handleUpdateBlockStyles: (
    newStyles: BlockStyles[keyof BlockStyles],
    fieldKey: keyof BlockStyles
  ) => void
  blockStyles?: BlockStyles[keyof BlockStyles]
}

export default function SignatoryDescriptionMenu({
  blockStyles,
  handleUpdateBlockStyles,
}: SignatoryDescriptionMenuProps) {
  const SIGNATORY_DESCRIPTION_OPTIONS = useSignatoryDescriptionOptions()
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  const [formatPopoverIsOpen, setFormatPopoverIsOpen] = useState(false)

  const activeFormat = (blockStyles as SignatoryDescriptionType)?.format

  const handleChange = (
    e: FormEvent<HTMLInputElement> | MouseEvent<HTMLDivElement>,
    value: SignatoryDescriptionType["format"]
  ) => {
    // prevent the click event from bubbling up to the parent element, which toggles the selected state of the FieldTile
    e.stopPropagation()

    // close the format menu if it was open
    setFormatPopoverIsOpen(false)

    if (typeof blockStyles === "object") {
      handleUpdateBlockStyles(
        {
          ...blockStyles,
          format: value,
        } as BlockStyles[keyof BlockStyles],
        "signatoryDescription"
      )
    }
  }

  return (
    <Popover2
      interactionKind="click"
      isOpen={popoverIsOpen}
      position={Position.BOTTOM}
      minimal
      className="invisible group-hover:visible"
      content={
        <OnClickOutside
          onClickOutside={() => {
            setPopoverIsOpen(false)
            setFormatPopoverIsOpen(false)
          }}
        >
          <div className="p-4 w-72" onClick={(e) => e.stopPropagation()}>
            <Popover2
              usePortal={false}
              isOpen={formatPopoverIsOpen}
              minimal
              position="bottom-left"
              popoverClassName="relative top-1"
              content={
                <OnClickOutside
                  onClickOutside={() => {
                    if (formatPopoverIsOpen) {
                      setFormatPopoverIsOpen(false)
                    }
                  }}
                >
                  <Menu
                    large
                    className="min-w-[300px] max-w-[325px] p-0 max-h-[400px] overflow-auto"
                  >
                    {SIGNATORY_DESCRIPTION_OPTIONS.map((option, index) => {
                      let isActive = index + 1 === activeFormat

                      return (
                        <div
                          key={option.name}
                          className={`p-4 border-b cursor-pointer last:border-b-0 hover:bg-gray-50 ${
                            isActive ? "bg-gray-50" : ""
                          }`}
                          onClick={(e) =>
                            handleChange(
                              e,
                              index === 0
                                ? SignatoryDescriptionOptions.STYLE1
                                : SignatoryDescriptionOptions.STYLE2
                            )
                          }
                        >
                          <div>
                            <FormatSignatoryDescriptionPreview
                              option={option}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </Menu>
                </OnClickOutside>
              }
            >
              <SettingsMenuButton
                rightIcon="caret-down"
                text="Format"
                minimal
                onClick={(e) => {
                  e.stopPropagation()
                  setFormatPopoverIsOpen(!formatPopoverIsOpen)
                }}
              />
            </Popover2>

            <div className="text-blue-6/50 pl-1">
              <FormatSignatoryDescriptionPreview
                option={SIGNATORY_DESCRIPTION_OPTIONS[activeFormat - 1]}
              />
            </div>
          </div>
        </OnClickOutside>
      }
    >
      <Tooltip2
        content="Format options"
        position={Position.TOP}
        hoverOpenDelay={500}
      >
        <Button
          minimal
          icon="more"
          onClick={(e) => {
            e.stopPropagation()
            setPopoverIsOpen(!popoverIsOpen)
          }}
        />
      </Tooltip2>
    </Popover2>
  )
}
