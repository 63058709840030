import { titleFormatConfiguration } from "components/item-detail/InstapageV2/FieldsFormatting/titleFormatConfiguration"
import { TitleType } from "models/InstaPageV2/Blocks/BlockStyles"
import {
  APISignatory,
  SignatoryFormatOptions,
  SignatoryTitleOptions,
  UpdatableSignatory,
} from "models/Signatory"
import { SignatoryWithRelations } from "models/SignatoryWithRelations"
import { Dispatch, SetStateAction } from "react"
import { UseMutateFunction } from "react-query"

export const DEFAULT_SIGNATORY_OPTIONS = {
  entity: SignatoryTitleOptions.NONE,
  individual: SignatoryTitleOptions.NONE,
  format: SignatoryFormatOptions.NONE,
}

export default function useUpdateSignatoryTitleOptions(
  updateSignatoryAsync: UseMutateFunction<
    APISignatory | null,
    unknown,
    UpdatableSignatory,
    unknown
  >
) {
  const updateTitleOptions = (
    key: keyof TitleType | "signatoryFormat",
    value: SignatoryFormatOptions | SignatoryTitleOptions | undefined,
    entityChecked: boolean,
    individualChecked: boolean,
    signatoryFormatEnabled: boolean,
    matchingSignatory: SignatoryWithRelations,
    setSignatoryFormatEnabled: Dispatch<SetStateAction<boolean>>
  ) => {
    // if key is signatoryFormat, toggle the signatoryFormatEnabled state
    if (key === "signatoryFormat") {
      const nextValue = !signatoryFormatEnabled
      setSignatoryFormatEnabled(nextValue)

      if (nextValue === false) {
        // if disabling the signatoryFormat, clear out the instapagesConfig
        updateSignatoryAsync({
          id: matchingSignatory.id,
          instapagesConfig: DEFAULT_SIGNATORY_OPTIONS,
          description: matchingSignatory.description,
        })
      } else {
        // if enabling the signatoryFormat, turn on Entity and individual and format as default
        updateSignatoryAsync({
          id: matchingSignatory.id,
          instapagesConfig: {
            entity: SignatoryTitleOptions.ITS,
            individual: DEFAULT_SIGNATORY_OPTIONS.individual,
            format: DEFAULT_SIGNATORY_OPTIONS.format,
          },
          description: matchingSignatory.description,
        })
      }
    } else {
      // if the key is not signatoryFormat, update the instapagesConfig with the new values

      let updatedValues = titleFormatConfiguration({
        key,
        value,
        entityChecked,
        individualChecked,
        currentValues:
          matchingSignatory.instapagesConfig ?? DEFAULT_SIGNATORY_OPTIONS,
        currentFormat:
          matchingSignatory.instapagesConfig?.format ??
          DEFAULT_SIGNATORY_OPTIONS.format,
      })

      updateSignatoryAsync({
        id: matchingSignatory.id,
        instapagesConfig: updatedValues,
        description: matchingSignatory.description,
      })
    }
  }

  return {
    updateTitleOptions,
  }
}
