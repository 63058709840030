import { IconPropsType } from "./IconPropTypes"

export default function SignatoryNameIcon({
  x,
  y,
  width = 16,
  height = 16,
  classNames = "",
}: IconPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x={x}
      y={y}
    >
      <g clipPath="url(#clip0_4103_88817)">
        <path
          d="M1.99219 8.97656V6.99219H8.00781V8.97656H1.99219ZM10.0078 11.0078H-0.0078125V4.99219H10.0078V6.00781H1.00781V9.99219H10.0078V11.0078ZM16.0078 4.99219V11.0078H12.9922V9.99219H14.9922V6.00781H12.9922V4.99219H16.0078ZM12.0078 12.2422C12.5859 12.7422 13.25 12.9922 14 12.9922H14.0078V14.0078H14C13.0833 14.0078 12.2552 13.7214 11.5156 13.1484C11.151 13.4297 10.7578 13.6432 10.3359 13.7891C9.91406 13.9349 9.46875 14.0078 9 14.0078H8.99219V12.9922H9C9.75521 12.9922 10.4193 12.75 10.9922 12.2656V3.70312C10.7214 3.47396 10.4193 3.30208 10.0859 3.1875C9.7526 3.07292 9.39062 3.01302 9 3.00781H8.99219V1.99219H9C9.47396 1.99219 9.92188 2.0625 10.3438 2.20312C10.7656 2.34375 11.1562 2.54948 11.5156 2.82031C12.2344 2.26823 13.0625 1.99219 14 1.99219H14.0078V3.00781H14C13.2292 3.00781 12.5651 3.2474 12.0078 3.72656V12.2422Z"
          fill="currentColor"
          className={classNames}
        />
      </g>
      <defs>
        <clipPath id="clip0_4103_88817">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
