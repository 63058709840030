import saveTransactionSignerBlockStyleIfOriginal from "features/insta-pages2/editor/save-transaction-signer-block-style-if-original"
import { useUpdateItemPage } from "features/item/api"
import { useParam, useTransactionIdParam } from "helpers/params"
import { apiClient } from "lib/api"
import { InstaPageV2 } from "models/InstaPageV2"
import { APIItem } from "models/Item"

export default function useUpdateInstaPage(itemId?: string) {
  let itemParamId = String(useParam("itemId"))
  let transactionId = useTransactionIdParam()

  const { updateItemPage } = useUpdateItemPage(itemId ? itemId : itemParamId, {
    postSuccess: (item: APIItem) => {
      saveTransactionSignerBlockStyleIfOriginal(item)
      apiClient.setQueryData<APIItem[]>(
        ["transactions", transactionId, "items"],
        (data) => {
          return data ? data.map((i) => (i.uuid === item.uuid ? item : i)) : []
        }
      )
    },
  })

  // TODO-TS: Create UpdatableInstaPage type
  function handleUpdateInstaPage(pageData: Partial<InstaPageV2>) {
    updateItemPage({
      pageId: pageData.id,
      apply: (p: InstaPageV2) => {
        return {
          ...p,
          ...pageData,
        }
      },
    })
  }

  return { handleUpdateInstaPage }
}
