import { Fragment, useRef } from "react"
import SVGTabRenderer from "./SVGTabRenderer"
import { groupByKey } from "helpers/array"
import { Line, _Text } from "features/insta-pages2/renderer/blocks-to-lines"
import { getBlockXPosition } from "./helpers"
import TSPanRenderer from "./TSpanRenderer"
import getTSpanX from "./get-tspan-x-value"
import { FontType } from "models/InstaPageV2"
import JustifiedLineText from "./JustifiedLineText"

type LineTextRendererProps = {
  pageFontFamily: FontType
  pageFontSize: number
  line: Line
  marginX: number
  lineYPosition: number
}

export default function LineTextRenderer({
  pageFontFamily,
  pageFontSize,
  line,
  marginX,
  lineYPosition,
}: LineTextRendererProps) {
  let textRef = useRef<SVGTextElement>(null)
  let textsByBlockId = groupByKey(line.nodes, "blockId")
  let lineHeight = line.lineHeight

  let fontSize = pageFontSize
  let tabFontSize = fontSize - 1
  let tabRectY = lineYPosition - lineHeight * 2 + lineHeight * 1.25 + 1
  let tabTextY =
    lineYPosition - lineHeight * 2 + lineHeight * 1.25 + lineHeight / 2 + 1

  return (
    <>
      {Object.values(textsByBlockId).map((texts) => {
        let textNodes = texts.filter((t): t is _Text => t.type === "text")
        let blockXPosition = texts[0] ? getBlockXPosition(texts[0], marginX) : 0
        let lineTextAlignment = textNodes[0]?.textAlign || "left"

        return (
          <Fragment key={texts[0]?.blockId || "block-key"}>
            {lineTextAlignment === "justify" ? (
              <JustifiedLineText
                blockXPosition={blockXPosition}
                lineYPosition={lineYPosition}
                pageAttrs={{
                  fontFamily: pageFontFamily,
                  fontSize: pageFontSize,
                  lineHeight: lineHeight,
                  marginX,
                }}
                textNodes={textNodes}
              />
            ) : (
              <text x={blockXPosition} y={lineYPosition} ref={textRef}>
                {textNodes.map((text, idx) => {
                  let isLastNode = idx === textNodes.length - 1
                  let shouldTrimEnd =
                    isLastNode &&
                    (lineTextAlignment === "center" ||
                      lineTextAlignment === "right")

                  if (shouldTrimEnd) {
                    text.text = text.text.trimEnd()
                  }

                  return (
                    <TSPanRenderer
                      key={text.id}
                      textNode={text}
                      x={getTSpanX(text, idx, blockXPosition, {
                        marginX,
                      })}
                    />
                  )
                })}
              </text>
            )}
            {texts.map((text) => {
              if (
                text.type === "title" ||
                text.type === "signature" ||
                text.type === "name" ||
                text.type === "value" ||
                text.type === "address" ||
                text.type === "email" ||
                text.type === "date"
              ) {
                return (
                  <SVGTabRenderer
                    key={text.id}
                    type={text.type}
                    value={text.value}
                    lineHeight={lineHeight}
                    fontSize={tabFontSize}
                    x={text.x + marginX}
                    rectY={tabRectY}
                    rectWidth={text.blockXEnd - text.x}
                    textX={text.x + marginX + 5}
                    textY={tabTextY}
                    lineX2={text.blockXEnd + marginX}
                    lineY={lineYPosition + 1}
                  />
                )
              }
            })}
          </Fragment>
        )
      })}
    </>
  )
}
