import { BlockStyles, TitleType } from "models/InstaPageV2/Blocks/BlockStyles"
import { SignatoryFormatOptions, SignatoryTitleOptions } from "models/Signatory"
import { titleFormatConfiguration } from "./titleFormatConfiguration"

export default function useUpdateTitleOptions(
  handleUpdateBlockStyles: (
    newStyles: BlockStyles[keyof BlockStyles],
    fieldKey: keyof BlockStyles
  ) => void
) {
  const updateTitleOptions = (
    key: keyof TitleType,
    value: SignatoryFormatOptions | SignatoryTitleOptions,
    entityChecked: boolean,
    individualChecked: boolean,
    blockStyles?: BlockStyles[keyof BlockStyles]
  ) => {
    const currentFormat = (blockStyles as TitleType)?.format

    let updatedValues = titleFormatConfiguration({
      key,
      value,
      entityChecked,
      individualChecked,
      currentValues: blockStyles,
      currentFormat,
    })

    handleUpdateBlockStyles(updatedValues, "title")
  }

  return {
    updateTitleOptions,
  }
}
