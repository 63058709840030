import { FormEvent, MouseEvent, useState } from "react"
import { Button, Position, Menu } from "@blueprintjs/core"
import { Popover2, Tooltip2 } from "@blueprintjs/popover2"
import OnClickOutside from "components/util/OnClickOutside"
import { Switch } from "@blueprintjs/core"
import { SettingsMenuButton } from "../UI"
import { SignatoryFormatOptions, SignatoryTitleOptions } from "models/Signatory"
import { BlockStyles, TitleType } from "models/InstaPageV2/Blocks/BlockStyles"
import useTitleOptions, { TitleOption } from "./useTitleOptions"
import useUpdateTitleOptions from "./useUpdateTitleOptions"
import FormatTitlePreview from "./FormatTitlePreview"

type TitleMenuProps = {
  handleUpdateBlockStyles: (
    newStyles: BlockStyles[keyof BlockStyles],
    fieldKey: keyof BlockStyles
  ) => void
  blockStyles?: BlockStyles[keyof BlockStyles]
}

export default function TitleMenu({
  blockStyles,
  handleUpdateBlockStyles,
}: TitleMenuProps) {
  const TITLE_OPTIONS = useTitleOptions()
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  const [formatPopoverIsOpen, setFormatPopoverIsOpen] = useState(false)

  const { updateTitleOptions } = useUpdateTitleOptions(handleUpdateBlockStyles)

  const entityChecked = Boolean(
    (blockStyles as TitleType)?.entity === SignatoryTitleOptions.ITS
  )

  const individualChecked = Boolean(
    (blockStyles as TitleType)?.individual === SignatoryTitleOptions.ITS
  )

  const activeFormat = (blockStyles as TitleType)?.format

  const handleChange = (
    e: FormEvent<HTMLInputElement> | MouseEvent<HTMLDivElement>,
    key: keyof TitleType,
    value: SignatoryFormatOptions | SignatoryTitleOptions
  ) => {
    // prevent the click event from bubbling up to the parent element, which toggles the selected state of the FieldTile
    e.stopPropagation()

    // close the format menu if it was open
    setFormatPopoverIsOpen(false)

    updateTitleOptions(
      key,
      value,
      entityChecked,
      individualChecked,
      blockStyles
    )
  }

  return (
    <Popover2
      interactionKind="click"
      isOpen={popoverIsOpen}
      position={Position.BOTTOM}
      minimal
      className="invisible group-hover:visible"
      content={
        <OnClickOutside
          onClickOutside={() => {
            setPopoverIsOpen(false)
            setFormatPopoverIsOpen(false)
          }}
        >
          <div className="p-4 w-72">
            <p className="font-semibold mb-2">
              For authorized representatives:
            </p>
            <Switch
              id="entity"
              checked={entityChecked}
              onChange={(e) =>
                handleChange(
                  e,
                  "entity",
                  entityChecked
                    ? SignatoryTitleOptions.TITLE
                    : SignatoryTitleOptions.ITS
                )
              }
              label={`Use "Its" if an entity`}
            />
            <Switch
              id="invidivual"
              checked={individualChecked}
              onChange={(e) =>
                handleChange(
                  e,
                  "individual",
                  individualChecked
                    ? SignatoryTitleOptions.TITLE
                    : SignatoryTitleOptions.ITS
                )
              }
              label={`Use "Its" if an individual`}
            />
            <Popover2
              usePortal={false}
              isOpen={formatPopoverIsOpen}
              minimal
              position="bottom-left"
              popoverClassName="relative top-1"
              content={
                <OnClickOutside
                  onClickOutside={() => {
                    if (formatPopoverIsOpen) {
                      setFormatPopoverIsOpen(false)
                    }
                  }}
                >
                  <Menu
                    large
                    className="min-w-[300px] max-w-[325px] p-0 max-h-[400px] overflow-auto"
                  >
                    {TITLE_OPTIONS.map((option, index) => {
                      let isActive = index === activeFormat

                      if (
                        // if neither are BOTH not checked, only show the last format option
                        (!entityChecked && !individualChecked && index !== 3) ||
                        // if either one are checked, hide the last format option
                        ((entityChecked || individualChecked) && index === 3)
                      ) {
                        return null
                      }

                      return (
                        <div
                          key={option.name}
                          className={`p-4 border-b cursor-pointer last:border-b-0 hover:bg-gray-50 ${
                            isActive ? "bg-gray-50" : ""
                          }`}
                          onClick={(e) => handleChange(e, "format", index)}
                        >
                          <div>
                            <FormatTitlePreview option={option} />
                          </div>
                        </div>
                      )
                    })}
                  </Menu>
                </OnClickOutside>
              }
            >
              <SettingsMenuButton
                rightIcon="caret-down"
                text={`"Its" format`}
                minimal
                disabled={!entityChecked && !individualChecked}
                onClick={() => setFormatPopoverIsOpen(!formatPopoverIsOpen)}
              />
            </Popover2>

            <div className="text-blue-6/50 pl-1">
              <FormatTitlePreview
                option={TITLE_OPTIONS[activeFormat ?? 0] as TitleOption}
              />
            </div>
          </div>
        </OnClickOutside>
      }
    >
      <Tooltip2
        content="Title options"
        position={Position.TOP}
        hoverOpenDelay={500}
      >
        <Button
          minimal
          icon="more"
          onClick={(e) => {
            e.stopPropagation()
            setPopoverIsOpen(!popoverIsOpen)
          }}
        />
      </Tooltip2>
    </Popover2>
  )
}
