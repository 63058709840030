import { useState } from "react"
import cx from "classnames"
import AddBlockMenu from "./AddBlockMenu"
import PlusIcon from "./Icons/PlusIcon"
import { getGridPosition, getInsertBarPosition } from "./helpers"
import { useIPEditorAddBlock, useIPEditorToggleDebug } from "./editor-store"
import useUpdateTextBlock from "./useUpdateTextBlock"
import { type BlockGrid } from "models/InstaPageV2/Block"

export type InsertRendererProps = {
  pageId: string
  insertIdx: number
  grid: BlockGrid
  blockIndex: number
  y: number
  height: number
  marginX: number
  marginY: number
  isAddFooterDisabled: boolean
  isAddLeadInDisabled: boolean
  noLeadIn: boolean
}

export default function InsertRenderer({
  pageId,
  insertIdx,
  grid,
  blockIndex,
  y,
  height,
  marginX,
  isAddFooterDisabled,
  isAddLeadInDisabled,
  noLeadIn,
}: InsertRendererProps) {
  let { isDebug } = useIPEditorToggleDebug()
  let { addBlock, setAddBlock } = useIPEditorAddBlock()
  let gridPosition = getGridPosition(grid)
  let insertBarPosition = getInsertBarPosition(
    gridPosition,
    marginX,
    noLeadIn ? y - height : y,
    height
  )

  let { handleAddTextBlock, handleAddLeadInBlock, handleAddFooterBlock } =
    useUpdateTextBlock(pageId)
  const [isCircleHovered, setIsCircleHovered] = useState(false)

  let isColumn = gridPosition === "right" || gridPosition === "left"

  const insertBlockClassNames = cx(
    "transition-opacity duration-75 ease-in opacity-0 hover:opacity-100",
    {
      "opacity-100": !!addBlock && addBlock.insertIdx === insertIdx,
    }
  )

  const circleAnchorClassNames = cx("", {
    "fill-fuchsia-700": isCircleHovered,
    "fill-fuchsia-700/50": !isCircleHovered,
  })

  const insertBarLineClassNames = cx("", {
    "stroke-fuchsia-700": isCircleHovered,
    "stroke-fuchsia-700/50": !isCircleHovered,
  })

  const addBlockCircleClassNames = cx("cursor-pointer hover:opacity-100", {
    "opacity-100": !!addBlock,
    "opacity-50": !addBlock,
  })

  const blockHoverInsertClassNames = cx("", {
    "fill-none": !!addBlock,
    "fill-fuchsia-700/10": !addBlock,
  })

  return (
    <g>
      {isDebug && (
        <rect
          x={insertBarPosition.rectX}
          y={insertBarPosition.rectY}
          width={insertBarPosition.rectWidth}
          height={insertBarPosition.rectHeight}
          fill="gray"
          fillOpacity={0.1}
          stroke="gray"
          strokeWidth={1}
          strokeDasharray={2}
        />
      )}

      <g pointerEvents="all" className={insertBlockClassNames}>
        <rect
          x={insertBarPosition.rectX}
          y={insertBarPosition.rectY}
          width={insertBarPosition.rectWidth}
          height={insertBarPosition.rectHeight}
          fill="none"
        />
        {/* // insert block hover area */}
        <rect
          x={insertBarPosition.rectX - 2}
          y={
            isColumn
              ? insertBarPosition.rectY - 10
              : insertBarPosition.rectY + 5
          }
          width={insertBarPosition.rectWidth + 4}
          height={insertBarPosition.rectHeight - 10}
          className={blockHoverInsertClassNames}
          rx={3}
        />

        <circle
          cx={insertBarPosition.leftAnchorX}
          cy={insertBarPosition.leftAnchorY}
          r={2}
          className={circleAnchorClassNames}
        />
        <line
          x1={insertBarPosition.leftLineStartX}
          y1={insertBarPosition.leftLineStartY}
          x2={insertBarPosition.leftLineEndX}
          y2={insertBarPosition.leftLineEndY}
          className={insertBarLineClassNames}
        />
        <g
          className={addBlockCircleClassNames}
          pointerEvents="all"
          onClick={() => {
            setAddBlock({
              blockIndex: blockIndex,
              insertIdx,
              grid: grid,
              x: insertBarPosition.centerCircleX - 8,
              y: noLeadIn
                ? y - height + 5
                : isColumn
                  ? y - 8
                  : y + height / 2 - 8,
            })
          }}
          onMouseEnter={() => !addBlock && setIsCircleHovered(true)}
          onMouseLeave={() => !addBlock && setIsCircleHovered(false)}
        >
          {addBlock && addBlock.insertIdx === insertIdx && (
            <foreignObject>
              <AddBlockMenu
                isOpen={!!addBlock}
                onClose={() => {
                  setAddBlock(null)
                }}
                yPosition={addBlock.y}
                xPosition={addBlock.x}
                isAddFooterDisabled={isAddFooterDisabled}
                isAddLeadInDisabled={isAddLeadInDisabled}
                addLeadInBlock={handleAddLeadInBlock}
                addTextBlock={handleAddTextBlock}
                addFooterBlock={handleAddFooterBlock}
              />
            </foreignObject>
          )}
          <circle
            cx={insertBarPosition.centerCircleX}
            cy={insertBarPosition.centerCircleY}
            r={10}
            className="fill-fuchsia-700 relative"
          />
          <PlusIcon
            x={insertBarPosition.plusIconX}
            y={insertBarPosition.plusIconY}
            fill="white"
          />
        </g>
        <line
          x1={insertBarPosition.rightLineStartX}
          y1={insertBarPosition.rightLineStartY}
          x2={insertBarPosition.rightLineEndX}
          y2={insertBarPosition.rightLineEndY}
          className={insertBarLineClassNames}
        />
        <circle
          cx={insertBarPosition.rightAnchorX}
          cy={insertBarPosition.rightAnchorY}
          r={2}
          className={circleAnchorClassNames}
        />
      </g>
    </g>
  )
}
