// Hard code this for all of our document page images
const IMG_PPI = 144.0

type DimensionProperties = {
  naturalWidth?: number | null
  width?: number
}
export default function applyPrintScale<D extends DimensionProperties>(
  dimensions: D
): D & { printScale: number } {
  let widthToInches = (dimensions.naturalWidth || 0) / IMG_PPI
  let printScale = (dimensions.width || 0) / 72.0 / widthToInches || 1

  return { ...dimensions, printScale: printScale }
}
