import { IPRenderedPage } from "models/InstaPageV2"
import useUpdateInstaPage from "../useUpdateInstaPage"
import { Block } from "models/InstaPageV2/Block"

import { AdditionalFieldsType } from "models/InstaPageV2/Blocks/SignatureBlock"

export default function useUpdateAdditionalFields(
  pageId: IPRenderedPage["id"],
  selectedBlockId: string | null,
  blocks: Block[]
) {
  let { handleUpdateInstaPage } = useUpdateInstaPage()

  const updateAdditionalFields = (additionalFields: AdditionalFieldsType[]) => {
    handleUpdateInstaPage({
      id: pageId,
      blocks: blocks.map((block) =>
        block.id === selectedBlockId && block.kind === "signature"
          ? {
              ...block,
              additionalFields: additionalFields,
            }
          : block
      ),
    })
  }
  return {
    updateAdditionalFields,
  }
}
