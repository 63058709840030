import { IconPropsType } from "./IconPropTypes"

export default function BoldIcon({
  x,
  y,
  width = 20,
  height = 20,
  classNames = "",
}: IconPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x={x}
      y={y}
      viewBox="0 0 20 20"
    >
      <path
        d="M7 15V6H10.3022C10.9945 6 11.6204 6.21553 12.1799 6.64659C12.7394 7.07766 13.0191 7.65806 13.0191 8.38781C13.0191 8.89549 12.8966 9.30935 12.6514 9.62939C12.4063 9.94942 12.1422 10.1817 11.8592 10.3263C12.2075 10.4464 12.5402 10.684 12.8572 11.0388C13.1741 11.3937 13.3326 11.8716 13.3326 12.4724C13.3326 13.3406 13.0126 13.9789 12.3725 14.3874C11.7325 14.7958 11.0876 15 10.438 15H7ZM8.46033 13.6468H10.3831C10.9178 13.6468 11.2958 13.4983 11.517 13.2013C11.7382 12.9044 11.8488 12.6196 11.8488 12.347C11.8488 12.0744 11.7382 11.7897 11.517 11.4927C11.2958 11.1958 10.9074 11.0473 10.3518 11.0473H8.46033V13.6468ZM8.46033 9.73586H10.2225C10.6352 9.73586 10.9625 9.61787 11.2041 9.38187C11.4458 9.14587 11.5666 8.86328 11.5666 8.5341C11.5666 8.18402 11.4388 7.89794 11.1832 7.67587C10.9276 7.45382 10.6144 7.34279 10.2434 7.34279H8.46033V9.73586Z"
        fill="currentColor"
        className={classNames}
      />
    </svg>
  )
}
