import { IconPropsType } from "./IconPropTypes"

export default function PlusIcon({
  x,
  y,
  width = 14,
  height = 14,
  classNames = "",
  fill = "currentColor",
}: IconPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      x={x}
      y={y}
      textAnchor="middle"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7H9V3C9 2.45 8.55 2 8 2C7.45 2 7 2.45 7 3V7H3C2.45 7 2 7.45 2 8C2 8.55 2.45 9 3 9H7V13C7 13.55 7.45 14 8 14C8.55 14 9 13.55 9 13V9H13C13.55 9 14 8.55 14 8C14 7.45 13.55 7 13 7Z"
        fill={fill}
        className={classNames}
      />
    </svg>
  )
}
