import { IconPropsType } from "./IconPropTypes"

export default function UnderlineIcon({
  x,
  y,
  width = 20,
  height = 20,
  classNames = "",
}: IconPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x={x}
      y={y}
      viewBox="0 0 20 20"
    >
      <path
        d="M6 15V14.1922H13.1669V15H6ZM9.58344 13.0633C8.74593 13.0633 8.09225 12.809 7.62239 12.3005C7.15254 11.792 6.91761 11.1107 6.91761 10.2566V6H7.91599V10.3063C7.91599 10.8504 8.06064 11.2864 8.34994 11.6144C8.63924 11.9424 9.05041 12.1064 9.58344 12.1064C10.1165 12.1064 10.5276 11.9424 10.8169 11.6144C11.1062 11.2864 11.2509 10.8504 11.2509 10.3063V6H12.2493V10.2566C12.2493 11.1107 12.0143 11.792 11.5445 12.3005C11.0746 12.809 10.421 13.0633 9.58344 13.0633Z"
        fill="currentColor"
        className={classNames}
      />
    </svg>
  )
}
