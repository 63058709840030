import * as React from "react"
import { create, createContext } from "lib/store"
import { BlockGrid, BlockKindType } from "models/InstaPageV2/Block"

export type SelectedBlockType = {
  id: string
  x: number
  y: number
  width: number
  kind: BlockKindType
}

export type AddBlockType = {
  blockIndex: number
  insertIdx: number
  grid: BlockGrid
  x: number
  y: number
}

type IPEditorStore = {
  renderMode: "edit" | "preview"
  showMargins: boolean
  setShowMargins: (showMargins: boolean) => void
  isInDebugMode: boolean
  setDebugMode: (isInDebugMode: boolean) => void
  selectedBlockId: string | null
  setSelectedBlockId: (blockId: string | null) => void
  selectedAssignmentId: string | null
  setSelectedAssignmentId: (id: string | null) => void
  selectedEditingSignatoryAssignmentId: string | null
  setSelectedEditingSignatoryAssignmentId: (id: string | null) => void
  pageScaleRatio: number
  setPageScaleRatio: (ratio: number) => void
  addBlock: AddBlockType | null
  setAddBlock: (menu: AddBlockType | null) => void
  newBlockId: string
  setNewBlockId: (id: string) => void
}

const { Provider, useStore: useIPEditorStore } = createContext<IPEditorStore>()

export const useIPEditorShowMargins = () => {
  let { showMargins, setShowMargins } = useIPEditorStore()
  return { showMargins, setShowMargins }
}

export const useIPEditorToggleDebug = () => {
  let { isInDebugMode, setDebugMode } = useIPEditorStore()
  return { isDebug: isInDebugMode, setIsDebug: setDebugMode }
}

export const useIPEditorEditorMode = () => {
  let renderMode = useIPEditorStore((store) => store.renderMode)
  return renderMode
}

export const useIPEditorSelectedBlock = () => {
  let { selectedBlockId, setSelectedBlockId } = useIPEditorStore()
  return { selectedBlockId, setSelectedBlockId }
}

export const useIPEditorPageScaleRatio = () => {
  let { pageScaleRatio, setPageScaleRatio } = useIPEditorStore()
  return { pageScaleRatio, setPageScaleRatio }
}

export const useIPEditorAddBlock = () => {
  let { addBlock, setAddBlock } = useIPEditorStore()
  return { addBlock, setAddBlock }
}

export const useIPEditorNewBlockId = () => {
  let { newBlockId, setNewBlockId } = useIPEditorStore()
  return { newBlockId, setNewBlockId }
}

export const useIPEditorEditingAssignmentSignatories = () => {
  let {
    selectedEditingSignatoryAssignmentId,
    setSelectedEditingSignatoryAssignmentId,
  } = useIPEditorStore()
  return {
    selectedEditingSignatoryAssignmentId,
    setSelectedEditingSignatoryAssignmentId,
  }
}

export function IPEditorStoreProvider({
  children,
  editorMode = "preview",
  selectedBlockId = null,
}: {
  children: React.ReactNode
  editorMode?: "edit" | "preview"
  selectedBlockId?: string | null
}) {
  let createStore = React.useCallback(
    () =>
      create<IPEditorStore>((set) => ({
        renderMode: editorMode,
        showMargins: false,
        setShowMargins: (showMargins: boolean) =>
          set({ showMargins: showMargins }),
        isInDebugMode: false,
        setDebugMode: (isInDebugMode: boolean) =>
          set({ isInDebugMode: isInDebugMode }),
        selectedBlockId: selectedBlockId,
        setSelectedBlockId: (block: string | null) =>
          set({ selectedBlockId: block }),
        selectedAssignmentId: null,
        setSelectedAssignmentId: (id: string | null) =>
          set({ selectedAssignmentId: id }),
        selectedEditingSignatoryAssignmentId: null,
        setSelectedEditingSignatoryAssignmentId: (id: string | null) =>
          set({ selectedEditingSignatoryAssignmentId: id }),
        pageScaleRatio: 1,
        setPageScaleRatio: (ratio: number) => set({ pageScaleRatio: ratio }),
        addBlock: null,
        setAddBlock: (menu: AddBlockType | null) => set({ addBlock: menu }),
        newBlockId: "",
        setNewBlockId: (id: string) => set({ newBlockId: id }),
      })),

    [editorMode]
  )

  return <Provider createStore={createStore}>{children}</Provider>
}
