import { Line } from "./blocks-to-lines"

export default function yPositionFromLineIndex(
  idx: number,
  lines: (Line | undefined)[],
  defaultLineHeight = 15
) {
  let yPosition = 0

  for (let [lineIdx, line] of lines.entries()) {
    yPosition += line?.lineHeight || defaultLineHeight

    if (lineIdx === idx) {
      return yPosition
    }
  }

  return yPosition
}
