import { Button } from "@blueprintjs/core"
import { MenuItem2 } from "@blueprintjs/popover2"
import styled from "@emotion/styled"

// Chosing to use styled-components here instead of Tailwind classes so we can more consistently override Blueprint styles

export const SettingsMenuButton = styled(Button)<{ disabled?: boolean }>`
  padding: 0px 4px;
  border-radius: 4px;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? "transparent" : "#d8e1e8 !important"};
  }

  &:active {
    background-color: ${(props) =>
      props.disabled ? "transparent" : "#d8e1e8 !important"};
  }

  & span.bp4-icon {
    opacity: ${(props) => (props.disabled ? ".5" : "1")};
  }

  span {
    margin-right: 2px;
  }

  .bp4-icon svg {
    color: #5a829e;
  }

  &.bp4-active {
    background-color: #d8e1e8 !important;
  }
`

export const MarginsMenuItem = styled(MenuItem2)`
  &.bp4-active {
    color: #5a829e;
    background-color: #f5f8fa;
  }
`

export const QuickStylesMenuItem = styled(MenuItem2)`
  padding: 0 !important;
  &.bp4-active {
    background-color: transparent;
    color: inherit;

    &:hover {
      background-color: transparent;
      color: inherit;
    }
  }
  &:hover {
    background-color: transparent;
  }
`

export const FontMenuItem = styled(MenuItem2)`
  &.bp4-active {
    color: #5a829e;
    background-color: #f5f8fa;
  }
`
