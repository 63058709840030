import { IconPropsType } from "./IconPropTypes"

export default function AddFooterIcon({
  x = 0,
  y = 0,
  width = 16,
  height = 16,
  classNames = "",
}: IconPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      x={x}
      y={y}
    >
      <path
        d="M7.4 8L13.4 8C13.5591 8 13.7117 8.0903 13.8243 8.25105C13.9368 8.4118 14 8.62981 14 8.85714C14 9.08447 13.9368 9.30249 13.8243 9.46323C13.7117 9.62398 13.5591 9.71428 13.4 9.71428L7.4 9.71429C7.24087 9.71429 7.08826 9.62398 6.97574 9.46323C6.86321 9.30249 6.8 9.08447 6.8 8.85714C6.8 8.62981 6.86321 8.4118 6.97574 8.25105C7.08826 8.09031 7.24087 8 7.4 8ZM2.6 12.2857L13.4 12.2857C13.5591 12.2857 13.7117 12.376 13.8243 12.5368C13.9368 12.6975 14 12.9155 14 13.1429C14 13.3702 13.9368 13.5882 13.8243 13.7489C13.7117 13.9097 13.5591 14 13.4 14L2.6 14C2.44087 14 2.28826 13.9097 2.17574 13.7489C2.06321 13.5882 2 13.3702 2 13.1429C2 12.9155 2.06321 12.6975 2.17574 12.5368C2.28826 12.376 2.44087 12.2857 2.6 12.2857Z"
        fill="currentColor"
        className={classNames}
      />
    </svg>
  )
}
