import { Icon } from "@blueprintjs/core"
import {
  DragObjectWithType,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from "react-dnd"
import cx from "classnames"
import { ReactNode, useRef } from "react"
import {
  BlockStyles,
  BlockStylesFieldNames,
} from "models/InstaPageV2/Blocks/BlockStyles"
import FormattingMenu from "./FormattingMenu"
import { IPRenderedPage } from "models/InstaPageV2"
import TitleMenu from "./TitleMenu"
import SignatoryDescriptionMenu from "./SignatoryDescriptionMenu"
import { AdditionalFieldsNamesType } from "models/InstaPageV2/Blocks/SignatureBlock"
import AuthRepNameAlignmentMenu from "./AuthRepNameAlignmentMenu"

type FieldTileProps = {
  icon: ReactNode | null
  name: string
  isSelected: boolean
  onSelect: () => void
  required?: boolean
  showMenu?: boolean
  blockStyles?: BlockStyles[keyof BlockStyles]
  canDrag: boolean
  fieldKey: BlockStylesFieldNames | AdditionalFieldsNamesType | string
  pageId: IPRenderedPage["id"]
  handleUpdateBlockStyles: (
    newStyles: BlockStyles[keyof BlockStyles],
    fieldKey: keyof BlockStyles
  ) => void
  index?: number
  setDropIndex?: (index: number | null) => void
  onDrop?: () => void
}

export default function FieldTile({
  pageId,
  icon,
  name,
  isSelected,
  onSelect,
  required,
  showMenu = false,
  blockStyles,
  canDrag,
  fieldKey,
  handleUpdateBlockStyles,
  index,
  setDropIndex,
  onDrop = () => null,
}: FieldTileProps) {
  const cardRef = useRef<HTMLDivElement>(null)

  const [{ isDragging }, dragRef] = useDrag({
    item: {
      type: "TILE",
      name,
      key: fieldKey,
      index,
      path: [0],
    },
    canDrag: () => canDrag,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        setDropIndex?.(null)
      }
    },
  })

  function handleHover(
    item: DragObjectWithType & { index: number },
    monitor: DropTargetMonitor
  ) {
    if (!cardRef.current || !canDrag || !setDropIndex) {
      return
    }
    const dragIndex = item.index
    const hoverIndex = index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }
    // Determine rectangle on screen
    const hoverBoundingRect = cardRef.current?.getBoundingClientRect()
    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    // Determine mouse position
    const clientOffset = monitor.getClientOffset()
    // Get pixels to the top
    const hoverClientY = clientOffset && clientOffset.y - hoverBoundingRect.top
    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    if (index && index >= 0 && hoverClientY && hoverClientY > hoverMiddleY) {
      setDropIndex(index + 1)
    } else {
      setDropIndex(index ?? null)
    }
  }

  const [, dropRef] = useDrop({
    accept: ["TILE"],
    hover: handleHover,
    drop: onDrop,
  })

  dropRef(cardRef)

  const cardClassNames = cx(
    `flex justify-between items-center text-blue-6 p-3 rounded-md border leading-4 border-opacity-20
     hover:border-[#5A829E] hover:border-opacity-60 hover:border hover:cursor-default
     active:bg-white`,
    // active state makes sure the hover state doesn't stay on the index of the item you grabbed, even though it moved
    // i.e. you grab the first item and drop it at the end, but the _new_ first item still has the hover state
    {
      "!border-[#5A829E]": (required || isSelected) && !canDrag,
      "!border-purple-1 bg-[#FAF1FC] shadow-lg": isDragging,
      "hover:cursor-move hover:shadow-lg": canDrag,
      "hover:!border-[#A21CAF] hover:!border-opacity-40":
        !isSelected && canDrag,
      "!border-[#A21CAF]": isSelected && canDrag,
    }
  )

  const iconContainerClasses = cx("w-8 h-8 flex items-center justify-center")

  return (
    <div ref={cardRef} className="py-2">
      <div
        ref={dragRef}
        style={{
          opacity: isDragging ? 0.5 : 1,
        }}
        onClick={() => (required ? undefined : onSelect())}
        className="group"
        data-field-tile={fieldKey}
      >
        <div className={cardClassNames}>
          <div className={iconContainerClasses}>{icon}</div>

          <div className="flex-1 text-center flex justify-center items-center whitespace-pre-line">
            {name}
            {required ? "*" : ""}
          </div>
          <div className={iconContainerClasses}>
            {isSelected ? (
              <Icon
                icon="tick"
                size={16}
                className="p-2 hover:cursor-default"
              />
            ) : null}
          </div>
          <div className={iconContainerClasses}>
            {showMenu &&
              fieldKey !== BlockStylesFieldNames.TITLE &&
              fieldKey !== BlockStylesFieldNames.NAME &&
              fieldKey !== BlockStylesFieldNames.SIGNATORY_DESCRIPTION && (
                <FormattingMenu
                  blockStyles={blockStyles}
                  fieldKey={fieldKey as BlockStylesFieldNames}
                  handleUpdateBlockStyles={handleUpdateBlockStyles}
                />
              )}
            {fieldKey === BlockStylesFieldNames.NAME && (
              <AuthRepNameAlignmentMenu pageId={pageId} />
            )}
            {fieldKey === BlockStylesFieldNames.TITLE && (
              <TitleMenu
                handleUpdateBlockStyles={handleUpdateBlockStyles}
                blockStyles={blockStyles}
              />
            )}
            {fieldKey === BlockStylesFieldNames.SIGNATORY_DESCRIPTION && (
              <SignatoryDescriptionMenu
                handleUpdateBlockStyles={handleUpdateBlockStyles}
                blockStyles={blockStyles}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
