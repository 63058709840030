import { IPRenderedPage } from "models/InstaPageV2"
import useUpdateInstaPage from "../useUpdateInstaPage"
import { Block } from "models/InstaPageV2/Block"
import { BlockStyles } from "models/InstaPageV2/Blocks/BlockStyles"

export default function useUpdateBlockStyles(
  pageId: IPRenderedPage["id"],
  selectedBlockId: string | null,
  blocks: Block[]
) {
  let { handleUpdateInstaPage } = useUpdateInstaPage()

  const updateBlockStyles = (
    newStyles: BlockStyles[keyof BlockStyles],
    fieldKey: keyof BlockStyles
  ) => {
    handleUpdateInstaPage({
      id: pageId,
      blocks: blocks.map((block) =>
        block.id === selectedBlockId && block.kind === "signature"
          ? {
              ...block,
              blockStyles: {
                ...block.blockStyles,
                [fieldKey]: newStyles,
              },
            }
          : block
      ),
    })
  }
  return {
    updateBlockStyles,
  }
}
