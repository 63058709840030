import { SignatoryWithAuthRep } from "models/Signatory"
import { line } from "./InstapagePreview"
import { useAppConfig } from "app/config"

interface InstaPageAddressProps {
  signatory?: SignatoryWithAuthRep | null
}

export default function InstaPageAddress(props: InstaPageAddressProps) {
  const { config } = useAppConfig()
  const isUsingInternationalAddress = config?.SWITCHES?.INTERNATIONAL_ADDRESS

  const emptyLines = (
    <>
      <div className="mb-2">{line}</div>
      <div className="mb-2">{line}</div>
      <div className="mb-2">{line}</div>
      <div className="mb-2">{line}</div>
    </>
  )

  let signerAddress = props.signatory?.address
  return (
    <div>
      <div className="mb-2">Address:</div>
      {isUsingInternationalAddress ? (
        props.signatory?.internationalAddress ? (
          String(props.signatory?.internationalAddress)
            .split("\n")
            .map((addressLine, idx) => (
              <div className="mb-2" key={`address-${idx}`}>
                {addressLine}
              </div>
            ))
        ) : (
          emptyLines
        )
      ) : signerAddress?.lineOne ? (
        <>
          <div className="mb-2">{signerAddress.lineOne}</div>
          {signerAddress?.lineTwo && (
            <div className="mb-2">{signerAddress.lineTwo}</div>
          )}
          <div className="mb-2">
            {signerAddress?.city && (
              <span>{`${signerAddress.city}${signerAddress.state && ","} ${
                signerAddress.state
              }`}</span>
            )}
            {signerAddress.postalCode && (
              <span
                className={
                  signerAddress?.city || signerAddress?.state ? "ml-1" : ""
                }
              >{`${signerAddress.postalCode}`}</span>
            )}
          </div>
        </>
      ) : (
        emptyLines
      )}
    </div>
  )
}
