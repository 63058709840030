import { Button } from "@blueprintjs/core"
import { DateInput as BPDateInput } from "@blueprintjs/datetime"

function toLocalDate(dateStr = "") {
  const utcDate = new Date(dateStr).getTime()
  const currentOffset = new Date().getTimezoneOffset()
  const dateOffset = new Date(dateStr).getTimezoneOffset()

  const offset = currentOffset < dateOffset ? dateOffset : currentOffset

  return new Date(utcDate + offset * 1000 * 60)
}

function defaultFormattedDate(date) {
  const year = date.getFullYear()
  const month = (1 + date.getMonth()).toString().padStart(2, "0")
  const day = date.getDate().toString().padStart(2, "0")

  return month + "/" + day + "/" + year
}

type DateInputProps = {
  name: string
  value?: string
  onChange?: () => void
  formatDate?: () => void
  parseDate?: () => void
}

export default function DateInput({
  name = "",
  value,
  onChange = () => null,
  formatDate = defaultFormattedDate,
  parseDate = (d) => new Date(d),
}: DateInputProps) {
  const maxDate = new Date()
  maxDate.setFullYear(new Date().getFullYear() + 2)

  return (
    <BPDateInput
      highlightCurrentDay={false}
      name={name}
      value={value ? toLocalDate(value) : null}
      defaultValue={new Date()}
      onChange={(date) => {
        onChange(date ? date.toLocaleDateString("en-CA") : date)
      }}
      parseDate={parseDate}
      formatDate={formatDate}
      maxDate={maxDate}
      inputProps={{
        rightElement: value ? (
          <Button
            minimal
            icon="cross"
            onClick={(evt) => {
              evt.preventDefault()
              evt.stopPropagation()
              onChange("")
            }}
          />
        ) : null,
      }}
    />
  )
}
