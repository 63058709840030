import { QuickStylesMenuItem } from "../UI"
import { FontType } from "models/InstaPageV2"
import { QuickStyleOption } from "./QuickStyles"
import QuickStylesCard from "./QuickStylesCard"
import { Block } from "models/InstaPageV2/Block"
import useUpdateQuickStyles from "./useUpdateQuickStyles"

type QuickStylesSelectionProps = {
  option: QuickStyleOption
  pageId: string
  blocks: Block[]
  fontFamily: FontType
  isActive: boolean
}

export default function QuickStylesSelection({
  option,
  pageId,
  blocks,
  fontFamily,
  isActive,
}: QuickStylesSelectionProps) {
  let { updateQuickStyles } = useUpdateQuickStyles(pageId, blocks)

  return (
    <QuickStylesMenuItem
      key={option.name}
      active={isActive}
      text={
        <QuickStylesCard
          fontFamily={fontFamily}
          name={option.name}
          header={option.header}
          signatoryName={option.signatoryName}
          signatoryDescription={option.signatoryDescription}
          isActive={isActive}
        />
      }
      onClick={() => updateQuickStyles(option)}
    />
  )
}
