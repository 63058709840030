import { Popover2 } from "@blueprintjs/popover2"
import { Menu } from "@blueprintjs/core"
import { SettingsMenuButton as QuickStylesButton } from "../UI"
import { FontType } from "models/InstaPageV2"
import QuickStylesSelection from "./QuickStylesSelection"
import { Block } from "models/InstaPageV2/Block"
import { SignatureBlock } from "models/InstaPageV2/Blocks/SignatureBlock"
import { QuickStyleOption } from "./QuickStyles"
import { isQuickStyleActive } from "../helpers"
import { SignatoryDescriptionOptions } from "models/Signatory"
import { useTransactionSignatureBlockTemplates } from "features/transaction/id/api"
import { useTransactionIdParam } from "helpers/params"
import UpdateTransactionSignatureBlockTemplate from "./UpdateTransactionSignatureBlockTemplate"

const QUICK_STYLE_OPTIONS: QuickStyleOption[] = [
  {
    name: "Style 1",
    header: {
      fontSize: "12px",
      bold: true,
      italic: false,
      underline: false,
      textTransform: "uppercase",
      isEnabled: true,
    },
    signatoryName: {
      fontSize: "12px",
      bold: false,
      italic: false,
      underline: false,
      textTransform: "none",
      isEnabled: true,
    },
    signatoryDescription: {
      format: SignatoryDescriptionOptions.STYLE1,
      isEnabled: true,
    },
  },
  {
    name: "Style 2",
    header: {
      fontSize: "12px",
      bold: false,
      italic: false,
      underline: true,
      textTransform: "uppercase",
      isEnabled: true,
    },
    signatoryName: {
      fontSize: "12px",
      bold: true,
      italic: false,
      underline: false,
      textTransform: "uppercase",
      isEnabled: true,
    },
    signatoryDescription: {
      format: SignatoryDescriptionOptions.STYLE2,
      isEnabled: true,
    },
  },
  {
    name: "Style 3",
    header: {
      fontSize: "12px",
      bold: false,
      italic: false,
      underline: false,
      textTransform: "uppercase",
      isEnabled: true,
    },
    signatoryName: {
      fontSize: "12px",
      bold: false,
      italic: false,
      underline: false,
      textTransform: "uppercase",
      isEnabled: true,
    },
    signatoryDescription: {
      format: SignatoryDescriptionOptions.STYLE2,
      isEnabled: true,
    },
  },
  {
    name: "Style 4",
    header: {
      fontSize: "12px",
      bold: false,
      italic: false,
      underline: false,
      textTransform: "uppercase",
      isEnabled: true,
    },
    signatoryName: {
      fontSize: "12px",
      bold: false,
      italic: false,
      underline: false,
      textTransform: "uppercase",
      isEnabled: true,
    },
    signatoryDescription: {
      format: SignatoryDescriptionOptions.STYLE1,
      isEnabled: true,
    },
  },
]

export default function QuickStylesMenu({
  pageId,
  fontFamily,
  blocks,
}: {
  pageId: string
  fontFamily: FontType
  blocks: Block[]
}) {
  let transactionId = useTransactionIdParam()
  let { data: signatureBlockTemplates } =
    useTransactionSignatureBlockTemplates(transactionId)

  let signatureBlocks = blocks.filter(
    (block) => block.kind === "signature"
  ) as SignatureBlock[]

  let signatureBlockTemplate = signatureBlockTemplates?.[0]

  let ALL_OPTIONS = QUICK_STYLE_OPTIONS

  if (signatureBlockTemplate && signatureBlockTemplate.data) {
    ALL_OPTIONS = [
      {
        name: "Transaction Style",
        header: signatureBlockTemplate.data.blockStyles.header,
        signatoryName: signatureBlockTemplate.data.blockStyles.signatoryName,
        signatoryDescription:
          signatureBlockTemplate.data.blockStyles.signatoryDescription,
      },
      ...QUICK_STYLE_OPTIONS,
    ]
  }

  return (
    <Popover2
      minimal
      position="bottom-left"
      popoverClassName="relative top-1"
      content={
        <Menu
          large
          className="min-w-[300px] max-w-[325px] p-2 max-h-[400px] overflow-auto"
          data-menu="quick-styles-menu"
        >
          {signatureBlockTemplate && signatureBlocks[0] && (
            <>
              <UpdateTransactionSignatureBlockTemplate
                templateId={signatureBlockTemplate.uuid}
                transactionId={transactionId}
                signatureBlock={signatureBlocks[0]}
              />
              <div className="border-b border-gray-200 my-2" />
            </>
          )}
          {ALL_OPTIONS.map((option) => {
            let isActive = isQuickStyleActive(option, signatureBlocks)

            return (
              <QuickStylesSelection
                key={option.name}
                option={option}
                pageId={pageId}
                blocks={blocks}
                fontFamily={fontFamily}
                isActive={isActive}
              />
            )
          })}
        </Menu>
      }
    >
      <QuickStylesButton rightIcon="caret-down" text="Quick Styles" minimal />
    </Popover2>
  )
}
