import { MouseEvent, useState } from "react"
import { Button, Position } from "@blueprintjs/core"
import cx from "classnames"
import { Popover2, Tooltip2 } from "@blueprintjs/popover2"
import BoldIcon from "../Icons/BoldIcon"
import ItalicIcon from "../Icons/ItalicIcon"
import UnderlineIcon from "../Icons/UnderlineIcon"
import AllCapsIcon from "../Icons/AllCapsIcon"
import {
  BlockStyleTypes,
  BlockStyles,
  BlockStylesFieldNames,
} from "models/InstaPageV2/Blocks/BlockStyles"
import styled from "@emotion/styled"
import OnClickOutside from "components/util/OnClickOutside"

const StyledButton = styled("button")<{ isSelected?: boolean }>`
  background-color: ${(props) =>
    props.isSelected ? "#E1E8ED !important" : "#ffffff !important"};
  &:hover {
    background-color: #e1e8ed !important;
  }
  &:active {
    background-color: #d8e1e8 !important;
  }
`
export default function FormattingMenu({
  blockStyles,
  fieldKey,
  handleUpdateBlockStyles,
}: {
  fieldKey: BlockStylesFieldNames
  blockStyles?: BlockStyles[keyof BlockStyles]
  handleUpdateBlockStyles: (
    newStyles: BlockStyles[keyof BlockStyles],
    fieldKey: keyof BlockStyles
  ) => void
}) {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  const buttonClassNames = cx("bp4-button bp4-minimal !w-7 !h-7 p-1")

  const toggleFormattingOption = (
    e: MouseEvent<HTMLButtonElement>,
    key: keyof BlockStyleTypes
  ) => {
    // prevent the click event from bubbling up to the parent element, which toggles the selected state of the FieldTile
    e.stopPropagation()
    setPopoverIsOpen(false)

    if (blockStyles && typeof blockStyles === "object") {
      handleUpdateBlockStyles(
        {
          ...blockStyles,
          [key]:
            // if the key is textTransform, we need to check if it's uppercase and toggle it
            key === "textTransform"
              ? blockStyles &&
                "textTransform" in blockStyles &&
                blockStyles.textTransform === "uppercase"
                ? "none"
                : "uppercase"
              : // otherwise, we just toggle the boolean value for all other keys
                !blockStyles[key as keyof BlockStyles[keyof BlockStyles]],
        } as BlockStyles[keyof BlockStyles],
        fieldKey
      )
    }
  }

  return (
    <Popover2
      interactionKind="click"
      isOpen={popoverIsOpen}
      position={Position.BOTTOM}
      minimal
      className="invisible group-hover:visible"
      content={
        <OnClickOutside
          onClickOutside={() => {
            setPopoverIsOpen(false)
          }}
        >
          <div className="flex p-2 gap-2">
            <Tooltip2
              content="Bold"
              position={Position.TOP}
              hoverOpenDelay={500}
            >
              <StyledButton
                className={buttonClassNames}
                isSelected={Boolean(
                  // need to check for typeof object because some BlockStyleTypes come back as a string
                  // (TitleType and LineStyleType), and there is a String.bold() method which would return truthy
                  typeof blockStyles === "object" &&
                    (blockStyles as BlockStyleTypes)?.bold
                )}
                onClick={(e) => toggleFormattingOption(e, "bold")}
              >
                <BoldIcon classNames="text-blue-6" width={32} height={32} />
              </StyledButton>
            </Tooltip2>
            <Tooltip2
              content="Italics"
              position={Position.TOP}
              hoverOpenDelay={500}
            >
              <StyledButton
                className={buttonClassNames}
                isSelected={Boolean((blockStyles as BlockStyleTypes)?.italic)}
                onClick={(e) => toggleFormattingOption(e, "italic")}
              >
                <ItalicIcon classNames="text-blue-6" width={32} height={32} />
              </StyledButton>
            </Tooltip2>
            <Tooltip2
              content="Underline"
              position={Position.TOP}
              hoverOpenDelay={500}
            >
              <StyledButton
                className={buttonClassNames}
                isSelected={Boolean(
                  (blockStyles as BlockStyleTypes)?.underline
                )}
                onClick={(e) => toggleFormattingOption(e, "underline")}
              >
                <UnderlineIcon
                  classNames="text-blue-6"
                  width={32}
                  height={32}
                />
              </StyledButton>
            </Tooltip2>
            <Tooltip2
              content="All caps"
              position={Position.TOP}
              hoverOpenDelay={500}
            >
              <StyledButton
                className={buttonClassNames}
                isSelected={Boolean(
                  (blockStyles as BlockStyleTypes)?.textTransform &&
                    (blockStyles as BlockStyleTypes).textTransform !== "none"
                )}
                onClick={(e) => toggleFormattingOption(e, "textTransform")}
              >
                <AllCapsIcon classNames="text-blue-6" />
              </StyledButton>
            </Tooltip2>
          </div>
        </OnClickOutside>
      }
    >
      <Tooltip2
        content="Style options"
        position={Position.TOP}
        hoverOpenDelay={500}
      >
        <Button
          minimal
          icon="more"
          onClick={(e) => {
            e.stopPropagation()
            setPopoverIsOpen(!popoverIsOpen)
          }}
        />
      </Tooltip2>
    </Popover2>
  )
}
