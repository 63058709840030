import { IconPropsType } from "./IconPropTypes"

export default function ItalicIcon({
  x,
  y,
  width = 20,
  height = 20,
  classNames = "",
}: IconPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x={x}
      y={y}
      viewBox="0 0 20 20"
    >
      <path
        d="M6 15V13.7722H8.59289L10.7939 7.22784H8.20103V6H14.4187V7.22784H12.0348L9.83379 13.7722H12.2177V15H6Z"
        fill="currentColor"
        className={classNames}
      />
    </svg>
  )
}
