import { useDrop } from "react-dnd"
import FieldTile from "./FieldTile"
import { useIPEditorSelectedBlock } from "../editor-store"
import HeaderIcon from "../Icons/HeaderIcon"
import SignatoryNameIcon from "../Icons/SignatoryNameIcon"
import SignatureIcon from "../Icons/SignatureIcon"
import PersonIcon from "../Icons/PersonIcon"
import TitleIcon from "../Icons/TitleIcon"
import SigningDateIcon from "../Icons/SigningDateIcon"
import AddressIcon from "../Icons/AddressIcon"
import EmailIcon from "../Icons/Email"
import { IPRenderedPage } from "models/InstaPageV2"
import {
  SignatureBlock,
  AdditionalFieldsType,
  AdditionalFieldsNamesType,
} from "models/InstaPageV2/Blocks/SignatureBlock"
import {
  BlockStyles,
  BlockStylesFieldNames,
} from "models/InstaPageV2/Blocks/BlockStyles"
import useUpdateBlockStyles from "./useUpdateBlockStyles"
import QuickStylesMenu from "../QuickStyles/QuickStylesMenu"
import { FontType } from "models/InstaPageV2"
import useUpdateAdditionalFields from "./useUpdateAdditionalFields"
import { mergeOrAddItem, moveItemToPath } from "helpers/array"
import { Fragment, useRef, useState } from "react"
import { InsertBar } from "features/transaction/id/DashboardMain/ui"
import { TransactionCustomField } from "models/InstaPageV2/CustomFields/TransactionCustomField"

export default function FieldsFormatting({
  page,
  fontFamily,
  customFields = [],
}: {
  page: IPRenderedPage
  fontFamily: FontType
  customFields?: TransactionCustomField[]
}) {
  return (
    <Fields fontFamily={fontFamily} page={page} customFields={customFields} />
  )
}

const nonDraggableFieldTypes = [
  {
    name: "Header",
    key: BlockStylesFieldNames.HEADER,
    icon: <HeaderIcon />,
    required: false,
    showMenu: true,
  },
  {
    name: "Signatory Name",
    key: BlockStylesFieldNames.SIGNATORY_NAME,
    icon: <SignatoryNameIcon />,
    required: true,
    showMenu: true,
  },
  {
    name: "Signatory Description",
    key: BlockStylesFieldNames.SIGNATORY_DESCRIPTION,
    icon: (
      <span className="rotate-180">
        <SignatoryNameIcon />
      </span>
    ),
    required: false,
    showMenu: true,
  },
  {
    name: "Signature Line",
    key: BlockStylesFieldNames.LINE_STYLES,
    icon: <SignatureIcon />,
    required: true,
    showMenu: false,
  },
  {
    name: "Authorized \n Representative",
    key: BlockStylesFieldNames.NAME,
    icon: <PersonIcon />,
    required: true,
    showMenu: true,
  },
  {
    name: "Title",
    key: BlockStylesFieldNames.TITLE,
    icon: <TitleIcon />,
    required: true,
    showMenu: true,
  },
]
export const AdditionalFieldsItems = [
  {
    name: "Signing Date",
    key: AdditionalFieldsNamesType.SigningDate,
    icon: <SigningDateIcon />,
    required: false,
    showMenu: false,
  },
  {
    name: "Address",
    key: AdditionalFieldsNamesType.Address,
    icon: <AddressIcon />,
    required: false,
    showMenu: false,
  },
  {
    name: "Email",
    key: AdditionalFieldsNamesType.Email,
    icon: <EmailIcon />,
    required: false,
    showMenu: false,
  },
]

function Fields({
  fontFamily,
  page,
  customFields,
}: {
  fontFamily: FontType
  page: IPRenderedPage
  customFields: TransactionCustomField[]
}) {
  const cardRef = useRef(null)
  const { selectedBlockId } = useIPEditorSelectedBlock()

  const selectedBlock = page.blocks.find((b) => b.id === selectedBlockId)

  let { updateBlockStyles } = useUpdateBlockStyles(
    page.id,
    selectedBlockId,
    page.blocks
  )
  let { updateAdditionalFields } = useUpdateAdditionalFields(
    page.id,
    selectedBlockId,
    page.blocks
  )

  const [dropIndex, setDropIndex] = useState<number | null>(null)

  let blocks = page.blocks || []

  const selectedSignatureBlock = blocks.find(
    (b) => b.id === selectedBlock?.id && b.kind === "signature"
  ) as SignatureBlock

  function toggleFieldSelection(fieldKey: keyof BlockStyles) {
    const values = selectedSignatureBlock?.blockStyles[fieldKey]

    if (typeof values === "object" && "isEnabled" in values) {
      updateBlockStyles(
        {
          ...values,
          isEnabled: !values.isEnabled,
        },
        fieldKey
      )
    }
  }

  function toggleAdditionalFieldSelection(
    fieldKey: AdditionalFieldsType["key"]
  ) {
    const matchingField = selectedSignatureBlock?.additionalFields.find(
      (f) => f.key === fieldKey
    )
    const fieldIsEnabled = matchingField?.isEnabled ?? false

    let newAdditionalFields = []

    if (matchingField) {
      newAdditionalFields = mergeOrAddItem(
        [...(selectedSignatureBlock?.additionalFields || [])],
        { ...matchingField, isEnabled: !fieldIsEnabled },
        "key"
      )
      updateAdditionalFields(newAdditionalFields)
    }
  }

  let draggableFieldsWithCustomFields = [
    ...AdditionalFieldsItems,
    ...customFields
      .map((field) => {
        if (field) {
          return {
            name: field.name,
            key: field.name,
            icon: null,
            required: false,
            showMenu: false,
            id: field.id,
          }
        }
      })
      .filter((f) => f),
  ]

  const mappedAdditionalFields =
    selectedSignatureBlock?.additionalFields.map((f, index) => ({
      ...f,
      path: [index],
    })) || []

  const draggableLength = draggableFieldsWithCustomFields.length

  function handleDrop(tile: {
    type: string
    name: string
    key: string
    index: number
    path: number[]
  }) {
    const dropPath =
      dropIndex === null
        ? [draggableFieldsWithCustomFields.length]
        : [dropIndex]

    let data = [...mappedAdditionalFields]
    const matchingField = data.find((f) => f.key === tile.key)

    let result =
      matchingField &&
      (moveItemToPath<AdditionalFieldsType>(
        data,
        dropPath,
        matchingField,
        ""
      )[0] as AdditionalFieldsType[])

    if (result) {
      updateAdditionalFields(result)
    }

    setDropIndex(null)
  }

  const [, dropRef] = useDrop({
    accept: ["TILE"],
    drop: handleDrop,
    collect: (monitor) => ({ isOver: monitor.isOver() }),
    canDrop: () => true,
  })
  dropRef(cardRef)

  let sortedDraggableFields = draggableFieldsWithCustomFields

  const keyIndexMap = mappedAdditionalFields.reduce<Record<string, number>>(
    (map, obj, index) => {
      map[obj.key] = index
      return map
    },
    {}
  )

  // Sort nameArray based on the order of keys in keyArray
  sortedDraggableFields.sort((a, b) => {
    if (!a || !b) return 0

    const indexA = keyIndexMap[a.key]
    const indexB = keyIndexMap[b.key]

    // Compare indices to determine the order
    return Number(indexA) - Number(indexB)
  })

  return (
    // 345px (total width of sidebar) - 24px (aka p-3 padding)>
    <div className="mb-3 h-full flex flex-col w-[321px]">
      <QuickStylesMenu
        pageId={page.id}
        fontFamily={fontFamily}
        blocks={blocks}
      />
      <div className="mb-4 px-px flex-col overflow-auto flex-grow border-t border-slate-300 pb-8">
        {nonDraggableFieldTypes.map((field) => {
          const blockStyles =
            selectedSignatureBlock?.blockStyles[field.key as keyof BlockStyles]
          const isSelected =
            typeof blockStyles === "object" && "isEnabled" in blockStyles
              ? blockStyles.isEnabled
              : field.required
          return (
            <FieldTile
              key={field.key}
              fieldKey={field.key}
              pageId={page.id}
              icon={field.icon}
              name={field.name}
              isSelected={isSelected}
              onSelect={() => toggleFieldSelection(field.key)}
              required={field.required}
              showMenu={field.showMenu}
              blockStyles={blockStyles}
              canDrag={false}
              handleUpdateBlockStyles={updateBlockStyles}
            />
          )
        })}
        <hr className="border-0 border-b my-4 mx-0" />
        <div ref={cardRef}>
          {sortedDraggableFields.map((field, index) => {
            if (!field) return null
            const matchingField = mappedAdditionalFields.find(
              (f) => f.key === field.key
            )

            const isSelected = matchingField?.isEnabled ?? false

            return (
              <Fragment key={field.name}>
                <div className="relative">
                  {index === dropIndex && <InsertBar />}
                  <FieldTile
                    fieldKey={field.key}
                    pageId={page.id}
                    icon={field.icon}
                    name={field.name}
                    isSelected={isSelected}
                    onSelect={() => toggleAdditionalFieldSelection(field.key)}
                    required={field.required}
                    showMenu={field.showMenu}
                    blockStyles={undefined}
                    canDrag={true}
                    handleUpdateBlockStyles={updateBlockStyles}
                    index={index}
                    setDropIndex={setDropIndex}
                  />
                </div>
              </Fragment>
            )
          })}
          <div className="relative py-4">
            {dropIndex !== null && draggableLength === dropIndex && (
              <InsertBar />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
