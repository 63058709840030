import { IconPropsType } from "./IconPropTypes"

export default function TitleIcon({
  x,
  y,
  width = 16,
  height = 16,
  classNames = "",
}: IconPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x={x}
      y={y}
      viewBox="0 0 16 16"
    >
      <path
        d="M1.95312 0V4.3625H2.68125C2.68125 3.63438 2.68125 2.18125 3.40937 1.45313C4.13438 0.725 4.8625 0.725 5.59062 0.725H7.04375V13.0875C7.04375 14.1781 6.68125 15.2688 5.59062 15.2688H4.87813L4.8625 15.9938H12.1344V15.2656H11.4062C10.3156 15.2656 9.95312 14.175 9.95312 13.0844V0.728125H11.4062C12.1344 0.728125 12.8594 0.728125 13.5875 1.45625C14.3156 2.18438 14.3156 3.6375 14.3156 4.36563H15.0437V0H1.95312Z"
        fill="currentColor"
        className={classNames}
      />
    </svg>
  )
}
