import * as React from "react"

import styled from "@emotion/styled"
import { Colors } from "@blueprintjs/core"

import { useDragToScroll } from "helpers/hooks"
import { heightToWidth, widthToHeight } from "helpers/pages/dimensions"
import { PacketSignatoryAssignment } from "models/PacketAssignment"
import { SignatoryWithAuthRep } from "models/Signatory"
import InstaPageAddress from "./InstaPageAddress"

function nestedEntityAuthReps(authReps: SignatoryWithAuthRep[] = []) {
  let entities: SignatoryWithAuthRep[] = []
  if (authReps && authReps.length > 0) {
    authReps.forEach((authRep) => {
      if (authRep.isOrg && authRep.authReps && authRep.authReps.length > 0) {
        entities.push(authRep)
        if (authRep.authReps && authRep.authReps.length > 0) {
          entities = [...entities, ...nestedEntityAuthReps(authRep.authReps)]
        }
      }
    })
  }

  return entities
}

const PIXELS_TO_INCH = 96.0

export const line = (
  <div
    style={{
      content: "",
      display: "inline-block",
      width: "100%",
      height: "1px",
      borderBottom: "1px solid",
      marginLeft: "3px",
    }}
  />
)

const Container = styled.div<{
  height?: number
  width?: number
  status?: string
  isScollable?: boolean
}>`
  border: 1px solid #738694;
  border-radius: 2px;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  position: relative;
  z-index: 1;
  ${(props) =>
    props.isScollable
      ? `overflow: scroll; cursor: grabbing;`
      : `
  overflow: hidden;
  `}
  ${(props) =>
    props.status === "ACCEPTED" && `border: 1px solid ${Colors.FOREST4};`}
  ${(props) =>
    props.status === "AWAITING_APPROVAL" &&
    `border: 1px solid ${Colors.GOLD4};`}
  ${(props) => props.status === "SENT" && `border: 1px solid $#669EFF;`}
`

const Preview = styled.div<{ scale: number }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14.5px;
  padding: 96px 72px 42px 72px;
  font-family: "Times New Roman";
  line-height: 1;
  height: ${PIXELS_TO_INCH * 11}px;
  width: ${PIXELS_TO_INCH * 8.5}px;
  transform: scale(${(props) => props.scale});
  transform-origin: top left;
`

type ESignInstaPageProps = {
  address?: boolean
  assignment?: PacketSignatoryAssignment | null
  className?: string
  date?: boolean
  email?: boolean
  footer?: string
  leadIn?: string
  pageHeight?: number
  height?: number
  width?: number
  status?: string
  witness?: boolean
  zoomScale?: number
}

const ESignInstaPage = React.forwardRef<HTMLDivElement, ESignInstaPageProps>(
  function (
    {
      address,
      assignment,
      className = "",
      date,
      email,
      footer,
      leadIn,
      pageHeight,
      height,
      status,
      witness,
      width,
      zoomScale = 1,
    },
    ref
  ) {
    const isZooming = zoomScale !== 1
    let { draggableContainerRef } = useDragToScroll({
      enabled: isZooming,
    })

    const prefferedHeight = height || pageHeight

    const containerWidth =
      !width && prefferedHeight ? heightToWidth(prefferedHeight) : width
    const containerHeight =
      !height && width ? widthToHeight(width) : prefferedHeight

    const scaledHeight = containerHeight
      ? (containerHeight / (PIXELS_TO_INCH * 11.0)) * zoomScale
      : 1

    const authRep = assignment?.authRep

    const authRepTitle =
      (assignment?.signatory &&
        assignment?.signatory.representatives.find(
          (r) => r.signatoryId === authRep?.id
        )?.title) ||
      ""

    let childEntities = nestedEntityAuthReps(assignment?.signatory?.authReps)

    return (
      <Container
        height={containerHeight}
        width={containerWidth}
        status={status}
        isScollable={isZooming}
        ref={ref || draggableContainerRef}
        className={className}
      >
        <Preview scale={scaledHeight}>
          {leadIn && <div className="mb-12">{leadIn}</div>}
          <div className="grid h-full grid-cols-[1fr,50%] gap-14 py-4">
            <div className="h-full">
              {witness && (
                <div className="pt-8">
                  <div className="mb-3 font-bold" />
                  {line}
                  <div className="pt-1">Witness</div>
                </div>
              )}
            </div>
            <div className="h-full">
              {assignment?.header && (
                <div className="mb-10 uppercase">{assignment.header}</div>
              )}
              <div className="mb-7 font-bold">
                {assignment?.signatory?.name || "Name"}
              </div>
              {assignment?.signatory && assignment?.signatory.isOrg && (
                <>
                  {!authRep ? (
                    <div className="mb-7">
                      <div className="mb-1 flex items-end">By: {line}</div>
                      <div className="mb-1 flex items-end">Name: {line}</div>
                      <div className="mb-1 flex items-end">Title: {line}</div>
                    </div>
                  ) : (
                    <div key={authRep.id} className="mb-7">
                      {childEntities.map((childEntity) => (
                        <div key={childEntity.id} className="mb-7">
                          <div className="mb-1 flex items-end">
                            By: {childEntity.name}
                          </div>
                          <div className="mb-1 flex items-end">
                            Title: {childEntity.title || line}
                          </div>
                        </div>
                      ))}
                      <div
                        className={
                          childEntities.length > 0 && !authRep.isOrg
                            ? "ml-6"
                            : ""
                        }
                      >
                        <div className="mb-7">
                          {authRep.isOrg ? (
                            <div className="mb-1 flex items-end">
                              By: {authRep.name}
                            </div>
                          ) : (
                            <>
                              <div className="mb-1 flex items-end">
                                By: {line}
                              </div>
                              <div className="mb-1 flex items-end">
                                Name: {authRep.name}
                              </div>
                            </>
                          )}
                          <div className="flex items-end">
                            Title: {authRepTitle || line}
                          </div>
                        </div>
                        {authRep.isOrg && (
                          <div className="mb-7 ml-6">
                            <div className="mb-1 flex items-end">
                              By: {line}
                            </div>
                            <div className="mb-1 flex items-end">
                              Name: {line}
                            </div>
                            <div className="mb-1 flex items-end">
                              Title: {line}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}

              {assignment?.signatory && !assignment?.signatory.isOrg && (
                <div className="mb-5">{line}</div>
              )}
              <div
                className={
                  childEntities.length > 0 || authRep?.isOrg ? "ml-6" : ""
                }
              >
                {email && (
                  <div className="mb-5 flex items-end">
                    Email:{" "}
                    {assignment?.signatory?.email || authRep?.email || line}
                  </div>
                )}
                {date && (
                  <div className="mb-6 flex items-end">Date: {line}</div>
                )}
                {address && (
                  <InstaPageAddress
                    signatory={authRep || assignment?.signatory}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mt-auto text-center">{footer}</div>
        </Preview>
      </Container>
    )
  }
)

ESignInstaPage.displayName = "ESignInstaPage"

export default ESignInstaPage
