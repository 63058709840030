import { IconPropsType } from "./IconPropTypes"

export default function PersonIcon({
  x,
  y,
  width = 16,
  height = 16,
  classNames = "",
}: IconPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x={x}
      y={y}
    >
      <g clipPath="url(#clip0_4503_17288)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6801 14.3202C15.2201 13.2702 13.0001 12.5702 11.5201 11.9202C10.0401 11.2702 10.2401 10.8702 10.1901 10.3302C10.1801 10.2602 10.1801 10.1802 10.1801 10.1002C10.6901 9.65024 11.1001 9.03023 11.3701 8.32023C11.3701 8.32023 11.3801 8.28024 11.3901 8.27024C11.4501 8.12024 11.5001 7.95023 11.5401 7.79023C11.8801 7.72023 12.0801 7.35023 12.1501 7.01023C12.2301 6.87023 12.3801 6.53023 12.3501 6.14023C12.3001 5.64023 12.1001 5.41023 11.8801 5.32023V5.23023C11.8801 4.60023 11.8201 3.68023 11.7101 3.08023C11.5686 2.14371 11.0705 1.29813 10.3201 0.720234C9.6801 0.250234 8.7901 -0.00976562 8.0001 -0.00976562C7.2101 -0.00976562 6.3201 0.240234 5.6901 0.720234C5.0801 1.19023 4.6301 1.85023 4.4101 2.58023C4.3601 2.75023 4.3201 2.91023 4.3001 3.08023C4.1801 3.68023 4.1301 4.59023 4.1301 5.23023V5.31023C3.8901 5.40023 3.6801 5.63023 3.6301 6.14023C3.6001 6.52023 3.7601 6.86023 3.8301 7.00023C3.9101 7.35023 4.1101 7.72023 4.4601 7.78023C4.5001 7.95023 4.5501 8.11024 4.6101 8.27024C4.6101 8.28024 4.6201 8.29023 4.6201 8.30023L4.6301 8.31024C4.9001 9.03024 5.3301 9.66023 5.8501 10.1102C5.8501 10.1802 5.8401 10.2502 5.8401 10.3202C5.7901 10.8602 5.9401 11.2602 4.4701 11.9102C2.9901 12.5602 0.770104 13.2602 0.310104 14.3102C-0.149896 15.3602 0.0401039 15.9802 0.0401039 15.9802H15.9601C15.9501 15.9902 16.1401 15.3702 15.6801 14.3202Z"
          fill="currentColor"
          className={classNames}
        />
      </g>
      <defs>
        <clipPath id="clip0_4503_17288">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
