import { type Block, type BlockGrid } from "models/InstaPageV2/Block"
import { type Container } from "./lines-to-containers"
import { getGridPosition } from "components/item-detail/InstapageV2/helpers"

type InsertBlock = {
  blockInsertIdx: number
  lineIdx: number
  numLines: number
  grid: BlockGrid
}

export default function containersToInsertBlocks(
  containers: Container[],
  blocks: Block[] = []
) {
  let insertBlocks: InsertBlock[] = []
  let hasLeadIn = blocks.some((b) => b.kind === "lead_in")
  let hasFooter = blocks.some((b) => b.kind === "footer")

  containers.map((container, idx) => {
    let block = blocks.find((b) => b.id === container.blockId)
    if (!block) {
      return
    }

    let blockGrid = getGridPosition(block.grid)
    let gridValue: BlockGrid =
      blockGrid === "right" ? [1, 1] : blockGrid === "left" ? [2, 2] : [1, 2]

    let isTwoColumnBlock =
      (blockGrid === "left" && blocks[idx + 1]?.grid[0] === 2) ||
      (blockGrid === "right" &&
        blocks[idx - 1]?.grid[1] === 1 &&
        blocks[idx + 1]?.grid[0] === 1)

    if (block.kind && block.kind !== "footer") {
      let isSignatureBlock = block.kind === "signature"
      let isRightGridBlock = blockGrid === "right"
      let isLeftGridBlock = blockGrid === "left"
      let isBlockAfterRightGridBlock = blocks[idx + 1]?.grid[0] === 2
      let isBlockAfter =
        Boolean(blocks[idx + 1]) && blocks[idx + 1]?.kind !== "footer"
      let isBlockAfterSignatureBlock = blocks[idx + 1]?.kind === "signature"
      let isBlockBeforeLeftGridBlock = blocks[idx - 1]?.grid[1] === 1
      let isBlockFullGridBlock = blockGrid === "full"
      let blockBeforeNumLines = containers[idx - 1]?.numLines || 0

      let totalNumLines =
        isBlockBeforeLeftGridBlock && blockBeforeNumLines > container.numLines
          ? blockBeforeNumLines
          : container.numLines

      if (
        isBlockAfterRightGridBlock &&
        isBlockAfterSignatureBlock &&
        isTwoColumnBlock
      ) {
        return
      }

      // If there is a right grid signature block, we need to have a left column insert block before it
      if (isSignatureBlock && isRightGridBlock && !isBlockBeforeLeftGridBlock) {
        insertBlocks.push({
          blockInsertIdx: idx,
          lineIdx:
            !hasLeadIn && idx === 0
              ? container.lineStartIdx + 2
              : container.lineStartIdx,
          numLines: container.numLines,
          grid: gridValue,
        })
      }
      // If there is a left grid signature block, and there is no left align block after, we need to have a left column insert block after it
      if (isSignatureBlock && isLeftGridBlock && !isBlockAfter) {
        insertBlocks.push({
          blockInsertIdx: idx + 1,
          lineIdx:
            !hasLeadIn && idx === 0
              ? container.lineStartIdx + 2 + totalNumLines + 1
              : container.lineStartIdx + totalNumLines + 1,
          numLines: container.numLines,
          grid: [1, 2],
        })
      }

      // If there is a left grid block with a full grid block after it, we need to add an insert block after the text block
      if (
        isSignatureBlock &&
        isLeftGridBlock &&
        isBlockAfter &&
        !isTwoColumnBlock
      ) {
        insertBlocks.push({
          blockInsertIdx: idx + 1,
          lineIdx: container.lineStartIdx,
          numLines: totalNumLines,
          grid: gridValue,
        })
      }

      // if there is a left grid block with a block after it, we need to add an insert block after the text block
      if (
        isSignatureBlock &&
        isLeftGridBlock &&
        isBlockAfter &&
        isTwoColumnBlock
      ) {
        return
      }

      let lineIdx
      if (!hasLeadIn && idx === 0 && isRightGridBlock) {
        lineIdx = container.lineStartIdx + totalNumLines
      } else if (!hasLeadIn && isRightGridBlock) {
        lineIdx = container.lineStartIdx + totalNumLines + 2
      } else if (!isBlockAfter && isLeftGridBlock) {
        lineIdx = container.lineStartIdx
      } else if (blocks.length === 1 && block.kind !== "lead_in") {
        lineIdx = container.lineStartIdx + totalNumLines + 2
      } else if (!hasLeadIn && idx === 0) {
        lineIdx = container.lineStartIdx + totalNumLines + 2
      } else if (isBlockBeforeLeftGridBlock && isBlockFullGridBlock) {
        lineIdx = container.lineStartIdx + container.numLines + 1
      } else {
        lineIdx = container.lineStartIdx + totalNumLines + 1
      }

      // We need to add an insert block after every text block and right column block
      insertBlocks.push({
        blockInsertIdx: idx + 1,
        lineIdx: lineIdx,
        numLines: totalNumLines,
        grid: !isBlockAfter && isLeftGridBlock ? gridValue : [1, 2],
      })
      return
    }
  })
  // if there is no lead in block, we need to show an insert block above the margin line
  if (!hasLeadIn && blocks.length > 0) {
    insertBlocks.push({
      blockInsertIdx: 0,
      lineIdx: hasFooter && blocks.length === 1 ? 0 : -2,
      numLines: 0,
      grid: [1, 2],
    })
  }

  // if there are no blocks, we need to show an insert block above the margin line
  if (blocks.length === 0) {
    insertBlocks.push({
      blockInsertIdx: 0,
      lineIdx: 0,
      numLines: 0,
      grid: [1, 2],
    })
  }

  return insertBlocks
}
