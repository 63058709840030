import cx from "classnames"
import { FONT_NAMES } from "../helpers"
import { FontType } from "models/InstaPageV2"
import {
  BlockStyleTypes,
  SignatoryDescriptionType,
} from "models/InstaPageV2/Blocks/BlockStyles"
import { SignatoryDescriptionOptions } from "models/Signatory"

export default function QuickStylesCard({
  fontFamily,
  name,
  header,
  signatoryName,
  signatoryDescription,
  isActive,
}: {
  fontFamily: FontType
  name: string
  header: BlockStyleTypes
  signatoryName: BlockStyleTypes
  signatoryDescription: SignatoryDescriptionType
  isActive: boolean
}) {
  const cardClassNames = cx(
    "flex flex-col border px-2 py-2 rounded-[3px] mx-1.5 my-2",
    {
      "border-blue-6 ring ring-blue-6/50": isActive,
      "hover:border-blue-6 hover:shadow border-gray-sa-2": !isActive,
    }
  )
  const headerClassNames = cx({
    "font-bold": header.bold,
    italic: header.italic,
    underline: header.underline,
    uppercase: header.textTransform === "uppercase",
  })
  const signatoryNameClassNames = cx({
    "font-bold": signatoryName.bold,
    italic: signatoryName.italic,
    underline: signatoryName.underline,
    uppercase: signatoryName.textTransform === "uppercase",
  })

  return (
    <div
      style={{
        fontFamily: FONT_NAMES[fontFamily],
        fontSize: "14px",
      }}
      className={cardClassNames}
    >
      <div className="flex items-center justify-between">
        {header.isEnabled && <div className={headerClassNames}>Header</div>}
        <div className="text-blue-6/50 font-['Open_Sans'] ml-auto">{name}</div>
      </div>
      <div className="mt-4 leading-4">
        <span className={signatoryNameClassNames}>
          Signatory name
          {signatoryDescription.isEnabled && <span>, </span>}
        </span>
        {signatoryDescription.isEnabled &&
          signatoryDescription.format ===
            SignatoryDescriptionOptions.STYLE2 && <br />}
        {signatoryDescription.isEnabled && <span>description</span>}
      </div>
      <div className="mt-5">
        <div className="flex justify-between">
          <span className="flex-1 mr-1">By:</span>
          <span className="-translate-y-px">
            ________________________________
          </span>
        </div>
        <div className="flex justify-between">
          <span className="flex-1 mr-1">Name:</span>
          <span className="-translate-y-px">
            ________________________________
          </span>
        </div>
        <div className="flex justify-between">
          <span className="flex-1 mr-1">Title:</span>
          <span className="-translate-y-px">
            ________________________________
          </span>
        </div>
      </div>
    </div>
  )
}
