import styled from "@emotion/styled"
import {
  ChangeEvent,
  forwardRef,
  ReactNode,
  Ref,
  Dispatch,
  SetStateAction,
} from "react"

import {
  Button,
  Checkbox,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  Menu,
  Position,
  Tag,
  TextArea,
} from "@blueprintjs/core"
import { Popover2, MenuItem2 as MenuItem } from "@blueprintjs/popover2"

export { default as DateInput } from "./inputs/DateInput"

const Group = styled(FormGroup)`
  .bp4-form-helper-text::first-letter {
    text-transform: uppercase;
  }
`

// TODO-TS: this was missing after conversion, verify it is correct
type InputProps = {
  errors?: Record<string, any>
  touched?: Record<string, any>
  id?: string
  name?: string
  inputRef?: Ref<any>
  isSample?: boolean
  props?: Record<string, any>
  large?: boolean
  fill?: boolean
  type?: string
  value?: string
  defaultValue?: string
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (evt: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (evt: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  placeholder?: string
}

export const Input = forwardRef(
  (
    { errors = {}, touched = {}, id, inputRef, isSample, ...props }: InputProps,
    ref
  ) => (
    <InputGroup
      {...props}
      ref={ref}
      inputRef={inputRef}
      id={id}
      intent={errors[id] && touched[id] ? Intent.DANGER : Intent.NONE}
      rightElement={isSample && <Tag minimal>TEST</Tag>}
    />
  )
)

type TextProps = {
  errors?: Record<string, any>
  touched?: Record<string, any>
  id?: string
  props?: Record<string, any>
  fill?: boolean
  type?: string
  value?: string
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (evt: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

export const Text = forwardRef(
  ({ errors, touched, id, ...props }: TextProps, ref) => (
    <TextArea
      {...props}
      ref={ref}
      id={id}
      intent={errors[id] && touched[id] ? Intent.DANGER : Intent.NONE}
    />
  )
)

type InputBlockProps = {
  errors?: Record<string, any>
  touched?: Record<string, any>
  forId?: string
  label?: ReactNode
  children?: ReactNode
  inverted?: boolean
  passwordBottomMargin?: boolean
  addRequiredAsterisk?: boolean
  showErrorMessage?: boolean
  hasRedHelperText?: boolean
  disabled?: boolean
}

export const InputBlock = forwardRef(
  (
    {
      errors = {},
      touched,
      forId,
      label,
      children,
      inverted = false,
      passwordBottomMargin = false,
      showErrorMessage = true,
      hasRedHelperText = false,
      disabled = false,
    }: InputBlockProps,
    ref
  ) => (
    <Group
      className={passwordBottomMargin ? "mb-0" : undefined}
      helperText={
        showErrorMessage && forId ? (
          errors[forId] && touched?.[forId] ? (
            inverted ? (
              <span
                className={`${!hasRedHelperText && "color-white"} inline-flex`}
              >
                <span className="mr-1 inline-flex h-4 rounded-full bg-white">
                  <Icon icon="error" intent={Intent.DANGER} />
                </span>
                {errors[forId]}
              </span>
            ) : (
              errors[forId]
            )
          ) : (
            ""
          )
        ) : null
      }
      intent={
        forId && errors[forId] && touched?.[forId] ? Intent.DANGER : Intent.NONE
      }
      inverted={inverted}
      labelFor={forId}
      label={label}
      ref={ref}
      disabled={disabled}
    >
      {children}
    </Group>
  )
)

type FormCheckBoxProps = {
  onChange?: (value: boolean) => void
  value?: boolean
  label?: string
}

export function FormCheckBox({ ...props }: FormCheckBoxProps) {
  function handleChange(evt) {
    props.onChange(evt.target.checked)
  }
  return <Checkbox {...props} onChange={handleChange} checked={props.value} />
}

type SearchInputProps = {
  onChange?: Dispatch<SetStateAction<string>>
  onClear?: () => void
  value?: string
  isDisabled?: boolean
  className?: string
  placeholder?: string
}

export function SearchInput({
  onChange = () => null,
  onClear = () => null,
  isDisabled = false,
  value = "",
  ...props
}: SearchInputProps) {
  return (
    <InputGroup
      autoComplete="off"
      name="search"
      round
      fill
      className="search"
      placeholder=""
      leftIcon="search"
      disabled={isDisabled}
      value={value}
      onChange={(evt) => onChange(evt.target.value)}
      rightElement={
        value ? <Button minimal icon="cross" onClick={onClear} /> : undefined
      }
      {...props}
    />
  )
}

type InputSelectProps = {
  className?: string
  leftIcon?: ReactNode
  menuItems?: any[]
  onChange?: () => void
  placeholder?: string
  value?: string
}

export function InputSelect({
  className = "",
  onChange = () => null,
  placeholder = "",
  leftIcon,
  menuItems = [],
  value = null,
}: InputSelectProps) {
  let selectedMenuItem = menuItems.find((menu) => menu.value === value)
  return (
    <Popover2
      content={
        <Menu>
          {menuItems.map((menu) => (
            <MenuItem
              active={selectedMenuItem?.value === menu.value}
              key={menu.value}
              icon={menu.icon}
              onClick={() => onChange(menu.value)}
              text={menu.text}
            />
          ))}
        </Menu>
      }
      position={Position.BOTTOM_RIGHT}
      minimal
    >
      <Input
        className={className}
        placeholder={placeholder}
        leftIcon={selectedMenuItem?.icon || leftIcon}
        value={selectedMenuItem?.valueText}
        readOnly
      />
    </Popover2>
  )
}
