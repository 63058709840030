/*
| --------------------------------------------------------------------------
| Colors
| --------------------------------------------------------------------------
|
| We need to define any colors we will need in the InstaPageV2 SVG markup
| here because css / classnames are not support when rendering the SVG
| on the server. These will (hopefully) match colors from TW config.
|
*/

const Colors = {
  yellow: {
    "100": "#FCF1D8",
    "400": "#FFD600",
    "700": "#D1980C",
  },
}

export default Colors
