import type {
  Node,
  _Text,
} from "features/insta-pages2/renderer/blocks-to-lines"
import { getTextAnchorFromAlign } from "./helpers"

type TSpanNode = {
  id: Node["id"]
  text: string
  marks?: _Text["marks"]
  textAlign?: _Text["textAlign"]
}

type TSpanRendererProps = {
  x?: number
  textNode: TSpanNode
  dx?: number
}

const TSPAN_STYLE = {
  whiteSpace: "pre",
}

export default function TSPanRenderer({ textNode, x, dx }: TSpanRendererProps) {
  let isBold = textNode.marks?.some((mark) => mark.type === "bold")
  let isItalic = textNode.marks?.some((mark) => mark.type === "italic")
  let isUnderlined = textNode.marks?.some((mark) => mark.type === "underline")
  let isUppercased = textNode.marks?.some((mark) => mark.type === "uppercase")

  let textAnchor = getTextAnchorFromAlign(textNode.textAlign)

  let localFontSize = textNode.marks?.find((m) => m.type === "textStyle")?.attrs
    ?.fontSize

  let spanText = textNode.text || ""

  if (isUppercased) {
    // Originally for uppercase we had this on the style={{}} prop ->
    //     textTransform: isUppercased ? "uppercase" : "none",
    // However, the textTransform was not working in the SVG -> PDF conversion
    // on the API server so forcing to uppercase characters here
    spanText = spanText.toUpperCase()
  }

  return (
    <tspan
      key={textNode.id}
      x={x}
      fontWeight={isBold ? "bold" : undefined}
      fontStyle={isItalic ? "italic" : undefined}
      textDecoration={isUnderlined ? "underline" : undefined}
      textAnchor={textAnchor !== "start" ? textAnchor : undefined}
      fontSize={localFontSize || undefined}
      dx={dx}
      style={TSPAN_STYLE}
      // xmlSpage="preserve" is deprecated but necessary for Apryse SVG->PDF conversion
      xmlSpace="preserve"
    >
      {spanText}
    </tspan>
  )
}
