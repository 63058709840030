import { TitleOption } from "./useTitleOptions"

export default function FormatTitlePreview({
  option,
}: {
  option?: TitleOption
}) {
  return (
    <>
      {option?.firstLine.label} Sample Company
      {option?.firstLine.comma && ", "}
      {option?.firstLine.lineBreak && <br />}
      {option?.secondLine.label} General Partner
    </>
  )
}
