import { Assignment } from "models/Assignment"
import { Item } from "models/Item"

export const isUnDistributed = (a: Partial<Assignment>) =>
  (!a.packetStatus && !a.status) || isRejected(a)

export const isInPDFPacket = (a: {
  status: Assignment["status"]
  packetStatus?: Assignment["packetStatus"]
}) => a.packetStatus === "handwritten" && !a.status

export const isSentForESigning = (a: Partial<Assignment>) =>
  (a.packetStatus === "draft" ||
    a.packetStatus === "delivered" ||
    a.packetStatus === "created" ||
    a.packetStatus === "sent") &&
  !a.status

export const isAwaitingApproval = (a: Partial<Assignment> & { item?: Item }) =>
  a.packetStatus === "completed" &&
  !isExecuted(a) &&
  !isRejected(a) &&
  (a.item?.isDeed ? Boolean(a.deedSubmissionAuditLogId) : true)

export const isAwaitingDeedSubmission = (a: Assignment & { item?: Item }) =>
  a.packetStatus === "completed" &&
  !isExecuted(a) &&
  !isRejected(a) &&
  Boolean(a.item?.isDeed) &&
  !a.deedSubmissionAuditLogId

export const isRejected = (a: Partial<Assignment>) => a.status === "rejected"

export const isExecuted = (a: Partial<Assignment>) =>
  Boolean(a.status && ["uploaded", "accepted"].includes(a.status))
