import useUpdateInstaPage from "../useUpdateInstaPage"
import { Block } from "models/InstaPageV2/Block"
import { SignatureBlock } from "models/InstaPageV2/Blocks/SignatureBlock"
import { QuickStyleOption } from "./QuickStyles"

export default function useUpdateQuickStyles(pageId: string, blocks: Block[]) {
  let { handleUpdateInstaPage } = useUpdateInstaPage()

  let signatureBlocks = blocks.filter(
    (block) => block.kind === "signature"
  ) as SignatureBlock[]

  const updateQuickStyles = (option: QuickStyleOption) => {
    let blocksWithUpdatedStyles = signatureBlocks.map((block) => {
      let blockStyles = block.blockStyles

      return {
        ...block,
        blockStyles: {
          ...blockStyles,
          header: {
            ...option.header,
          },
          signatoryName: {
            ...option.signatoryName,
          },
          signatoryDescription: option.signatoryDescription,
        },
      }
    })

    let indexesOfSignatureBlocks = blocks.reduce(
      (acc, block, index) =>
        block.kind === "signature" ? [...acc, index] : acc,
      [] as number[]
    )

    let updatedBlocksWithNewStyles = blocks.map((block, index) => {
      if (indexesOfSignatureBlocks.includes(index)) {
        return blocksWithUpdatedStyles.shift() as SignatureBlock
      }
      return block
    })

    handleUpdateInstaPage({ id: pageId, blocks: updatedBlocksWithNewStyles })
  }
  return {
    updateQuickStyles,
  }
}
