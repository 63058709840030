import { IconPropsType } from "./IconPropTypes"

export default function AllCapsIcon({
  x,
  y,
  width = 21,
  height = 14,
  classNames = "",
}: IconPropsType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x={x}
      y={y}
    >
      <path
        d="M3.99847 13.836V2.07328H0V0H10.451V2.07328H6.45255V13.836H3.99847Z"
        fill="currentColor"
        className={classNames}
      />
      <path
        d="M15.5474 13.836V2.07328H11.549V0H22V2.07328H18.0015V13.836H15.5474Z"
        fill="currentColor"
        className={classNames}
      />
    </svg>
  )
}
