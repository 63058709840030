import { IPRenderedPage } from "models/InstaPageV2"
import InsertRenderer from "./InsertRenderer"
import BlockHoverSVGContainer from "./BlockHoverSVGContainer"
import LineTextRenderer from "./LineTextRenderer"
import blocksToLines from "features/insta-pages2/renderer/blocks-to-lines"
import linesToContainers from "features/insta-pages2/renderer/lines-to-containers"
import containersToInsertBlocks from "features/insta-pages2/renderer/containers-to-insert-blocks"
import {
  useIPEditorEditorMode,
  useIPEditorShowMargins,
  useIPEditorToggleDebug,
} from "./editor-store"
import { FONT_NAMES, MARGINS, PAGE_SIZE } from "./helpers"
import { type InstaPageTab } from "models/InstaPageTab"
import { type SignerBlock } from "models/InstaPageV2/SignerBlock"
import linesToTabs from "features/insta-pages2/renderer/lines-to-tabs"

type SVGPageRendererProps = {
  pageNum?: number
  page: IPRenderedPage
  tabs?: InstaPageTab[]
  signerBlocks?: SignerBlock[]
  lineHeight?: number
}

export default function SVGPageRenderer({
  page,
  tabs = [],
  signerBlocks = [],
  lineHeight = 15,
  pageNum,
}: SVGPageRendererProps) {
  let { showMargins } = useIPEditorShowMargins()
  let { isDebug } = useIPEditorToggleDebug()

  let renderMode = useIPEditorEditorMode()

  let blocks = page.blocks || []

  let marginX = MARGINS[page.margins].x
  let marginY = MARGINS[page.margins].y

  let blockLines = blocksToLines(blocks, signerBlocks, {
    fontFamily: page.font,
    height: PAGE_SIZE.height - marginY * 2,
    width: PAGE_SIZE.width - marginX * 2,
    fontSize: page.fontSize,
    lineHeight,
  })

  // Fill em in
  for (let [idx, blockLine] of blockLines.entries()) {
    if (!blockLine) {
      blockLines[idx] = { lineHeight, nodes: [] }
    }
  }

  linesToTabs(blockLines, tabs, {
    marginX,
    marginY,
    lineHeight,
  })

  let editorContainers = linesToContainers(blockLines)
  let insertBlocks = containersToInsertBlocks(editorContainers, blocks)
  let lineYPosition = marginY

  return (
    <svg
      data-instapage-v2
      viewBox="0 0 612 792"
      className="w-full"
      xmlns="http://www.w3.org/2000/svg"
      fontFamily={FONT_NAMES[page.font]}
      fontSize={page.fontSize}
    >
      <rect
        data-instapage-v2-margin
        x={marginX}
        y={marginY}
        width={PAGE_SIZE.width - marginX * 2}
        height={PAGE_SIZE.height - marginY * 2}
        fill="none"
        fillOpacity={0}
      />
      {/* Red dashed border to show margins */}
      {showMargins && (
        <rect
          x={marginX}
          y={marginY}
          width={PAGE_SIZE.width - marginX * 2}
          height={PAGE_SIZE.height - marginY * 2}
          fill="none"
          fillOpacity={0}
          stroke="red"
          strokeWidth={1}
          strokeDasharray={3}
        />
      )}

      {isDebug && (
        <>
          <line
            x1={PAGE_SIZE.width / 2}
            y1={0}
            x2={PAGE_SIZE.width / 2}
            y2={PAGE_SIZE.height}
            stroke="red"
            strokeWidth={1}
            strokeDasharray={3}
          />
          {Array(blockLines.length)
            .fill(0)
            .map((_, idx) => {
              let y = marginY + (idx + 1) * lineHeight
              return (
                <line
                  key={idx}
                  x1={marginX}
                  y1={y}
                  x2={PAGE_SIZE.width - marginX}
                  y2={y}
                  stroke="blue"
                  strokeWidth={0.5}
                  strokeDasharray="1 1"
                />
              )
            })}
        </>
      )}

      {blockLines.map((line, idx) => {
        lineYPosition += line.lineHeight

        return (
          <LineTextRenderer
            key={idx}
            pageFontFamily={page.font}
            pageFontSize={page.fontSize}
            line={line}
            marginX={marginX}
            lineYPosition={lineYPosition}
          />
        )
      })}

      {renderMode === "edit" && (
        <>
          {editorContainers.map((container, idx) => {
            let block = blocks.find((b) => b.id === container.blockId)

            // return true if the block is a signature block and is the first found signature block
            let defaultSelectedBlock =
              blocks.findIndex((b) => b.kind === "signature") === idx

            if (!block) {
              return
            }

            return (
              <BlockHoverSVGContainer
                key={idx}
                blockId={block.id}
                x={container.x1 + marginX - page.fontSize / 2}
                y={container.y1 + marginY + lineHeight / 2 - page.fontSize / 2}
                width={container.x2 - container.x1}
                rectWidth={container.x2 - container.x1 + page.fontSize}
                height={container.y2 - container.y1 + page.fontSize / 2}
                kind={block.kind}
                defaultSelectedBlock={defaultSelectedBlock}
              />
            )
          })}

          {insertBlocks.map((line, idx) => {
            let insertProps = {
              pageId: page.id,
              insertIdx: idx,
              grid: line?.grid,
              blockIndex: line?.blockInsertIdx,
              y: line?.lineIdx ? line.lineIdx * lineHeight + marginY : marginY,
              height: 30,
              marginX,
              marginY,
              isAddFooterDisabled: page.blocks.some((b) => b.kind === "footer"),
              isAddLeadInDisabled:
                page.blocks.some((b) => b.kind === "lead_in") || pageNum !== 1,
              noLeadIn:
                page.blocks.every((b) => b.kind !== "lead_in") && idx === 0,
            }

            return <InsertRenderer key={idx} {...insertProps} />
          })}
        </>
      )}
    </svg>
  )
}
