import * as React from "react"
import { Button, Icon, Intent } from "@blueprintjs/core"

import ColorSpinner from "components/spinners/ColorSpinner"
import SAModal from "./SAModal"
import { BlueprintIcons_16Id } from "@blueprintjs/icons/lib/esm/generated-icons/16px/blueprint-icons-16"

type AreYouSureProps = {
  isLoadingOnConfirm?: boolean
  isOpen?: boolean
  onClose?: () => void
  canOutsideClickClose?: boolean
  children?: React.ReactNode
  onConfirm?: () => void
  disableConfirm?: boolean
  deleteText?: string
  cancelText?: string
  title?: React.ReactNode
  iconType?: "WARNING" | "DANGER" | "DEFAULT" | "WHITE"
}

export const AreYouSure = ({
  isOpen,
  onClose,
  canOutsideClickClose = false,
  children,
  onConfirm,
  disableConfirm = false,
  deleteText = "Delete",
  cancelText = "Cancel",
  isLoadingOnConfirm = false,
  title,
  iconType = "DEFAULT",
}: AreYouSureProps) => {
  const [isConfirming, setIsConfirming] = React.useState(false)

  let icon: BlueprintIcons_16Id =
    iconType === "WARNING"
      ? "warning-sign"
      : iconType === "DANGER"
        ? "high-priority"
        : "tick"

  React.useEffect(() => {
    if (isOpen) {
      setIsConfirming(false)
    }
  }, [isOpen])

  return (
    <SAModal
      onClose={onClose}
      canOutsideClickClose={canOutsideClickClose}
      isOpen={isOpen}
      icon={<Icon icon={icon} size={32} className="left" />}
      iconCenter
      iconType={iconType}
      title={title}
      body={
        <>
          <div className="mx-auto w-[380px] px-5">{children}</div>

          <div className="mt-6 flex rounded-b-sm bg-gray-50 px-5 py-3">
            {onClose && (
              <Button minimal className="ml-auto mr-2" onClick={onClose}>
                <div className="text-blue-9">{cancelText}</div>
              </Button>
            )}
            {onConfirm && (
              <Button
                icon={
                  isConfirming ? (
                    <ColorSpinner color="white" size={14} />
                  ) : undefined
                }
                intent={Intent.PRIMARY}
                onClick={() => {
                  if (isLoadingOnConfirm) {
                    setIsConfirming(true)
                  }
                  onConfirm()
                }}
                disabled={disableConfirm || isConfirming}
              >
                {deleteText}
              </Button>
            )}
          </div>
        </>
      }
    />
  )
}
