import { SignatoryDescriptionOption } from "./useSignatoryDescriptionOptions"

export default function FormatSignatoryDescriptionPreview({
  option,
}: {
  option?: SignatoryDescriptionOption
}) {
  return (
    <>
      Sample Company, {option?.lineBreak && <br />}a Delaware limited liability
      company
    </>
  )
}
