import { create, shallow } from "lib/store"

type ItemDetailStore = {
  selectedAssignmentId: string | null
  setSelectedAssignmentId: (id: string | null) => void
}

const useItemDetailStore = create<ItemDetailStore>((set) => ({
  selectedAssignmentId: null,
  setSelectedAssignmentId: (id: string | null) =>
    set({ selectedAssignmentId: id }),
}))

export function useSelectedAssignmentId() {
  let store = useItemDetailStore(
    ({ selectedAssignmentId, setSelectedAssignmentId }) => ({
      selectedAssignmentId,
      setSelectedAssignmentId,
    }),
    shallow
  )

  return store
}
