import { Menu } from "@blueprintjs/core"
import { MenuItem2, Popover2 } from "@blueprintjs/popover2"
import AddLeadInIcon from "./Icons/AddLeadInIcon"
import AddFooterIcon from "./Icons/AddFooterIcon"

export type AddBlockMenuProps = {
  isOpen: boolean
  onClose: () => void
  yPosition: number
  xPosition: number
  isAddLeadInDisabled: boolean
  isAddFooterDisabled: boolean
  addLeadInBlock: () => void
  addFooterBlock: () => void
  addTextBlock: () => void
}

export default function AddBlockMenu({
  isOpen,
  onClose,
  yPosition,
  xPosition,
  isAddLeadInDisabled = false,
  isAddFooterDisabled = false,
  addLeadInBlock,
  addFooterBlock,
  addTextBlock,
}: AddBlockMenuProps) {
  return (
    <Popover2
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
      minimal
      targetProps={{
        style: {
          position: "absolute",
          top: yPosition,
          left: xPosition,
          width: "20px",
          height: "20px",
        },
      }}
      content={
        <Menu className="min-w-[125px]">
          <MenuItem2
            text="Lead in"
            disabled={isAddLeadInDisabled}
            icon={<AddLeadInIcon />}
            onClick={addLeadInBlock}
          />
          <MenuItem2 text="Text" icon="align-justify" onClick={addTextBlock} />
          <MenuItem2
            text="Footer"
            disabled={isAddFooterDisabled}
            icon={<AddFooterIcon />}
            onClick={addFooterBlock}
          />
        </Menu>
      }
    >
      <div />
    </Popover2>
  )
}
