import {
  isInPDFPacket,
  isSentForESigning,
  isUnDistributed,
} from "app/signature-status/filters"
import {
  getTransactionAssignments,
  getTransactionCustomFieldKeys,
  getTransactionItems,
  getTransactionSignatories,
} from "features/transaction/id/api"
import { buildAuthRepTreeForEntity } from "helpers/mappings/buildAuthRepTreeForEntity"
import { getTransactionIdParam } from "helpers/params"
import { Assignment } from "models/Assignment"
import { Signer, SignerBlock } from "models/InstaPageV2/SignerBlock"
import { PacketSignatoryAssignment } from "models/PacketAssignment"
import { Signatory } from "models/Signatory"
import { Block } from "models/InstaPageV2/Block"
import { getDefaultSignerBlock } from "components/item-detail/InstapageV2/helpers"
import { getItemQueryData } from "features/item/api"

type CustomFieldsType = {
  id: string
  name: string
  transactionId: string
}

type PageAssignmentType = {
  id: PacketSignatoryAssignment["id"]
  signatory?: { id: Signatory["id"] }
  authRep?: { id: Signatory["id"] }
  header?: PacketSignatoryAssignment["header"]
  status: Assignment["status"]
  packetStatus?: Assignment["packetStatus"]
  signingGroupAssignment: { signingGroupId: string } | null
}

const getSignatoryCustomValues = (
  customValues: Signatory["customValues"],
  customFieldsData?: CustomFieldsType[]
) => {
  return customFieldsData?.map((cf) => {
    const matchingCustomValue =
      customValues?.find((cv) => cv.customFieldId === cf?.id)?.value ??
      undefined

    return {
      custom_key_uuid: cf.id,
      label: cf.name,
      value: matchingCustomValue,
    }
  })
}

export type GetConsolidatedAssignmentArg = {
  id: string
  signatory?: {
    id: string
    isOrg: boolean
  }
  authRep?: {
    id: string
  }
}

export function getConsolidatedAssignments(
  assignment: GetConsolidatedAssignmentArg,
  assignments: GetConsolidatedAssignmentArg[]
) {
  return assignments.filter((a) => {
    // if the assignment is the same as the current assignment, return true, check id's
    if (a.id === assignment.id) {
      return true
    }

    // Auth Reps ->
    if (assignment.signatory?.isOrg) {
      if (!assignment.authRep) {
        return a.signatory?.id === assignment.signatory?.id
      }

      let isSelectedAuthRepAnotherAuthRep =
        assignment.authRep && assignment.authRep.id === a?.authRep?.id

      let isSelectedAuthRepAnIndividual =
        assignment.authRep?.id === a?.signatory?.id && !a?.authRep

      return isSelectedAuthRepAnotherAuthRep || isSelectedAuthRepAnIndividual
    }

    return (
      a.signatory?.id === assignment.signatory?.id ||
      a.authRep?.id === assignment.signatory?.id
    )
  })
}

export default function getSelectedSignerBlock(
  consolidateSigner: boolean = false,
  pageAssignments: PageAssignmentType[],
  selectedAssignmentId: string | null,
  blocks: Block[]
): SignerBlock[] | null {
  let signerBlocks: SignerBlock[] = []

  let transactionId = getTransactionIdParam()

  let customFieldsData = getTransactionCustomFieldKeys(transactionId)

  let assignments = []

  // Early return if there is no selectedAssignmentId, using placeholder data so we can show the
  // additional fields & custom fields in the preview when no signer is selected
  if (!selectedAssignmentId) {
    return getDefaultSignerBlock(blocks)
  }

  let maybeSelectedAssignment = pageAssignments.find(
    (a) => a.id === selectedAssignmentId
  )

  if (!maybeSelectedAssignment) return null

  let selectedAssignment = maybeSelectedAssignment

  let selectedAssignmentSignatory = getTransactionSignatories(
    transactionId
  ).find((s) => s.id === selectedAssignment.signatory?.id)

  if (consolidateSigner && selectedAssignment) {
    /*------------------------------------------------------------------------
     * Find all applicable assignments on the page for the selected signatory.
     * These can either be assignments where the signatory is an auth rep
     * or the assignment signatory is the selected signatory
     * -----------------------------------------------------------------------
     */
    assignments = pageAssignments.filter(notYetDistributed).filter((a) => {
      // Groups ->
      let selectedAssignmentGroupId =
        selectedAssignment.signingGroupAssignment?.signingGroupId

      if (
        selectedAssignmentGroupId !== a.signingGroupAssignment?.signingGroupId
      ) {
        return false
      }

      // Auth Reps ->
      if (selectedAssignmentSignatory?.isOrg) {
        if (!selectedAssignment.authRep) {
          return a.signatory?.id === selectedAssignment.signatory?.id
        }

        let isSelectedAuthRepAnotherAuthRep =
          selectedAssignment.authRep.id === a?.authRep?.id

        let isSelectedAuthRepAnIndividual =
          selectedAssignment.authRep?.id === a?.signatory?.id && !a?.authRep

        return isSelectedAuthRepAnotherAuthRep || isSelectedAuthRepAnIndividual
      }

      return (
        a.signatory?.id === selectedAssignment.signatory?.id ||
        a.authRep?.id === selectedAssignment.signatory?.id
      )
    })
  } else {
    assignments.push(selectedAssignment)
  }

  assignments.sort((a, b) => {
    return (
      pageAssignments.findIndex((pa) => pa.id === a.id) -
      pageAssignments.findIndex((pa) => pa.id === b.id)
    )
  })

  for (let assignment of assignments) {
    let signatories: Signer[] = []

    let transactionSignatories = getTransactionSignatories(transactionId)

    let signatory = transactionSignatories.find(
      (s) => s.id === assignment?.signatory?.id
    )

    if (!signatory) continue

    let signatoryWithAuthReps = buildAuthRepTreeForEntity(
      signatory,
      transactionSignatories
    )

    let authRepCustomFieldsData = customFieldsData.filter(
      (cf): cf is CustomFieldsType => Boolean(cf.id)
    )

    signatories.push({
      uuid: signatory.id,
      name: signatory.name,
      email: signatory.email,
      // this is the top-level signatory so a title doesn't make sense here. Auth Reps get their title later
      title: "",
      instapagesConfig: signatory.instapagesConfig,
      description: signatory.description,
      address: signatory.internationalAddress ?? "",
      customValues: getSignatoryCustomValues(
        signatory?.customValues ?? [],
        authRepCustomFieldsData
      ),
      isOrg: signatory.isOrg,
    })

    let authRepId = assignment.authRep?.id

    if (authRepId) {
      addAuthRepsToSignerBlock(
        signatories,
        signatoryWithAuthReps.authReps,
        authRepId,
        authRepCustomFieldsData
      )
    }

    signerBlocks.push({
      header: assignment.header,
      assignmentId: assignment.id,
      signatories,
    })
  }

  return signerBlocks
}

export type SignerBlockAuthRep = {
  internationalAddress?: Signatory["internationalAddress"]
  authReps?: SignerBlockAuthRep[]
  customValues?: Signatory["customValues"]
  description?: Signatory["description"]
  email?: Signatory["email"]
  id: Signatory["id"]
  instapagesConfig: Signatory["instapagesConfig"]
  isOrg?: Signatory["isOrg"]
  name: Signatory["name"]
  title?: Signatory["title"]
}

function addAuthRepsToSignerBlock(
  signatories: Signer[],
  authReps: SignerBlockAuthRep[],
  finalAuthRepId: string,
  customFieldsData?: CustomFieldsType[]
) {
  if (authReps.length === 0) return

  if (authReps.length === 1) {
    let authRep = authReps[0]
    if (authRep) {
      signatories.push({
        address: authRep.internationalAddress ?? "",
        customValues: getSignatoryCustomValues(
          authRep.customValues ?? [],
          customFieldsData
        ),
        description: authRep.description,
        email: authRep.email,
        instapagesConfig: authRep.instapagesConfig,
        isOrg: authRep.isOrg,
        name: authRep.name,
        title: authRep.title,
        uuid: authRep.id,
      })

      return addAuthRepsToSignerBlock(
        signatories,
        authRep.authReps || [],
        finalAuthRepId,
        customFieldsData
      )
    }
  }

  let finalAuthRep = authReps.find((ar) => ar.id === finalAuthRepId)

  if (finalAuthRep) {
    signatories.push({
      address: finalAuthRep.internationalAddress ?? "",
      customValues: getSignatoryCustomValues(
        finalAuthRep.customValues ?? [],
        customFieldsData
      ),
      description: finalAuthRep.description,
      email: finalAuthRep.email,
      instapagesConfig: finalAuthRep.instapagesConfig,
      isOrg: finalAuthRep.isOrg,
      name: finalAuthRep.name,
      title: finalAuthRep.title,
      uuid: finalAuthRep.id,
    })
  }
}

export function getSignerBlocksForAssignment(
  assignmentId: string
): SignerBlock[] | null {
  let transactionId = getTransactionIdParam()

  let transactionAssignments = getTransactionAssignments(transactionId)

  let maybeAssignment = transactionAssignments.find(
    (a) => a.id === assignmentId
  )

  let maybeItem = getItemQueryData(maybeAssignment?.itemId || "")

  let maybeAssignmentPage = (
    maybeItem ||
    getTransactionItems(transactionId).find(
      (item) => item.id === maybeAssignment?.itemId
    )
  )?.pages.find((page) => page.id === maybeAssignment?.itemPageId)

  let pageAssignments =
    maybeAssignmentPage?.assignments
      ?.map((aId) => transactionAssignments.find((a) => a.id === aId))
      .filter((a): a is Assignment => Boolean(a)) || []

  let isConsolidated =
    maybeAssignmentPage?.type === "instapagev2"
      ? maybeAssignmentPage?.consolidateSigner
      : false

  let blocks =
    maybeAssignmentPage?.type === "instapagev2"
      ? maybeAssignmentPage?.blocks
      : []

  return getSelectedSignerBlock(
    isConsolidated,
    pageAssignments.map((a) => ({
      id: a.id,
      signatory: a.signatory,
      authRep: a.authRep ? { id: a.authRep } : undefined,
      header: a.header,
      signingGroupAssignment: a.signingGroupAssignment,
      status: a.status,
      packetStatus: a.packetStatus,
    })),
    assignmentId,
    blocks
  )
}

function notYetDistributed(assignment: {
  status: Assignment["status"]
  packetStatus?: Assignment["packetStatus"]
}) {
  return (
    isUnDistributed(assignment) ||
    isInPDFPacket(assignment) ||
    isSentForESigning(assignment)
  )
}
