import { _Text } from "features/insta-pages2/renderer/blocks-to-lines"
import TSPanRenderer from "./TSpanRenderer"
import contentToWordChunk, {
  getWordChunkWidth,
  removeTrailingSpaces,
} from "features/insta-pages2/renderer/editor-content-to-word-chunk"
import { FONT_NAMES } from "./helpers"
import { FontType } from "models/InstaPageV2"

type JustifiedLineTextProps = {
  blockXPosition: number
  lineYPosition: number
  pageAttrs: PageAttrs
  textNodes: _Text[]
}
type PageAttrs = {
  fontFamily: FontType
  fontSize: number
  lineHeight: number
  marginX: number
}

export default function JustifiedLineText({
  blockXPosition,
  lineYPosition,
  textNodes,
  pageAttrs,
}: JustifiedLineTextProps) {
  let result = useJustifiedLineText(blockXPosition, textNodes, pageAttrs)
  let isLastLineInParagraph = textNodes[0]?.isLastLineInParagraph || false

  return (
    <text x={blockXPosition} y={lineYPosition}>
      {result.wordChunks.map((wc, wcIdx) => {
        let shouldJustify = !isLastLineInParagraph && wcIdx !== 0

        return wc.content.map((content, cIdx) => {
          return (
            <TSPanRenderer
              key={`#{content.text}-${wcIdx}-${cIdx}`}
              textNode={{
                id: `${content.text}-${wcIdx}-${cIdx}`,
                text: content.text || "",
                marks: content.marks,
              }}
              dx={shouldJustify ? result.dx : undefined}
            />
          )
        })
      })}
    </text>
  )
}

function useJustifiedLineText(
  startX: number,
  textNodes: _Text[],
  pageAttrs: PageAttrs
) {
  let availableWidth =
    (textNodes[0]?.blockXEnd || 0) -
    (textNodes[0]?.blockX || startX - pageAttrs.marginX || 0)

  let wordChunks = removeTrailingSpaces(
    contentToWordChunk(textNodes, {
      family: FONT_NAMES[pageAttrs.fontFamily],
      lineHeight: pageAttrs.lineHeight,
      size: `${pageAttrs.fontSize || 12}px`,
    })
  )

  let totalWidth = getWordChunkWidth(wordChunks)
  let dx = (availableWidth - totalWidth) / (wordChunks.length - 1)
  let percentTextWidth = totalWidth / availableWidth

  return { wordChunks, totalWidth, dx, percentTextWidth }
}
