import { FontType, MarginType } from "models/InstaPageV2"
import { Block, BlockKindType, TextAlignType } from "models/InstaPageV2/Block"
import { SignatureBlock } from "models/InstaPageV2/Blocks/SignatureBlock"
import { QuickStyleOption } from "./QuickStyles/QuickStyles"

export const PAGE_SIZE = {
  width: 612,
  height: 792,
}

export const MARGINS: Record<MarginType, { x: number; y: number }> = {
  regular: {
    x: 72,
    y: 72,
  },
  narrow: {
    x: 36,
    y: 36,
  },
  moderate: {
    x: 54,
    y: 72,
  },
  large: {
    x: 90,
    y: 90,
  },
}

function enabledCustomFieldsForPage(blocks: Block[]) {
  return blocks
    .filter((block) => block.kind === "signature")
    .flatMap((sigBlock) =>
      "additionalFields" in sigBlock ? sigBlock.additionalFields : []
    )
    .filter((af) => af.isEnabled)
}

export function getDefaultSignerBlock(blocks: Block[]) {
  return [
    {
      header: "Header",
      assignmentId: "placeholder-assignment-id",
      signatories: [
        {
          uuid: "fake-placeholder",
          name: "Signatory name",
          title: "",
          description: "",
          customValues: enabledCustomFieldsForPage(blocks).map((cf) => ({
            custom_key_uuid: cf.id || "",
            label: String(cf.name),
            value: "",
          })),
        },
        {
          uuid: "fake-auth-rep-placeholder",
          name: "",
          title: "",
          customValues: enabledCustomFieldsForPage(blocks).map((cf) => ({
            custom_key_uuid: cf.id || "",
            label: String(cf.name),
            value: "",
          })),
        },
      ],
    },
  ]
}

export const FIELD_LINE_TAB_STOP = 36

export const FONT_NAMES: Record<FontType, string> = {
  arial: "Arial",
  calibri: "Calibri",
  inter: "Inter",
  helvetica: "Helvetica",
  open_sans: "Open Sans",
  times_new_roman: "Times New Roman",
}

export const getBlockTypeLabel = (blockType: BlockKindType) => {
  if (blockType === "lead_in") {
    return "Lead-in"
  } else if (blockType === "signature") {
    return "Signature Block"
  } else {
    return blockType
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  }
}

export const getGridPosition = (grid: [number, number]) => {
  return grid[0] === 2
    ? "right"
    : grid[0] === 1 && grid[1] === 2
      ? "full"
      : "left"
}

export const getTextAlignmentFromString = (input: string): TextAlignType => {
  switch (input) {
    case "center":
      return "center"
    case "right":
      return "right"
    case "justify":
      return "justify"
    default:
      return "left"
  }
}
export const getTextAnchorFromAlign = (textAlign: TextAlignType = "left") => {
  switch (textAlign) {
    case "center":
      return "middle"
    case "right":
      return "end"
    case "left":
      return "start"
    default:
      return "start"
  }
}

export function getInsertBarPosition(
  gridPosition: string,
  marginX: number,
  y: number,
  height: number
) {
  const isLeft = gridPosition === "left"
  const isRight = gridPosition === "right"
  const isColumn = isLeft || isRight

  const halfCanvasWidth = PAGE_SIZE.width / 2
  const quarterCanvasWidth = PAGE_SIZE.width / 4
  const marginHalf = marginX / 2
  const iconCircleSpacing = 10 // width of circle is 20px, so 10px spacing
  const iconSpacing = 8 // want a 2px padding between circle and plus icon
  const anchorOffset = 2 // offset to position anchor past margin line
  const leftGridStartPosition = quarterCanvasWidth + marginHalf
  const rightGridStartPosition = 3 * quarterCanvasWidth - marginHalf

  const rectX = isRight ? halfCanvasWidth : marginX
  const rectWidth =
    isLeft || isRight
      ? halfCanvasWidth - marginX
      : PAGE_SIZE.width - marginX * 2
  const leftAnchorX = isRight
    ? halfCanvasWidth - anchorOffset
    : marginX - anchorOffset
  const leftLineStartX = isRight ? halfCanvasWidth : marginX
  const leftLineEndX = isLeft
    ? leftGridStartPosition - iconCircleSpacing
    : isRight
      ? rightGridStartPosition - iconCircleSpacing
      : halfCanvasWidth - iconCircleSpacing
  const centerCircleX = isLeft
    ? leftGridStartPosition
    : isRight
      ? rightGridStartPosition
      : halfCanvasWidth
  const plusIconX = isLeft
    ? leftGridStartPosition - iconSpacing
    : isRight
      ? rightGridStartPosition - iconSpacing
      : halfCanvasWidth - iconSpacing
  const rightLineStartX = isLeft
    ? leftGridStartPosition + iconCircleSpacing
    : isRight
      ? rightGridStartPosition + iconCircleSpacing
      : halfCanvasWidth + iconCircleSpacing
  const rightLineEndX = isLeft ? halfCanvasWidth : PAGE_SIZE.width - marginX
  const rightAnchorX = isLeft
    ? halfCanvasWidth + anchorOffset
    : PAGE_SIZE.width - marginX + anchorOffset
  const hoverLineYPosition = isColumn ? y : y + height / 2
  const plusIconYPosition = isColumn ? y - 8 : y - 8 + height / 2

  return {
    rectX,
    rectY: y,
    rectWidth,
    rectHeight: height,
    leftAnchorX,
    leftAnchorY: hoverLineYPosition,
    leftLineStartX: leftLineStartX,
    leftLineStartY: hoverLineYPosition,
    leftLineEndX: leftLineEndX,
    leftLineEndY: hoverLineYPosition,
    centerCircleX,
    centerCircleY: hoverLineYPosition,
    plusIconX,
    plusIconY: plusIconYPosition,
    rightLineStartX,
    rightLineStartY: hoverLineYPosition,
    rightLineEndX,
    rightLineEndY: hoverLineYPosition,
    rightAnchorX,
    rightAnchorY: hoverLineYPosition,
  }
}

export const isQuickStyleActive = (
  option: QuickStyleOption,
  signatureBlocks: SignatureBlock[]
) => {
  let activeBlocks = signatureBlocks.filter((block) => {
    let blockStyles = block.blockStyles
    let headerStyles = blockStyles.header
    let signatoryNameStyles = blockStyles.signatoryName
    let signatoryDescriptionStyles = blockStyles.signatoryDescription

    return (
      headerStyles.bold === option.header.bold &&
      headerStyles.textTransform === option.header.textTransform &&
      headerStyles.underline === option.header.underline &&
      signatoryNameStyles.bold === option.signatoryName.bold &&
      signatoryNameStyles.textTransform ===
        option.signatoryName.textTransform &&
      signatoryDescriptionStyles.isEnabled ===
        option.signatoryDescription.isEnabled &&
      signatoryDescriptionStyles.format === option.signatoryDescription.format
    )
  })

  return activeBlocks.length > 0
}

export function getBlockXPosition(
  text: {
    text?: string
    width?: number
    blockX: number
    blockXEnd: number
    textAlign?: string
  },
  marginX: number
) {
  let blockXPosition: number = 0

  // if text blockX is greater than 0 then that means its in the right side grid so we need to calculate the center based off of the blockX and blockXEnd
  if (
    text &&
    "textAlign" in text &&
    text.textAlign === "center" &&
    text.blockX > 0
  ) {
    blockXPosition = text.blockX + marginX + (text.blockXEnd - text.blockX) / 2
    // if text blockX is 0 left side grid so we can just calculate the center based off of the blockXEnd
  } else if (
    text &&
    "textAlign" in text &&
    text.textAlign === "center" &&
    text.blockX === 0
  ) {
    blockXPosition = text.blockXEnd / 2 + marginX
    // Right grid and right aligned text means we need to calculate the blockXEnd + marginX
  } else if (text && "textAlign" in text && text.textAlign === "right") {
    blockXPosition = text.blockXEnd + marginX
    // all other cases we can just use the blockX + marginX
  } else {
    let indentAmount = 0
    let isIndent = text.text === "" && (text.width || 0) > 0

    if (isIndent) {
      indentAmount = text.width || 0
    }
    blockXPosition = (text?.blockX || 0) + marginX + indentAmount
  }

  return blockXPosition
}
