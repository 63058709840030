import { type Line } from "./blocks-to-lines"
import yPositionFromLineIndex from "./y-position-from-line-index"

export type Container = {
  blockId: string
  x1: number
  x2: number
  y1: number
  y2: number
  numLines: number
  lineStartIdx: number
  lineEndIdx: number
}

export default function linesToContainers(
  lines: (Line | undefined)[]
): Container[] {
  let containersByBlockId: { [blockId: string]: Container } = {}

  for (let [lineIdx, line] of lines.entries()) {
    // It's possible that there are blank lines within the lines array
    // if so, skip them
    if (!line) {
      continue
    }

    let lineTexts = line.nodes

    let lineYPosition = yPositionFromLineIndex(lineIdx, lines)

    for (let [, text] of lineTexts.entries()) {
      let container = containersByBlockId[text.blockId]

      if (!container) {
        container = {
          blockId: text.blockId,
          x1: text.blockX,
          x2: text.blockXEnd,
          y1: lineYPosition - line.lineHeight,
          y2: lineYPosition,
          numLines: 1,
          lineStartIdx: lineIdx,
          lineEndIdx: lineIdx,
        }
        containersByBlockId[text.blockId] = container
      } else {
        container.x1 = Math.min(container.x1, text.blockX)
        container.x2 = Math.max(container.x2, text.blockXEnd)
        container.y1 = Math.min(container.y1, lineYPosition)
        container.y2 = Math.max(container.y2, lineYPosition)
        container.lineStartIdx = Math.min(container.lineStartIdx, lineIdx)
        container.lineEndIdx = Math.max(container.lineEndIdx, lineIdx)
        container.numLines = container.lineEndIdx - container.lineStartIdx
      }
    }
  }

  return Object.values(containersByBlockId)
}
