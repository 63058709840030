import {
  FONT_NAMES,
  PAGE_SIZE,
  getTextAlignmentFromString,
} from "components/item-detail/InstapageV2/helpers"
import { FontType } from "models/InstaPageV2"
import { Block, TextAlignType } from "models/InstaPageV2/Block"
import contentToLines from "../editor/content-to-lines"
import type { ContentInLine } from "models/InstaPageV2/Content"
import { SignerBlock } from "models/InstaPageV2/SignerBlock"
import renderSignatureBlockContent from "./render-signature-block-content"
import yPositionFromLineIndex from "./y-position-from-line-index"

/* Witness _________[GRID_GAP]Email: _________ */
const GRID_GAP = 36

// [_Text, _Text, _Text, _Text, _Text]
export type _Text = {
  id: string
  type: "text"
  text: string
  marks?: {
    attrs?: Record<string, string>
    type: string
  }[]
  blockX: number
  blockXEnd: number
  blockId: string
  textAlign?: TextAlignType
  width: number
  isLastLineInParagraph: boolean
}

type Placeholder = {
  id: string
  type: "spacer"
  blockX: number
  blockXEnd: number
  blockId: string
  x: number
}

type ESignTabPlaceholder = {
  id: string
  type: "title" | "signature" | "name" | "value" | "address" | "date" | "email"
  value?: string
  blockX: number
  blockXEnd: number
  blockId: string
  x: number
  assignmentId: string
}

export type Node = _Text | Placeholder | ESignTabPlaceholder

export type Line = {
  lineHeight: number
  nodes: Node[]
}

type Meta = {
  fontFamily: FontType
  fontSize: number
  height: number
  width: number
  lineHeight: number
}

const defaultMeta: Meta = {
  fontFamily: "times_new_roman",
  height: PAGE_SIZE.height,
  width: PAGE_SIZE.width,
  fontSize: 12,
  lineHeight: 15,
}

export default function blocksToLines(
  pageBlocks: Block[],
  signerBlocks: SignerBlock[] = [],
  meta: Meta = defaultMeta
): Line[] {
  let fontFamily = FONT_NAMES[meta.fontFamily]
  let lines: Line[] = []
  let blockStartIndex = lines.length

  for (let [blockIdx, block] of pageBlocks.entries()) {
    let isLeftGrid = block.grid[0] === 1 && block.grid[1] === 1

    let isRightGrid = block.grid[0] === 2 && block.grid[1] === 2

    let isFullWidth = !isLeftGrid && !isRightGrid

    let blockXPosition =
      block.grid[0] === 1
        ? 0
        : meta.width - meta.width / block.grid[0] + GRID_GAP / 2

    let blockXEndPosition =
      block.grid[1] === 2 ? meta.width : meta.width / 2 - GRID_GAP / 2

    switch (block.kind) {
      case "signature": {
        let signatureBlock = block
        let isLeftGridWithRightGridBlockAfter =
          isLeftGrid && pageBlocks[blockIdx + 1]?.grid[0] === 2

        let blockLineIndex = blockStartIndex

        const addContentToLineIndex = (lineIndex: number, content: Node) => {
          // We need to keep track of the line index for each signer block
          // so we know which line to start the next signer block on
          blockLineIndex = lineIndex
          addNodeToLine(lineIndex, content, lines, meta.lineHeight)
        }

        if (signerBlocks.length > 0) {
          signerBlocks.forEach((signer, idx) => {
            if (idx > 0) {
              // Add vertical spacing between signer blocks
              blockLineIndex += 2
            }

            renderSignatureBlockContent(
              signer,
              blockLineIndex,
              addContentToLineIndex,
              signatureBlock,
              blockXPosition,
              blockXEndPosition,
              meta.lineHeight,
              fontFamily,
              meta.fontSize
            )
          })
        }

        if (isRightGrid || !isLeftGridWithRightGridBlockAfter) {
          addParagraphSpacerLines(lines, meta)
          blockStartIndex = lines.length
        }

        break
      }
      case "lead_in":
      case "text": {
        let contentLines = contentToLines(block.content, {
          width: blockXEndPosition - blockXPosition,
          lineHeight: meta.lineHeight,
          fontFamily: fontFamily,
          fontSize: meta.fontSize,
        })

        for (let [contentLinesIdx, contents] of contentLines.entries()) {
          for (let [contentIdx, content] of contents.entries()) {
            addNodeToLine(
              blockStartIndex + contentLinesIdx,
              getTextFromContent(
                content,
                blockXPosition,
                blockXEndPosition,
                block,
                block.id + "-" + contentIdx + "-" + contentLinesIdx,
                block.textAlign ||
                  getTextAlignmentFromString(
                    String(content.attrs?.textAlign) || "left"
                  )
              ),
              lines,
              meta.lineHeight
            )
          }
        }

        if (isFullWidth || isRightGrid) {
          addParagraphSpacerLines(lines, meta)
          blockStartIndex = lines.length
        }
        break
      }
      case "footer": {
        let numberOfLinesAvailable = getNumberOfLinesAvailable(
          meta.height,
          lines,
          meta.lineHeight
        )

        let footerContentLines = contentToLines(block.content, {
          width: meta.width,
          lineHeight: meta.lineHeight,
          fontFamily: fontFamily,
          fontSize: meta.fontSize,
        })

        let footerLinesLength = footerContentLines.length

        for (let i = 0; i < numberOfLinesAvailable - footerLinesLength; i++) {
          lines.push({ lineHeight: meta.lineHeight, nodes: [] })
        }

        for (let [contentLinesIdx, contents] of footerContentLines.entries()) {
          let contentLineHeight = meta.lineHeight

          for (let [contentIdx, content] of contents.entries()) {
            let lineIndex =
              blockStartIndex +
              (numberOfLinesAvailable < footerLinesLength
                ? 0
                : numberOfLinesAvailable - footerLinesLength) +
              contentLinesIdx

            let textAlignment =
              block.textAlign ||
              getTextAlignmentFromString(
                String(content.attrs?.textAlign) || "left"
              )

            addNodeToLine(
              lineIndex,
              getTextFromContent(
                content,
                blockXPosition,
                blockXEndPosition,
                block,
                block.id + "-" + contentIdx + "-" + contentLinesIdx,
                textAlignment
              ),
              lines,
              contentLineHeight
            )
          }
        }
        break
      }
    }
    // if there is no footer block, we should add the remaining lines
    // to the lines array
    let noFooterBlock = blockIdx === pageBlocks.length - 1

    if (noFooterBlock) {
      let numberOfLinesAvailable = getNumberOfLinesAvailable(
        meta.height,
        lines,
        meta.lineHeight
      )
      for (let i = 0; i < numberOfLinesAvailable; i++) {
        lines.push({ lineHeight: meta.lineHeight, nodes: [] })
      }
    }
  }

  // if there are no blocks, we should add the remaining lines to the lines array
  let noBlocks = pageBlocks.length === 0

  if (noBlocks) {
    let numberOfLinesAvailable = getNumberOfLinesAvailable(
      meta.height,
      lines,
      meta.lineHeight
    )
    for (let i = 0; i < numberOfLinesAvailable; i++) {
      lines.push({ lineHeight: meta.lineHeight, nodes: [] })
    }
  }

  return lines
}

function getNumberOfLinesAvailable(
  height: number,
  lines: Line[],
  lineHeight: number
): number {
  let currentContentY = yPositionFromLineIndex(lines.length - 1, lines)

  let availableHeight = height - currentContentY
  let numberOfLinesAvailable = Math.floor(availableHeight / lineHeight)

  return numberOfLinesAvailable
}

function getTextFromContent(
  content: ContentInLine,
  blockX: number,
  blockXEnd: number,
  block: Block,
  id: string,
  textAlign?: TextAlignType
): _Text {
  return {
    id: id,
    type: "text",
    text: content.text || "",
    marks: content.marks || [],
    blockX,
    blockXEnd,
    blockId: block.id,
    textAlign,
    width: content.width,
    isLastLineInParagraph: content.isLastLineInParagraph,
  }
}

export function addNodeToLine(
  lineIndex: number,
  node: Node,
  lines: Line[],
  lineHeight: number
) {
  let currentLine = lines[lineIndex]
  if (!currentLine) {
    currentLine = { lineHeight: lineHeight, nodes: [] }
    lines[lineIndex] = currentLine
  }
  currentLine.nodes.push(node)
}

function addParagraphSpacerLines(lines: Line[], meta: Meta) {
  lines.push({
    lineHeight: meta.lineHeight,
    nodes: [],
  })
  lines.push({
    lineHeight: meta.lineHeight,
    nodes: [],
  })
}
