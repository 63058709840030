import {
  getTransactionItems,
  getTransactionSignatureBlockTemplates,
} from "features/transaction/id/api"
import {
  patchSignatureBlockTemplate,
  postTransactionSignatureBlockTemplate,
} from "helpers/api"
import { apiClient } from "lib/api"
import { InstaPageV2 } from "models/InstaPageV2"
import { SignatureBlock } from "models/InstaPageV2/Blocks/SignatureBlock"
import { APIItem } from "models/Item"
import { decode as decodeAPIPages } from "models/Pages"

export default function saveTransactionSignerBlockStyleIfOriginal(
  updatedItem: APIItem
) {
  let transactionId = updatedItem.transaction
  let items = getTransactionItems(transactionId)
  let transactionInstaPages: InstaPageV2[] = []

  for (let item of items) {
    let pages = item.pages
    if (item.id === updatedItem.uuid) {
      // The changes to the item that is getting updated are not
      // yet reflected in the transaction items query data. So we
      // need to check the updating item separately.
      pages = updatedItem.pages.map(decodeAPIPages)
    }

    for (let page of pages) {
      if (page.type === "instapagev2") {
        transactionInstaPages.push(page)
      }
    }
  }

  let signatureBlockTemplate =
    getTransactionSignatureBlockTemplates(transactionId)[0]

  if (
    transactionInstaPages.length === 1 ||
    // In case there are any in progress transactions that already have instapages
    // but don't yet have a signatureBlockTemplate, we want to create one
    (transactionInstaPages.length > 1 && !signatureBlockTemplate)
  ) {
    let page = transactionInstaPages[0]

    if (page) {
      let signatureBlock = page.blocks.find((b) => b.kind === "signature")

      if (signatureBlock && signatureBlock.kind === "signature") {
        saveSignerBlockTemplate(transactionId, signatureBlock)
          .then(() => {
            apiClient.refetchQueries([
              "transactions",
              transactionId,
              "signature-block-templates",
            ])
          })
          .catch(() => {
            console.error(
              "Failed to save transaction signer block template for transactionId: ",
              transactionId
            )
          })
      }
    }
  }
}

async function saveSignerBlockTemplate(
  transactionId: string,
  block: SignatureBlock
) {
  let signatureBlockTemplate =
    getTransactionSignatureBlockTemplates(transactionId)[0]

  if (!signatureBlockTemplate) {
    return postTransactionSignatureBlockTemplate(transactionId, block)
  }

  return patchSignatureBlockTemplate(signatureBlockTemplate.id, block)
}
