import { SignatoryDescriptionOptions } from "models/Signatory"

export type SignatoryDescriptionOption = {
  name: SignatoryDescriptionOptions
  lineBreak: boolean
}

export default function useSignatoryDescriptionOptions(): SignatoryDescriptionOption[] {
  const SIGNATORY_DESCRIPTION_OPTIONS = [
    {
      name: SignatoryDescriptionOptions.STYLE1,
      lineBreak: false,
    },
    {
      name: SignatoryDescriptionOptions.STYLE2,
      lineBreak: true,
    },
  ]

  return SIGNATORY_DESCRIPTION_OPTIONS
}
