import { useEffect } from "react"
import classNames from "classnames"
import {
  useIPEditorAddBlock,
  useIPEditorNewBlockId,
  useIPEditorSelectedBlock,
} from "./editor-store"
import { useSelectedAssignmentId } from "../item-detail-store"
import { type BlockKindType } from "models/InstaPageV2/Block"

type BlockHoverSVGContainerProps = {
  blockId: string
  x: number
  y: number
  width: number
  rectWidth: number
  height: number
  kind: BlockKindType
  defaultSelectedBlock?: boolean
}

export default function BlockHoverSVGContainer({
  blockId,
  x,
  y,
  rectWidth,
  height,
  kind,
  defaultSelectedBlock = false,
}: BlockHoverSVGContainerProps) {
  let { selectedBlockId, setSelectedBlockId } = useIPEditorSelectedBlock()
  let { selectedAssignmentId } = useSelectedAssignmentId()
  let { setAddBlock } = useIPEditorAddBlock()
  let { newBlockId, setNewBlockId } = useIPEditorNewBlockId()

  let isTextBlock = kind === "text" || kind === "lead_in" || kind === "footer"
  let isSignatureBlock = kind === "signature"

  const handleSelectBlock = () => {
    setSelectedBlockId(blockId)
  }

  useEffect(() => {
    // If the new block id is the same as the block id, then we want to select/edit the block
    if (blockId === newBlockId) {
      handleSelectBlock()
      setAddBlock(null)
      setNewBlockId("")
    }
  }, [newBlockId, blockId])

  useEffect(() => {
    if (defaultSelectedBlock) {
      handleSelectBlock()
    }
  }, [])

  useEffect(() => {
    if (selectedAssignmentId && !selectedBlockId && isSignatureBlock) {
      setSelectedBlockId(blockId)
    }
  }, [selectedAssignmentId])

  let isThisBlockSelected = selectedBlockId === blockId

  let parentClasses = classNames(
    "transition-opacity duration-75 ease-in opacity-0",
    { "opacity-0": isThisBlockSelected },
    { "hover:opacity-100": !isThisBlockSelected }
  )

  return (
    <g className={parentClasses}>
      <g
        pointerEvents="all"
        className={isTextBlock ? "cursor-text" : "cursor-pointer"}
        onMouseDown={(e) => {
          if (!isThisBlockSelected) {
            e.stopPropagation()
            handleSelectBlock()
          }
        }}
      >
        <rect
          x={x}
          y={y}
          rx={3}
          ry={3}
          width={rectWidth}
          height={height}
          fill="none"
          className="stroke-fuchsia-700"
          strokeWidth={1}
          strokeDasharray={2}
        />
      </g>
    </g>
  )
}
