import { v4 as uuid } from "uuid"
import {
  useIPEditorAddBlock,
  useIPEditorNewBlockId,
  useIPEditorSelectedBlock,
} from "./editor-store"
import useUpdateInstaPage from "./useUpdateInstaPage"
import { apiClient } from "lib/api"
import { useParam } from "helpers/params"
import { APIItem } from "models/Item"
import { decode as decodePageFromAPI } from "models/Pages"
import { InstaPageV2 } from "models/InstaPageV2"
import { DEFAULT_BLOCK_STYLES } from "models/InstaPageV2/Blocks/BlockStyles"
import { BlockGrid } from "models/InstaPageV2/Block"

export default function useUpdateTextBlock(pageId: string) {
  let itemId = useParam("itemId")

  let { addBlock } = useIPEditorAddBlock()
  let { setSelectedBlockId } = useIPEditorSelectedBlock()
  let { handleUpdateInstaPage } = useUpdateInstaPage()
  let { setNewBlockId } = useIPEditorNewBlockId()
  let insertBlockIndex = addBlock?.blockIndex ?? 0

  let itemQuery = apiClient.getQueryData<APIItem>(["items", itemId])
  let pages = itemQuery?.pages.map(decodePageFromAPI)
  let page = pages && (pages.find((p) => p.id === pageId) as InstaPageV2)
  let blocks = page ? page.blocks : []
  let itemName = String(itemQuery?.name)

  function handleAddTextBlock() {
    const newBlockId = uuid()

    handleUpdateInstaPage({
      id: pageId,
      blocks: [
        ...blocks.slice(0, insertBlockIndex),
        {
          id: newBlockId,
          kind: "text",
          grid: addBlock?.grid ?? [1, 1],
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: "Add your text here...",
                },
              ],
            },
          ],
        },
        ...blocks.slice(insertBlockIndex),
      ],
    })
    setNewBlockId(newBlockId)
  }

  function handleAddLeadInBlock() {
    let newBlockId = uuid()
    handleUpdateInstaPage({
      id: pageId,
      blocks: [
        {
          id: newBlockId,
          kind: "lead_in",
          grid: [1, 2],
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: "IN WITNESS WHEREOF, the parties hereto have executed this agreement as of the date first set forth above.",
                },
              ],
            },
          ],
        },
        ...blocks,
      ],
    })
    setNewBlockId(newBlockId)
  }

  function handleAddFooterBlock() {
    let newBlockId = uuid()
    handleUpdateInstaPage({
      id: pageId,
      blocks: [
        ...blocks,
        {
          id: newBlockId,
          kind: "footer",
          grid: [1, 2],
          content: [
            {
              attrs: { textAlign: "center" },
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: `Signature page to ${itemName}`,
                },
              ],
            },
          ],
        },
      ],
    })
    setNewBlockId(newBlockId)
  }

  function handleAddSignatureBlock() {
    let newBlockId = uuid()
    handleUpdateInstaPage({
      id: pageId,
      blocks: [
        ...blocks.slice(0, insertBlockIndex),
        {
          id: newBlockId,
          kind: "signature",
          grid: [2, 2],
          blockStyles: DEFAULT_BLOCK_STYLES,
          additionalFields: [],
          authRepAlignment: "indent",
        },
        ...blocks.slice(insertBlockIndex),
      ],
    })
    setNewBlockId(newBlockId)
  }

  function handleDeleteBlock(blockId: string | undefined) {
    if (!blockId) return

    handleUpdateInstaPage({
      id: pageId,
      blocks: blocks.filter((b) => b.id !== blockId),
    })
    setSelectedBlockId(null)
  }

  function handleUpdateBlockGrid(
    blockId: string,
    newGrid: BlockGrid,
    oldGrid: BlockGrid
  ) {
    let blockThatNeedsToBeMovedIdx = blocks.findIndex((b) => b.id === blockId)
    let isNewGridLeft = newGrid[0] === 1 && newGrid[1] === 1
    let isBlockBeforeLeftGrid =
      blocks[blockThatNeedsToBeMovedIdx - 1]?.grid[0] === 1 &&
      blocks[blockThatNeedsToBeMovedIdx - 1]?.grid[1] === 1
    let isNewGridRight = newGrid[0] === 2 && newGrid[1] === 2
    let isBlockAfterRightGrid =
      blocks[blockThatNeedsToBeMovedIdx + 1]?.grid[0] === 2 &&
      blocks[blockThatNeedsToBeMovedIdx + 1]?.grid[1] === 2

    let updatedBlock = blocks.find((b) => b.id === blockId)
    let blockBeforeToUpdate = blocks[blockThatNeedsToBeMovedIdx - 1]
    let blockAfterToUpdate = blocks[blockThatNeedsToBeMovedIdx + 1]

    // if new grid is left and the block before it is also left, then we need to update the block to it's new grid while also updating the block before its grid to be right and positioned after the block that needs to be moved. Opposite is true if the new grid is right. If it's not either of those cases, then we just update the block to its new grid.
    let updatedBlocks =
      isNewGridLeft &&
      isBlockBeforeLeftGrid &&
      updatedBlock &&
      blockBeforeToUpdate
        ? [
            ...blocks.slice(0, blockThatNeedsToBeMovedIdx - 1),
            { ...updatedBlock, grid: newGrid },
            { ...blockBeforeToUpdate, grid: oldGrid },
            ...blocks.slice(blockThatNeedsToBeMovedIdx + 1),
          ]
        : isNewGridRight &&
            isBlockAfterRightGrid &&
            updatedBlock &&
            blockAfterToUpdate
          ? [
              ...blocks.slice(0, blockThatNeedsToBeMovedIdx),
              { ...blockAfterToUpdate, grid: oldGrid },
              { ...updatedBlock, grid: newGrid },
              ...blocks.slice(blockThatNeedsToBeMovedIdx + 2),
            ]
          : blocks.map((b) => (b.id === blockId ? { ...b, grid: newGrid } : b))

    handleUpdateInstaPage({
      id: pageId,
      blocks: updatedBlocks,
    })
  }

  return {
    handleAddTextBlock,
    handleAddLeadInBlock,
    handleAddFooterBlock,
    handleAddSignatureBlock,
    handleDeleteBlock,
    handleUpdateBlockGrid,
  }
}
